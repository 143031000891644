import {
  SET_LITECOMPANIES_DATA,
  SET_ORGANIZATION_DATA,
  TOGGLE_MODAL_LOADING,
  TOGGLE_LOADING,
  SET_PROJECTS_DATA,
  SET_BLP_DATA,
  SET_BLC_DATA,
  SET_USERS_DATA,
  SET_CONTRACTORS_DATA,
  SET_PERSONELS_DATA,
  SET_CHANGELOG_DATA,
} from "./types";
export const setOrganizationsData = (params) => {
  return {
    type: SET_ORGANIZATION_DATA,
    payload: params,
  };
};
export const setblpData = (params) => {
  return {
    type: SET_BLP_DATA,
    payload: params,
  };
};
export const setblcData = (params) => {
  return {
    type: SET_BLC_DATA,
    payload: params,
  };
};
export const setLiteCompaniesData = (params) => {
  return {
    type: SET_LITECOMPANIES_DATA,
    payload: params,
  };
};
export const setProjectsData = (params) => {
  return {
    type: SET_PROJECTS_DATA,
    payload: params,
  };
};
export const setUsersData = (params) => {
  return {
    type: SET_USERS_DATA,
    payload: params,
  };
};
export const setContractorsData = (params) => {
  return {
    type: SET_CONTRACTORS_DATA,
    payload: params,
  };
};
export const setPersonelsData = (params) => {
  return {
    type: SET_PERSONELS_DATA,
    payload: params,
  };
};
export const setChangeLog = (params) => {
  return {
    type: SET_CHANGELOG_DATA,
    payload: params,
  };
};
export const toggleModalLoading = (params) => {
  return {
    type: TOGGLE_MODAL_LOADING,
    payload: params,
  };
};
export const toggleLoading = (params) => {
  return {
    type: TOGGLE_LOADING,
    payload: params,
  };
};
