import React, { useEffect, useState, useRef } from "react";
import {
  Table,
  Row,
  Col,
  InputGroup,
  FormControl,
  Form,
  Image,
  Nav,
} from "react-bootstrap";
import {
  ArrowRight,
  ArrowLeftCircleFill,
  ArrowLeft,
} from "react-bootstrap-icons";
import Dashboard from "../../components/Dashboard/Dashboard";
import AxiosInterceptor from "../../utils/AxiosInterceptor";
import swal from "sweetalert";
import {
  setChangeLog,
  toggleLoading,
} from "../../redux/actions";
import { useSelector, useDispatch } from "react-redux";
import defProfile from "../../assets/images/defProfile.jpg";
import { IMAGEURLS } from "../../utils/Constants";

function ChangeLog() {
  const tableHeightRef = useRef(null);
  const reduxState = useSelector((state) => state);
  const dispatch = useDispatch();
  const [paginationFilter, setPaginationFilter] = useState({
    pointer: 1,
    items: [],
    docNumberPerPage: "15",
    goTo: "",
  });
  const [isViewedList, setIsViewedList] = useState(0);
  const getAllChangeLogs = (page, take, filter) => {
    dispatch(toggleLoading(true));
    AxiosInterceptor.post("/ChangeLog/List", {
      page: +page,
      pageSize: take ? +take : +paginationFilter.docNumberPerPage,
      orderBy: "",
      filter: filter ?? "",
    }).then((response) => {
      if (response.data.status) {
        dispatch(
          setChangeLog({
            list: response.data.changeLogList.data,
            count: response.data.changeLogList.count,
          })
        );
      } else {
        swal({
          icon: "error",
          title: "خطا",
          text: response.data.message,
          button: "متوجه شدم",
        });
      }
      dispatch(toggleLoading(false));
    });
  };
  // useEffect(() => {
  //   getAllChangeLogs(1);
  // }, []);
  useEffect(() => {
    setPaginationFilter({
      ...paginationFilter,
      pointer: 1,
      goTo: "",
    })
    getAllChangeLogs(1, undefined,`viewed=${isViewedList===1?true:false}`);
  }, [isViewedList]);
  useEffect(() => {
    if (+reduxState.changelog.count > 0) {
      let arr = [];
      let groupsNumber =
        parseInt(reduxState.changelog.count) /
        parseInt(paginationFilter.docNumberPerPage);
      let i = 1;
      while (groupsNumber > 0) {
        groupsNumber--;
        arr.push(i);
        i++;
      }
      setPaginationFilter({
        ...paginationFilter,
        items: arr,
      });
    }
  }, [paginationFilter.docNumberPerPage, reduxState.changelog.count]);
  return (
    <Dashboard>
      <div className="shared-wrapper p-4">
        <Row className="mb-2">
          <Col
            sm={12}
            lg={4}
            className="d-flex align-item-center justify-content-start"
          >
            <h5 className="m-0 set-column-middle">تاریخچه تغییرات</h5>
          </Col>
          <Col
            sm={12}
            lg={8}
            className="d-flex align-item-center justify-content-end"
          >
            <Nav
              variant="pills"
              defaultActiveKey={isViewedList}
              onSelect={(eventKey) => setIsViewedList(+eventKey)}
              className="set-row-middle"
            >
              <b className="mx-2">فیلتر داده ها بر اساس:</b>
              <Nav.Item>
                <Nav.Link eventKey={0}>دیده نشده ها</Nav.Link>
              </Nav.Item>
              <Nav.Item>
                <Nav.Link eventKey={1}>دیده شده ها</Nav.Link>
              </Nav.Item>
            </Nav>
            {/* <button
              className="btn btn-success mx-2 text-white"
              onClick={() => {
              }}
            >
              دیده شده ها
            </button>
            <button
              className="btn btn-success mx-2 text-white"
              onClick={() => {
              }}
            >
              دیده نشده ها
            </button> */}
          </Col>
        </Row>
        <hr />
        <Row
          ref={tableHeightRef}
          style={{
            overflowY: "auto",
          }}
        >
          <Table striped bordered hover responsive>
            <thead className="gradiantBg text-white text-center">
              <tr>
                <th scope="col" className="text-nowrap">
                  ردیف
                </th>
                <th scope="col" className="text-nowrap">
                  منبع تغییر
                </th>
                <th scope="col" className="text-nowrap">
                  نوع
                </th>
                <th scope="col" className="text-nowrap">
                  توضحیات
                </th>
                <th scope="col" className="text-nowrap">
                  تاریخ تغییر
                </th>
              </tr>
            </thead>
            <tbody className="text-center">
              {reduxState.changelog.list.length > 0 ? (
                reduxState.changelog.list?.map((cl, index) => {
                  return (
                    <tr key={index} id={cl.eChangeLogId}>
                      <td className="text-nowrap">
                        {parseInt(paginationFilter.pointer) *
                          parseInt(paginationFilter.docNumberPerPage) +
                          (index +
                            1 -
                            parseInt(paginationFilter.docNumberPerPage))}
                      </td>
                      <td className="text-nowrap">{cl.source}</td>
                      <td className="text-nowrap">{cl.type}</td>
                      <td className="text-nowrap">{cl.description}</td>
                      <td className="text-nowrap">{cl.persianDateTime}</td>
                    </tr>
                  );
                })
              ) : (
                <tr>
                  <td colSpan={99}>لیست تاریخچه تغییرات خالی میباشد</td>
                </tr>
              )}
            </tbody>
          </Table>
        </Row>
        <Row>
          <Col sm={12} className="px-3 py-3 set-row-middle position-relative">
            <div
              className="input-wrapper flex-nowrap"
              style={{ position: "absolute", right: "5px" }}
            >
              <Form.Group className="set-row-middle">
                <InputGroup.Text>برو به صفحه</InputGroup.Text>
                <FormControl
                  name="goTo"
                  type="number"
                  style={{ maxWidth: "70px" }}
                  value={paginationFilter.goTo}
                  onChange={(e) => {
                    setPaginationFilter({
                      ...paginationFilter,
                      goTo: e.target.value,
                    });
                  }}
                />
                <button
                  className="btn gradiantBg"
                  onClick={() => {
                    if (paginationFilter.goTo !== "") {
                      getAllChangeLogs(paginationFilter.goTo);
                    }
                  }}
                >
                  <ArrowLeftCircleFill size="1.3em" color="#fff" />
                </button>
              </Form.Group>
            </div>
            <ul
              id="paginationList"
              className="m-0 p-0 list-unstyled set-row-middle flex-wrap"
            >
              <li
                className="ms-1 cpointer"
                onClick={() => {
                  if (paginationFilter.pointer !== paginationFilter.items[0]) {
                    getAllChangeLogs(paginationFilter.pointer - 1);
                  }
                }}
              >
                <ArrowRight size="1.3em" />
              </li>
              {paginationFilter.pointer !== 1 && (
                <li
                  className="paginationItems"
                  onClick={() => {
                    getAllChangeLogs(1);
                  }}
                >
                  1
                </li>
              )}
              {paginationFilter.pointer > 2 && (
                <li>
                  <strong>.....</strong>
                </li>
              )}
              {paginationFilter.items?.map((pa, index) => {
                return index + 1 === paginationFilter.pointer ||
                  (index <= paginationFilter.pointer + 1 &&
                    index + 1 >= paginationFilter.pointer) ||
                  index + 1 ===
                  paginationFilter.items[
                  paginationFilter.items.length - 1
                  ] ? (
                  <li
                    key={index}
                    className={`paginationItems ${index + 1 === paginationFilter.pointer
                      ? "paginationItemActive"
                      : ""
                      }`}
                    onClick={() => {
                      getAllChangeLogs(pa);
                    }}
                  >
                    {pa}
                  </li>
                ) : index + 1 ===
                  paginationFilter.items[paginationFilter.items.length - 2] ? (
                  <>
                    <li>
                      <strong>.....</strong>
                    </li>
                    <li
                      key={index}
                      className={`paginationItems ${index + 1 === paginationFilter.pointer
                        ? "paginationItemActive"
                        : ""
                        }`}
                      onClick={() => {
                        getAllChangeLogs(pa);
                      }}
                    >
                      {pa}
                    </li>
                  </>
                ) : (
                  <></>
                );
              })}

              <li
                className="me-1 cpointer"
                onClick={() => {
                  if (
                    paginationFilter.pointer !==
                    paginationFilter.items[paginationFilter.items.length - 1]
                  ) {
                    getAllChangeLogs(paginationFilter.pointer + 1);
                  }
                }}
              >
                <ArrowLeft size="1.3em" />
              </li>
            </ul>
            <div
              className="input-wrapper flex-nowrap"
              style={{ position: "absolute", left: "5px" }}
            >
              <Form.Group className="set-row-middle">
                <InputGroup.Text>تعداد سند در هر صفحه</InputGroup.Text>
                <Form.Select
                  value={paginationFilter.docNumberPerPage}
                  name="docNumberPerPage"
                  id="docNumberPerPage"
                  onChange={(e) => {
                    getAllChangeLogs(1, e.target.value);
                  }}
                >
                  <option value="5">5</option>
                  <option value="10">10</option>
                  <option value="15">15</option>
                </Form.Select>
              </Form.Group>
            </div>
          </Col>
        </Row>
      </div>
    </Dashboard>
  );
}

export default ChangeLog;
