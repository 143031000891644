import React from "react";
import { Col, Card } from "react-bootstrap";
import {
  Printer,
  CloudArrowUpFill,
  EyeFill,
  Trash,
} from "react-bootstrap-icons";
import { EHRAZ_IMAGEURLS } from "utils/Constants";
function DocItem({ item, deleteDoc, uploadDoc, type }) {
  // type = download | create | upload | readOnly
  function ImagetoPrint(source) {
    return `<html><head><scri>function step1(){"setTimeout('step2()', 10);}
        function step2(){window.print();window.close()}
        "</scri" + "pt></head><body onload='step1()'>
        "<img src='" + source + "' /></body></html>`;
  }

  function printImage(source) {
    var Pagelink = "about:blank";
    var pwa = window.open(Pagelink, "_new");
    pwa.document.open();
    pwa.document.write(ImagetoPrint(source));
    pwa.document.close();
  }
  return (
    <Col sm={6} lg={4} className="p-2">
      <Card>
        <Card.Body>
          <Card.Title className="text-center mb-3">
            {type === "upload" || type === "readOnly"
              ? item.eDoc.name
              : item.name}
          </Card.Title>
          <hr />
          <div className="set-row-middle justify-content-between">
            {type !== "upload" && type !== "readOnly" ? (
              item.image ? (
                item.image.includes("JPG") ||
                item.image.includes("jpg") ||
                item.image.includes("PNG") ||
                item.image.includes("png") ? (
                  <button
                    className="btn btn-info text-white"
                    onClick={() => {
                      printImage(EHRAZ_IMAGEURLS + item.image);
                    }}
                  >
                    <Printer size="1.2em" /> چاپ
                  </button>
                ) : (
                  <a
                    href={EHRAZ_IMAGEURLS + item.image}
                    target="_blank"
                    rel="noreferrer"
                  >
                    <button className="btn btn-info text-white">
                      <Printer size="1.2em" /> چاپ
                    </button>
                  </a>
                )
              ) : (
                <></>
              )
            ) : (
              <a
                href={EHRAZ_IMAGEURLS + item.image}
                target="_blank"
                rel="noreferrer"
              >
                <button className="btn btn-info text-white">
                  <EyeFill size="1.2em" /> مشاهده
                </button>
              </a>
            )}
            {type === "download" ? (
              <button
                className="btn btn-success"
                onClick={() => uploadDoc(item.eDocId)}
              >
                <CloudArrowUpFill size="1.2em" /> بارگذاری
              </button>
            ) : type === "readOnly" ? (
              <></>
            ) : type === "upload" ? (
              <button
                className="btn btn-danger"
                onClick={() => deleteDoc(item.ePersonDocId, item.eDoc.name)}
              >
                <Trash size="1.2em" /> حذف
              </button>
            ) : (
              <button
                className="btn btn-danger"
                onClick={() => deleteDoc(item.eDocId, item.name)}
              >
                <Trash size="1.2em" /> حذف
              </button>
            )}
          </div>
        </Card.Body>
      </Card>
    </Col>
  );
}

export default DocItem;
