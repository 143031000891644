import React, { useEffect, useState, useRef } from "react";
import {
  Table,
  Row,
  Col,
  InputGroup,
  FormControl,
  Form,
  Image,
  OverlayTrigger,
  Tooltip,
} from "react-bootstrap";
import {
  ArrowRight,
  ArrowLeftCircleFill,
  ArrowLeft,
  XLg,
  CheckLg,
  Bricks,
} from "react-bootstrap-icons";
import Dashboard from "components/Dashboard/Dashboard";
import AxiosInterceptor from "utils/AxiosInterceptor";
import { EHRAZ_IMAGEURLS } from "utils/Constants";
import defCompany from "assets/images/defCompany.jpg";
import swal from "sweetalert";
import { setContractorsData, toggleLoading } from "redux/actions";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate, useLocation } from "react-router-dom";
import Breadcrumb from "components/Breadcrumb/Breadcrumb";

function Contractors() {
  const navigate = useNavigate();
  const params = useLocation();
  const tableHeightRef = useRef(null);
  const reduxState = useSelector((state) => state);
  const arrayParams = params.search.split("&");
  const isSearchMode = arrayParams.length === 1;
  const searchedKey = isSearchMode
    ? decodeURIComponent(arrayParams[0].split("=")[1].replace("*", ""))
    : "";
  const dispatch = useDispatch();
  const [paginationFilter, setPaginationFilter] = useState({
    pointer: 1,
    items: [],
    docNumberPerPage: "15",
    goTo: "",
  });
  const [usersInputs, setUsersInputs] = useState({
    eUserId: "",
    eOrganizationId: "",
    organizatioName: "",
    eLiteCompanyId: "",
    companyName: "",
    eProjectId: "",
    projectName: "",
    username: "",
    password: "",
    accessType: 0,
  });
  const getAllContractors = (page, take, filter) => {
    dispatch(toggleLoading(true));
    const prjId = arrayParams[4]?.replace("prjId=", "");
    AxiosInterceptor.post("/Company/List", {
      page: +page,
      pageSize: take ? +take : +paginationFilter.docNumberPerPage,
      orderBy: "",
      filter: filter ? filter : prjId ? `eProjectId=${prjId}` : "",
    }).then((response) => {
      if (response.data.status) {
        dispatch(
          setContractorsData({
            list: response.data.companies.data,
            count: response.data.companies.count,
          })
        );
      } else {
        swal({
          icon: "error",
          title: "خطا",
          text: response.data.message,
          button: "متوجه شدم",
        });
      }
      dispatch(toggleLoading(false));
    });
  };
  // const [contractorsDetailModal, setContractorsDetailModal] = useState(false);
  // const [selectedContractor, setSelectedContractor] = useState({});
  // const [searchInputs, setSearchInputs] = useState({
  //   searchBy: "codeMelli",
  //   searchWord: "",
  // });
  // const searchHandle = () => {
  //   if (searchInputs.searchWord) {
  //     dispatch(toggleLoading(true));
  //     AxiosInterceptor.post("/Company/List", {
  //       page: 1,
  //       pageSize: 20,
  //       orderBy: "",
  //       filter: `${searchInputs.searchBy}=${searchInputs.searchWord}`,
  //     }).then((response) => {
  //       if (response.data.status) {
  //         if (response.data.companies.count === 0) {
  //           swal({
  //             icon: "error",
  //             title: "خطا",
  //             text: "نتیجه ای برای این عبات جست‌وجو یافت نشد",
  //             button: "متوجه شدم",
  //           });
  //         } else {
  //           let person = response.data.companies.data[0];
  //           setSelectedContractor(person);
  //           setContractorsDetailModal(true);
  //         }
  //       } else {
  //         swal({
  //           icon: "error",
  //           title: "خطا",
  //           text: response.data.message,
  //           button: "متوجه شدم",
  //         });
  //       }
  //       dispatch(toggleLoading(false));
  //     });
  //   } else {
  //     swal({
  //       icon: "error",
  //       title: "خطا",
  //       text: "عبارت جست‌وجو نمیتواند خالی باشد.",
  //       button: "متوجه شدم",
  //     });
  //   }
  // };

  useEffect(() => {
    if (params.search) {
      if (arrayParams.length > 1) {
        //این یعنی از بخش بالایی فرم سرچ استفاده کرده و 6 تا پارامتر فرستاده
        let orgId = arrayParams[0].replace("?orgId=", "");
        let orgName = arrayParams[1].replace("orgName=", "");
        let compId = arrayParams[2].replace("compId=", "");
        let compName = arrayParams[3].replace("compName=", "");
        let prjId = arrayParams[4].replace("prjId=", "");
        let prjName = arrayParams[5].replace("prjName=", "");
        setUsersInputs({
          ...usersInputs,
          eOrganizationId: orgId,
          organizatioName: decodeURIComponent(orgName),
          eLiteCompanyId: compId,
          companyName: decodeURIComponent(compName),
          eProjectId: prjId,
          projectName: decodeURIComponent(prjName),
        });
        getAllContractors(1, 15);
      } else {
        //این یعنی از بخش پایینی فرم سرچ استفاده کرده و یه پارامتر فرستاده فقط برای فیلتر
        let filter = decodeURIComponent(arrayParams[0].replace("?", ""));
        getAllContractors(1, 50, filter);
      }
    } else {
      swal({
        icon: "error",
        title: "خطا",
        text: "ابتدا سازمان و شرکت و پروژه مورد نظر را مشخص نمایید",
        button: "متوجه شدم",
      });
      navigate("/Organizations");
    }
  }, [params]);

  useEffect(() => {
    if (+reduxState.contractors.count > 0) {
      let arr = [];
      let groupsNumber =
        parseInt(reduxState.contractors.count) /
        parseInt(paginationFilter.docNumberPerPage);
      let i = 1;
      while (groupsNumber > 0) {
        groupsNumber--;
        arr.push(i);
        i++;
      }
      setPaginationFilter({
        ...paginationFilter,
        items: arr,
      });
    }
  }, [paginationFilter.docNumberPerPage, reduxState.contractors.count]);
  return (
    <Dashboard>
      <div className="shared-wrapper p-4">
        <Row className="mb-2">
          <Col
            sm={12}
            className="d-flex align-item-center justify-content-start"
          >
            {isSearchMode ? (
              <Breadcrumb title={`نتایج جست‌وجو برای عبارت ${searchedKey}`} />
            ) : (
              <>
                <Breadcrumb
                  title={"لیست پیمانکاران"}
                  list={[
                    usersInputs.projectName,
                    usersInputs.companyName,
                    usersInputs.organizatioName,
                  ]}
                />
              </>
            )}
          </Col>
        </Row>
        <hr />
        {/* <Row className="my-2">
          <Col sm={12} lg={5}>
            <div className="input-wrapper flex-nowrap">
              <Form.Group className="set-row-middle">
                <InputGroup.Text>جستجو بر اساس</InputGroup.Text>
                <Form.Select
                  value={searchInputs.searchBy}
                  name="searchBy"
                  id="searchBy"
                  onChange={(e) => {
                    setSearchInputs({
                      ...searchInputs,
                      searchBy: e.target.value,
                    });
                  }}
                >
                  <option value="name">نام شرکت</option>
                  <option value="sabtNumber">شماره ثبت</option>
                  <option value="codeMelli">شناسه ملی</option>
                </Form.Select>
              </Form.Group>
            </div>
          </Col>
          <Col sm={12} lg={5}>
            <Form.Group className="input-wrapper">
              <InputGroup.Text>عبارت جست‌وجو</InputGroup.Text>
              <FormControl
                name="searchWord"
                type="text"
                value={searchInputs.searchWord}
                onChange={(e) => {
                  setSearchInputs({
                    ...searchInputs,
                    searchWord: e.target.value,
                  });
                }}
              />
            </Form.Group>
          </Col>
          <Col sm={12} lg={2}>
            <Button
              className="w-100"
              onClick={searchHandle}
              variant={"success"}
            >
              جست‌وجو <Search />
            </Button>
          </Col>
        </Row> */}
        <Row
          ref={tableHeightRef}
          style={{
            overflowY: "auto",
          }}
        >
          <Table striped bordered hover responsive>
            <thead className="gradiantBg text-white text-center">
              <tr>
                <th scope="col" className="text-nowrap">
                  ردیف
                </th>
                <th scope="col" className="text-nowrap">
                  تصویر
                </th>
                <th scope="col" className="text-nowrap">
                  نام
                </th>
                <th scope="col" className="text-nowrap">
                  شناسه ملی
                </th>
                <th scope="col" className="text-nowrap">
                  شماره ثبت
                </th>
                <th scope="col" className="text-nowrap">
                  کد اقتصادی
                </th>
                {isSearchMode && (
                  <>
                    <th scope="col" className="text-nowrap">
                      سازمان
                    </th>
                    <th scope="col" className="text-nowrap">
                      شرکت
                    </th>
                    <th scope="col" className="text-nowrap">
                      پروژه
                    </th>
                  </>
                )}
                <th scope="col" className="text-nowrap">
                  صلاحیت همکاری
                </th>
                <th scope="col" className="text-nowrap">
                  سمتا
                </th>
              </tr>
            </thead>
            <tbody className="text-center">
              {reduxState.contractors.list.length > 0 ? (
                reduxState.contractors.list?.map((company, index) => {
                  return (
                    <tr key={index} id={company.eUserId}>
                      <td className="text-nowrap">
                        {parseInt(paginationFilter.pointer) *
                          parseInt(paginationFilter.docNumberPerPage) +
                          (index +
                            1 -
                            parseInt(paginationFilter.docNumberPerPage))}
                      </td>
                      <td className="text-nowrap set-row-middle">
                        <div style={{ width: "40px", height: "40px" }}>
                          <Image
                            className="cpointer zoomable_avatar"
                            src={
                              company.image
                                ? EHRAZ_IMAGEURLS + company.image
                                : defCompany
                            }
                            width="40px"
                            height="40px"
                          />
                        </div>
                      </td>
                      <td className="text-nowrap">{company.name}</td>
                      <td className="text-nowrap">{company.codeMelli}</td>
                      <td className="text-nowrap">{company.sabtNumber}</td>
                      <td className="text-nowrap">{company.eghtesadiCode}</td>
                      {isSearchMode && (
                        <>
                          <td className="text-nowrap">
                            {company.eOrganization.name}
                          </td>
                          <td className="text-nowrap">
                            {company.eLiteCompany.name}
                          </td>
                          <td className="text-nowrap">
                            {company.eProject.name}
                          </td>
                        </>
                      )}
                      <td className="text-nowrap">
                        {/* 
                            دارد = 0,
                            ندارد = 1,
                            مشکوک_المعامله = 2 
                        */}
                        {company.salahiyateHamkariInt === 0 ? (
                          <OverlayTrigger
                            placement="top"
                            delay={{ show: 250, hide: 400 }}
                            overlay={
                              <Tooltip>
                                {company.salahiyateHamkari.replaceAll("_", " ")}
                              </Tooltip>
                            }
                          >
                            <span className="text-success cpointer">
                              <CheckLg size="1.3em" />
                            </span>
                          </OverlayTrigger>
                        ) : company.salahiyateHamkariInt === 1 ? (
                          <OverlayTrigger
                            placement="top"
                            delay={{ show: 250, hide: 400 }}
                            overlay={
                              <Tooltip>
                                {company.salahiyateHamkari.replaceAll("_", " ")}
                              </Tooltip>
                            }
                          >
                            <span className="text-danger cpointer">
                              <XLg size="1.3em" />
                            </span>
                          </OverlayTrigger>
                        ) : (
                          <OverlayTrigger
                            placement="top"
                            delay={{ show: 250, hide: 400 }}
                            overlay={
                              <Tooltip>
                                {company.salahiyateHamkari.replaceAll("_", " ")}
                              </Tooltip>
                            }
                          >
                            <span className="text-orange cpointer">
                              <Bricks size="1.3em" />
                            </span>
                          </OverlayTrigger>
                        )}
                      </td>
                      <td className="text-nowrap">
                        {company.samta ? (
                          <span className="text-success">
                            <CheckLg size="1.3em" />
                          </span>
                        ) : (
                          <span className="text-danger">
                            <XLg size="1.3em" />
                          </span>
                        )}
                      </td>
                    </tr>
                  );
                })
              ) : (
                <tr>
                  <td colSpan={99}>
                    {isSearchMode
                      ? "نتیجه ای برای این عبارت یافت نشد"
                      : "لیست پیمانکاران خالی میباشد"}
                  </td>
                </tr>
              )}
            </tbody>
          </Table>
        </Row>
        <Row>
          <Col sm={12} className="px-3 py-3 set-row-middle position-relative">
            <div
              className="input-wrapper flex-nowrap"
              style={{ position: "absolute", right: "5px" }}
            >
              <Form.Group className="set-row-middle">
                <InputGroup.Text>برو به صفحه</InputGroup.Text>
                <FormControl
                  name="goTo"
                  type="number"
                  style={{ maxWidth: "70px" }}
                  value={paginationFilter.goTo}
                  onChange={(e) => {
                    setPaginationFilter({
                      ...paginationFilter,
                      goTo: e.target.value,
                    });
                  }}
                />
                <button
                  className="btn gradiantBg"
                  onClick={() => {
                    if (paginationFilter.goTo !== "") {
                      getAllContractors(paginationFilter.goTo);
                    }
                  }}
                >
                  <ArrowLeftCircleFill size="1.3em" color="#fff" />
                </button>
              </Form.Group>
            </div>
            <ul
              id="paginationList"
              className="m-0 p-0 list-unstyled set-row-middle flex-wrap"
            >
              <li
                className="ms-1 cpointer"
                onClick={() => {
                  if (paginationFilter.pointer !== paginationFilter.items[0]) {
                    getAllContractors(paginationFilter.pointer - 1);
                  }
                }}
              >
                <ArrowRight size="1.3em" />
              </li>
              {paginationFilter.pointer !== 1 && (
                <li
                  className="paginationItems"
                  onClick={() => {
                    getAllContractors(1);
                  }}
                >
                  1
                </li>
              )}
              {paginationFilter.pointer > 2 && (
                <li>
                  <strong>.....</strong>
                </li>
              )}
              {paginationFilter.items?.map((pa, index) => {
                return index + 1 === paginationFilter.pointer ||
                  (index <= paginationFilter.pointer + 1 &&
                    index + 1 >= paginationFilter.pointer) ||
                  index + 1 ===
                    paginationFilter.items[
                      paginationFilter.items.length - 1
                    ] ? (
                  <li
                    key={index}
                    className={`paginationItems ${
                      index + 1 === paginationFilter.pointer
                        ? "paginationItemActive"
                        : ""
                    }`}
                    onClick={() => {
                      getAllContractors(pa);
                    }}
                  >
                    {pa}
                  </li>
                ) : index + 1 ===
                  paginationFilter.items[paginationFilter.items.length - 2] ? (
                  <>
                    <li>
                      <strong>.....</strong>
                    </li>
                    <li
                      key={index}
                      className={`paginationItems ${
                        index + 1 === paginationFilter.pointer
                          ? "paginationItemActive"
                          : ""
                      }`}
                      onClick={() => {
                        getAllContractors(pa);
                      }}
                    >
                      {pa}
                    </li>
                  </>
                ) : (
                  <></>
                );
              })}

              <li
                className="me-1 cpointer"
                onClick={() => {
                  if (
                    paginationFilter.pointer !==
                    paginationFilter.items[paginationFilter.items.length - 1]
                  ) {
                    getAllContractors(paginationFilter.pointer + 1);
                  }
                }}
              >
                <ArrowLeft size="1.3em" />
              </li>
            </ul>
            <div
              className="input-wrapper flex-nowrap"
              style={{ position: "absolute", left: "5px" }}
            >
              <Form.Group className="set-row-middle">
                <InputGroup.Text>تعداد سند در هر صفحه</InputGroup.Text>
                <Form.Select
                  value={paginationFilter.docNumberPerPage}
                  name="docNumberPerPage"
                  id="docNumberPerPage"
                  onChange={(e) => {
                    getAllContractors(1, e.target.value);
                  }}
                >
                  <option value="5">5</option>
                  <option value="10">10</option>
                  <option value="15">15</option>
                </Form.Select>
              </Form.Group>
            </div>
          </Col>
        </Row>
        {/* <Portal show={contractorsDetailModal}>
          <Modal.Header>
            <Modal.Title id="contained-modal-title-vcenter">
              جزئیات پیمانکار
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Row>
              <Col sm={12} className="set-column-middle">
                <div
                  className="set-row-middle"
                  style={{
                    width: "170px",
                    height: "170px",
                    borderRadius: "50%",
                    border: "3px solid #ddd",
                    overflow: "hidden",
                    padding: "5px",
                  }}
                >
                  <Image
                    src={
                      selectedContractor.image
                        ? EHRAZ_IMAGEURLS + selectedContractor.image
                        : defCompany
                    }
                    width="90%"
                    height="90%"
                  />
                </div>
                <h4 className="my-2">
                  نام شرکت : <b>{selectedContractor.name}</b>
                </h4>
                <h6 className="my-2">
                  شناسه ملی : <b>{selectedContractor.codeMelli}</b>
                </h6>
                <h6 className="my-2">
                  شماره ثبت : <b>{selectedContractor.sabtNumber}</b>
                </h6>
                <h6 className="my-2">
                  کد اقتصادی : <b>{selectedContractor.eghtesadiCode}</b>
                </h6>
              </Col>
            </Row>
          </Modal.Body>
          <Modal.Footer>
            <Button
              onClick={() => setContractorsDetailModal(false)}
              variant={"danger"}
            >
              بستن
            </Button>
          </Modal.Footer>
        </Portal> */}
      </div>
    </Dashboard>
  );
}

export default Contractors;
