import React, { useEffect, useState } from "react";
// import defCompany from "assets/images/defCompany.jpg";
import { DateInput } from "react-hichestan-datetimepicker";
import "./Dashboard.scss";
import {
  Modal,
  Button,
  // Image,
} from "react-bootstrap";
import { BoxArrowDownLeft } from "react-bootstrap-icons";
import { Link, useNavigate } from "react-router-dom";
import Portal from "components/Portal/Portal";
import Joyride from "react-joyride";
import AxiosInterceptor from "utils/AxiosInterceptor";
import Loading from "components/Loading/Loading";
import swal from "sweetalert";
import { BASEURL } from "utils/Constants";
import Sidebar from "./Sidebar/Sidebar";
import Prerequisites from "./Prerequisites";

function Dashboard({ children }) {
  const navigate = useNavigate();
  const [tourState, setTourState] = useState(false);
  const [nowDate, setNowDate] = useState("");
  const [nowTime, setNowTime] = useState("");
  const [backUpModalShow, setBackUpModalShow] = useState(false);
  const [alarmData, setAlarmData] = useState({});
  let initialPrerequisitesModal = {
    show: false,
    type: "",
  };
  const [prerequisitesModal, setPrerequisitesModal] = useState(
    initialPrerequisitesModal
  );
  const [backUps, setBackUps] = useState({
    backUpStartDate: "",
    backUpEndDate: "",
    backUpStartDateErr: "",
    backUpEndDateErr: "",
    backUpLink: "",
  });
  const tourSteps = [
    {
      target: "#Organizations",
      content: " لیست سازمان ها در راس شرکت ها ",
    },
    {
      target: "#LiteCompanies",
      content: " لیست شرکت ها در راس پروژه ها ",
    },
    {
      target: "#Projects",
      content: " لیست پروژه ها در راس کاربران، پیمانکاران و پرسنل ",
    },
    {
      target: "#Users",
      content: " لیست کاربران زیر مجموعه پروژه ها ",
    },
    {
      target: "#Contractors",
      content: " لیست پیمانکاران زیر مجموعه پروژه ها ",
    },
    {
      target: "#Personels",
      content: " لیست پرسنل زیر مجموعه پروژه ها ",
    },
    {
      target: "#BlackListPerson",
      content: " لیست افزاد ممنوعه به همراه اضافه کردن، ویرایش و حذف ",
    },
    {
      target: "#BlackListCompany",
      content: " لیست شرکت‌های ممنوعه به همراه اضافه کردن، ویرایش و حذف  ",
    },
    {
      target: "#DocManagement",
      content: "آپلود و بارگذاری مدارک ثابت که برای ثبت پرسنل نیاز هستند",
    },
    {
      target: "#backUp",
      content: "گرفتن فایل پشتیبانی از اطلاعات به صورت بازه زمانی",
    },
    {
      target: "#aboutUs",
      content:
        "برای شناخت بیشتر و آشنایی با شرکت ما و همچنین، شبکه های اجتماعی و ارتباط با ما از این گزینه استفاده نمایید",
    },
  ];
  const checkTime = (i) => {
    if (i < 10) {
      i = "0" + i;
    }
    return i;
  };
  const logout = () => {
    localStorage.removeItem("_data");
    navigate("/");
  };
  const getBackUp = () => {
    if (backUps.backUpStartDate === "" && backUps.backUpEndDate === "") {
      setBackUps({
        ...backUps,
        backUpStartDateErr: "تعیین فیلد تاریخ شروع پشتیبان الزامی است",
        backUpEndDateErr: "تعیین فیلد تاریخ پایان پشتیبان الزامی است",
      });
    } else if (backUps.backUpStartDate === "") {
      setBackUps({
        ...backUps,
        backUpStartDateErr: "تعیین فیلد تاریخ شروع پشتیبان الزامی است",
        backUpEndDateErr: "",
      });
    } else if (backUps.backUpEndDate === "") {
      setBackUps({
        ...backUps,
        backUpStartDateErr: "",
        backUpEndDateErr: "تعیین فیلد تاریخ پایان پشتیبان الزامی است",
      });
    } else {
      AxiosInterceptor.post("/BackUp", {
        from: backUps.backUpStartDate,
        to: backUps.backUpEndDate,
      })
        .then((response) => {
          if (!response.data.includes("error")) {
            setBackUps({
              ...backUps,
              backUpStartDateErr: "",
              backUpEndDateErr: "",
              backUpLink: BASEURL + response.data,
            });
            document.querySelector("#backUpLink").click();
            document.querySelector("#closeBackUpModal").click();
          } else {
            swal({
              icon: "error",
              title: "خطا",
              text: response.data,
              button: "متوجه شدم",
            });
          }
        })
        .catch((error) => console.log(error));
    }
  };
  const selectPrerequisites = (type) => {
    setPrerequisitesModal({
      show: true,
      type: type,
    });
  };
  useEffect(() => {
    setNowDate(document.querySelector(".date-input-with-dialog-input").value);
    var clockInterVal = setInterval(() => {
      const today = new Date();
      let h = today.getHours();
      let m = today.getMinutes();
      let s = today.getSeconds();
      m = checkTime(m);
      s = checkTime(s);
      setNowTime(h + ":" + m + ":" + s);
    }, 1000);
    return () => {
      clearInterval(clockInterVal);
    };
  }, []);
  const CheclAlarm = () => {
    AxiosInterceptor.post("/Setting/CheckAlarm").then(({ data }) => {
      if (data.status) setAlarmData(data);
    });
  };
  useEffect(() => {
    CheclAlarm();
    var CheckAlarmInterval = setInterval(() => {
      CheclAlarm();
    }, 10000);
    return () => {
      clearInterval(CheckAlarmInterval);
    };
  }, []);
  
  return (
    <div className="nav-fixed">
      <Joyride
        steps={tourSteps}
        continuous={true}
        disableOverlayClose={true}
        hideCloseButton={true}
        scrollToFirstStep={true}
        showProgress={true}
        showSkipButton={true}
        run={tourState}
        locale={{
          back: "قبلی",
          close: "بستن",
          last: "پایان",
          next: "بعدی",
          open: "باز کردن",
          skip: "رد شدن",
        }}
        styles={{
          options: {
            arrowColor: "#fff",
            backgroundColor: "#fff",
            overlayColor: "rgba(0,0,0,0.75)",
            primaryColor: "var(--color2)",
            textColor: "#000",
            width: 900,
            zIndex: 2000,
          },
        }}
      />
      <nav
        className="topnav navbar navbar-expand justify-content-between justify-content-sm-start navbar-light bg-white"
        id="sidenavAccordion"
      >
        <span className="navbar-brand">داشبورد کنترل دسترسی</span>
        {alarmData.type === 1 ? (
          <div id="blinking_status">
            <Link to={alarmData.link} className={alarmData.color}>
              <div></div>
              {alarmData.message}
            </Link>
          </div>
        ) : (
          <></>
        )}
        <ul className="navbar-nav align-items-center me-auto">
          <li>
            امروز {nowDate} - {nowTime}
          </li>
          <li className="nav-item no-caret me-3 me-lg-4">
            <button className="mx-3 btn logOutBtn" onClick={logout}>
              خروج <BoxArrowDownLeft />
            </button>
          </li>
          <div className="d-none">
            <DateInput name={"nowDate"} value={new Date()} disabled={true} />
          </div>
        </ul>
      </nav>
      <div id="layoutSidenav">
        <Sidebar
          setTourState={setTourState}
          selectPrerequisites={selectPrerequisites}
          setBackUpModalShow={setBackUpModalShow}
        />
        <div id="layoutSidenav_content">
          <Loading />
          <main>{children}</main>
          <footer className="footer-admin mt-auto set-row-middle">
            <div className="container-xl px-4">
              <div className="row">
                <div className="col-md-12 text-center small">
                  &copy; تمامی حقوق این وبسایت برای موسسه نماپردازامیر به شماره
                  ثبت 50527 محفوظ است.
                </div>
              </div>
            </div>
          </footer>
        </div>
      </div>
      <Prerequisites
        showModal={prerequisitesModal.show}
        dataType={prerequisitesModal.type}
        resetPrerequisitesModalData={() => {
          setPrerequisitesModal(initialPrerequisitesModal);
        }}
      />
      <Portal show={backUpModalShow}>
        <Modal.Header>
          <Modal.Title id="contained-modal-title-vcenter">
            پشتیبان گیری
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="row mb-3 text-center">
            <strong>تاریخ شروع و پایان پشتیبان گیری خود را مشخص نمایید</strong>
          </div>
          <div className="row">
            <div className="col-sm-12 col-lg-6 mb-3 set-column-middle align-items-start">
              <label htmlFor="">از تاریخ</label>
              <DateInput
                name="backUpStartDate"
                onChange={(e) => {
                  setBackUps({
                    ...backUps,
                    backUpStartDate: e.target.value.split("T")[0],
                  });
                }}
                autoOk={true}
                value={backUps.backUpStartDate}
              />
              <p className="error">{backUps.backUpStartDateErr}</p>
            </div>
            <div className="col-sm-12 col-lg-6 mb-3 set-column-middle align-items-start">
              <label htmlFor="">تا تاریخ</label>
              <DateInput
                name="backUpEndDate"
                onChange={(e) => {
                  setBackUps({
                    ...backUps,
                    backUpEndDate: e.target.value.split("T")[0],
                  });
                }}
                autoOk={true}
                value={backUps.backUpEndDate}
              />
              <p className="error">{backUps.backUpEndDateErr}</p>
            </div>
          </div>
          <div className="row">
            <a
              id="backUpLink"
              className="d-none"
              href={backUps.backUpLink}
              download
            >
              backUpLink
            </a>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button onClick={getBackUp} variant={"success"}>
            پشتیبان گیری
          </Button>
          <Button
            onClick={() => {
              setBackUps({
                backUpStartDate: "",
                backUpEndDate: "",
                backUpStartDateErr: "",
                backUpEndDateErr: "",
                backUpLink: "",
              });
              setBackUpModalShow(false);
            }}
            variant={"danger"}
          >
            انصراف
          </Button>
        </Modal.Footer>
      </Portal>
    </div>
  );
}

export default Dashboard;
