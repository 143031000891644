import "./App.css";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import DashboardHome from "./pages/DashboardHome/DashboardHome";
import Login from "./pages/Login/Login";
import Organizations from "./pages/Organizations/Organizations";
import LiteCompanies from "./pages/LiteCompanies/LiteCompanies";
import Projects from "./pages/Projects/Projects";
import Users from "./pages/Users/Users";
import Contractors from "./pages/Contractors/Contractors";
import Personels from "./pages/Personels/Personels";
import BlackListPerson from "./pages/BlackListPerson/BlackListPerson";
import BlackListCompany from "./pages/BlackListCompany/BlackListCompany";
import ChangeLog from "pages/ChangeLog/ChangeLog";
import DocManagement from "./pages/DocManagement/DocManagement";
import ProtectedRoute from "./routes/ProtectedRoute";
// ==============================================
function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<Login />} />
        <Route element={<ProtectedRoute />}>
          <Route path="Dashboard" element={<DashboardHome />} />
          <Route path="Organizations" element={<Organizations />} />
          <Route path="LiteCompanies" element={<LiteCompanies />} />
          <Route path="Projects" element={<Projects />} />
          <Route path="Users" element={<Users />} />
          <Route path="Contractors" element={<Contractors />} />
          <Route path="Personels" element={<Personels />} />
          <Route path="BlackListPerson" element={<BlackListPerson />} />
          <Route path="BlackListCompany" element={<BlackListCompany />} />
          <Route path="ChangeLog" element={<ChangeLog />} />
          <Route path="DocManagement" element={<DocManagement />} />
        </Route>
      </Routes>
    </BrowserRouter>
  );
}

export default App;
