import React, { useEffect, useState, useRef } from "react";
import ReactDOM from "react-dom";
import {
  Table,
  Row,
  Col,
  Modal,
  InputGroup,
  FormControl,
  Form,
  Image,
} from "react-bootstrap";
import {
  PencilSquare,
  Trash,
  ArrowRight,
  ArrowLeftCircleFill,
  ArrowLeft,
} from "react-bootstrap-icons";
import Dashboard from "../../components/Dashboard/Dashboard";
import AxiosInterceptor from "../../utils/AxiosInterceptor";
import swal from "sweetalert";
import {
  setblpData,
  toggleLoading,
  toggleModalLoading,
} from "../../redux/actions";
import { useSelector, useDispatch } from "react-redux";
import Portal from "../../components/Portal/Portal";
import defProfile from "../../assets/images/defProfile.jpg";
import { IMAGEURLS } from "../../utils/Constants";
import { DateInput } from "react-hichestan-datetimepicker";
import EnumSelector from "../../components/EnumSelector/EnumSelector";
import validator from "validator";
import IRCheck from "ircheck";

function BlackListPerson() {
  const tableHeightRef = useRef(null);
  const reduxState = useSelector((state) => state);
  const dispatch = useDispatch();
  const [staticDatas, setStaticDatas] = useState({});
  const [paginationFilter, setPaginationFilter] = useState({
    pointer: 1,
    items: [],
    docNumberPerPage: "15",
    goTo: "",
  });
  const [blackListPersonModal, setBlackListPersonModal] = useState(false);
  const [blackListPersonInputs, setBlackListPersonInputs] = useState({
    eBlackListPersonId: "",
    name: "",
    family: "",
    codeMelli: "",
    image: "",
    letterNumber: "",
    letterDatetime: "",
    letterSender: "",
    letterReciever: "",
    letterClassificationId: 0,
    letterClassificationDisplay: "",
    letterArjahiyat: "",
    letterAttachment: "",
    letterReason: "",
    letterReceivedDatetime: "",
    description: "",
  });
  const [blackListPersonInputsErr, setBlackListPersonInputsErr] = useState({
    nameErr: "",
    familyErr: "",
    codeMelliErr: "",
    imageErr: "",
  });
  const getStaticData = () => {
    AxiosInterceptor.post("/Setting/List", {
      page: 1,
      pageSize: 100,
      orderBy: "",
      filter: "",
    }).then((response) => {
      if (response.data.status) {
        let obj = {};
        response.data.settings.data.forEach((item) => {
          obj[item.key] = JSON.parse(item.value);
        });
        setStaticDatas(obj);
      } else {
        swal({
          icon: "error",
          title: "خطا",
          text: response.data.message,
          button: "متوجه شدم",
        });
      }
    });
  };
  const getAllblackListPersons = (page, take) => {
    dispatch(toggleLoading(true));
    AxiosInterceptor.post("/BlackListPerson/List", {
      page: +page,
      pageSize: take ? +take : +paginationFilter.docNumberPerPage,
      orderBy: "",
      filter: "",
    }).then((response) => {
      if (response.data.status) {
        dispatch(
          setblpData({
            list: response.data.persons.data,
            count: response.data.persons.count,
          })
        );
      } else {
        swal({
          icon: "error",
          title: "خطا",
          text: response.data.message,
          button: "متوجه شدم",
        });
      }
      dispatch(toggleLoading(false));
    });
  };
  const blackListPersonOnChangeHandle = (e) => {
    let { name, value, type } = e.target;
    if (type === "number") {
      setBlackListPersonInputs({
        ...blackListPersonInputs,
        [name]: +value,
      });
    } else if (type === "select-one") {
      if (value === "انتخاب کنید") {
        setBlackListPersonInputs({
          ...blackListPersonInputs,
          [name]: null,
        });
      } else if (value === "true") {
        setBlackListPersonInputs({
          ...blackListPersonInputs,
          [name]: true,
        });
      } else if (value === "false") {
        setBlackListPersonInputs({
          ...blackListPersonInputs,
          [name]: false,
        });
      } else {
        setBlackListPersonInputs({
          ...blackListPersonInputs,
          [name]: +value,
        });
      }
    } else {
      setBlackListPersonInputs({
        ...blackListPersonInputs,
        [name]: value,
      });
    }
  };
  const editBlackListPerson = (blp) => {
    setBlackListPersonInputs(blp);
    setBlackListPersonModal(true);
  };
  const deleteBlackListPerson = (oId) => {
    let alertInput = document.createElement("div");
    ReactDOM.render(
      <Form.Control id="adminPassword" name="adminPassword" type="password" />,
      alertInput
    );
    let el = alertInput.firstChild;
    swal("برای حذف فرد باید کلمه عبور ادمین را وارد نمایید.", {
      content: el,
      buttons: ["لغو", "حذف"],
    }).then((value) => {
      if (value) {
        dispatch(toggleLoading(true));
        AxiosInterceptor.post("/BlackListPerson/Delete", {
          id: oId,
          password: document.querySelector("#adminPassword").value,
        }).then((response) => {
          if (response.data.status) {
            swal({
              icon: "success",
              title: "موفقیت",
              text: "فرد مورد نظر با موفقیت حذف گردید",
              button: "متوجه شدم",
            });
            getAllblackListPersons(1);
          } else {
            swal({
              icon: "error",
              title: "خطا",
              text: response.data.message,
              button: "متوجه شدم",
            });
          }
        });
      }
    });
  };
  const ADDorUPDATEBlackListPerson = (actionType) => {
    let isValid = true;
    if (validator.isEmpty(blackListPersonInputs.name)) {
      setBlackListPersonInputsErr((prevState) => ({
        ...prevState,
        nameErr: "نام نمیتواند خالی باشد",
      }));
      isValid = false;
    } else {
      setBlackListPersonInputsErr((prevState) => ({
        ...prevState,
        nameErr: "",
      }));
    }
    if (validator.isEmpty(blackListPersonInputs.family)) {
      setBlackListPersonInputsErr((prevState) => ({
        ...prevState,
        familyErr: "نام خانوادگی نمیتواند خالی باشد",
      }));
      isValid = false;
    } else {
      setBlackListPersonInputsErr((prevState) => ({
        ...prevState,
        familyErr: "",
      }));
    }
    if (validator.isEmpty(blackListPersonInputs.image)) {
      setBlackListPersonInputsErr((prevState) => ({
        ...prevState,
        imageErr: "انتخاب تصویر الزامی است",
      }));
      isValid = false;
    } else {
      setBlackListPersonInputsErr((prevState) => ({
        ...prevState,
        imageErr: "",
      }));
    }
    if (
      !IRCheck.National.isNationalCodeValid(blackListPersonInputs.codeMelli)
    ) {
      setBlackListPersonInputsErr((prevState) => ({
        ...prevState,
        codeMelliErr: "کد ملی معتبر وارد نمایید",
      }));
      isValid = false;
    } else {
      setBlackListPersonInputsErr((prevState) => ({
        ...prevState,
        codeMelliErr: "",
      }));
    }
    if (isValid) {
      dispatch(toggleModalLoading(true));
      let data = { ...blackListPersonInputs };
      let msg = "";
      delete data.letterClassificationDisplay;
      if (data.letterDatetime === "") {
        data["letterDatetime"] = null;
      }
      if (data.letterReceivedDatetime === "") {
        data["letterReceivedDatetime"] = null;
      }
      if (actionType === "ADD") {
        delete data.eBlackListPersonId;
        msg = "فرد مورد نظر با موفقیت ثبت گردید.";
      } else {
        msg = "فرد مورد نظر با موفقیت به روز رسانی گردید.";
      }
      AxiosInterceptor.post("/BlackListPerson/Edit", data).then((response) => {
        if (response.data.status) {
          swal({
            icon: "success",
            title: "موفقیت",
            text: msg,
            button: "متوجه شدم",
          });
          setBlackListPersonModal(false);
          getAllblackListPersons(1);
        } else {
          swal({
            icon: "error",
            title: "خطا",
            text: response.data.message,
            button: "متوجه شدم",
          });
        }
        dispatch(toggleModalLoading(false));
      });
    }
  };
  const fileChangeHandler = (e) => {
    let size = (e.target.files[0].size / 1024).toFixed(2);
    let fileName = e.target.files[0].name;
    if (size > 5000) {
      swal({
        icon: "error",
        title: "خطا",
        text: "حداکثر حجم مجاز 5 مگابایت است",
        button: "متوجه شدم",
      });
    } else if (fileName.includes(".exe")) {
      swal({
        icon: "error",
        title: "خطا",
        text: "فایل‌های اجرایی را نمیتوان آپلود کرد",
        button: "متوجه شدم",
      });
    } else {
      dispatch(toggleModalLoading(true));
      let reader = new FileReader();
      reader.onloadend = () => {
        AxiosInterceptor.post("/Setting/Upload", {
          file: reader.result,
          name: fileName,
        }).then((response) => {
          setBlackListPersonInputs({
            ...blackListPersonInputs,
            image: response.data.message,
          });
          dispatch(toggleModalLoading(false));
        });
      };
      reader.readAsDataURL(e.target.files[0]);
    }
  };
  useEffect(() => {
    getAllblackListPersons(1);
    getStaticData();
  }, []);
  useEffect(() => {
    if (+reduxState.blpersons.count > 0) {
      let arr = [];
      let groupsNumber =
        parseInt(reduxState.blpersons.count) /
        parseInt(paginationFilter.docNumberPerPage);
      let i = 1;
      while (groupsNumber > 0) {
        groupsNumber--;
        arr.push(i);
        i++;
      }
      setPaginationFilter({
        ...paginationFilter,
        items: arr,
      });
    }
  }, [paginationFilter.docNumberPerPage, reduxState.blpersons.count]);
  return (
    <Dashboard>
      <div className="shared-wrapper p-4">
        <Row className="mb-2">
          <Col
            sm={12}
            lg={4}
            className="d-flex align-item-center justify-content-start"
          >
            <h5 className="m-0 set-column-middle">لیست افراد ممنوعه</h5>
          </Col>
          <Col
            sm={12}
            lg={8}
            className="d-flex align-item-center justify-content-end"
          >
            <button
              className="btn btn-success mx-2 text-white"
              onClick={() => {
                setBlackListPersonInputs({
                  eBlackListPersonId: "",
                  name: "",
                  family: "",
                  codeMelli: "",
                  image: "",
                  letterNumber: "",
                  letterDatetime: "",
                  letterSender: "",
                  letterReciever: "",
                  letterClassificationId: 0,
                  letterClassificationDisplay: "",
                  letterArjahiyat: "",
                  letterAttachment: "",
                  letterReason: "",
                  letterReceivedDatetime: "",
                  description: "",
                });
                setBlackListPersonModal(true);
              }}
            >
              افزودن فرد جدید +
            </button>
          </Col>
        </Row>
        <hr />
        <Row
          ref={tableHeightRef}
          style={{
            overflowY: "auto",
          }}
        >
          <Table striped bordered hover responsive>
            <thead className="gradiantBg text-white text-center">
              <tr>
                <th scope="col" className="text-nowrap">
                  ردیف
                </th>
                <th scope="col" className="text-nowrap">
                  تصویر
                </th>
                <th scope="col" className="text-nowrap">
                  نام و نام خانوادگی
                </th>
                <th scope="col" className="text-nowrap">
                  کدملی
                </th>
                <th scope="col" className="text-nowrap">
                  عملیات
                </th>
              </tr>
            </thead>
            <tbody className="text-center">
              {reduxState.blpersons.list.length > 0 ? (
                reduxState.blpersons.list?.map((blp, index) => {
                  return (
                    <tr key={index} id={blp.eBlackListPersonId}>
                      <td className="text-nowrap">
                        {parseInt(paginationFilter.pointer) *
                          parseInt(paginationFilter.docNumberPerPage) +
                          (index +
                            1 -
                            parseInt(paginationFilter.docNumberPerPage))}
                      </td>
                      <td className="text-nowrap set-row-middle">
                        <div style={{ width: "40px", height: "40px" }}>
                          <Image
                            className="cpointer zoomable_avatar"
                            src={blp.image ? IMAGEURLS + blp.image : defProfile}
                            width="40px"
                            height="40px"
                          />
                        </div>
                      </td>
                      <td className="text-nowrap">
                        {blp.name + " " + blp.family}
                      </td>
                      <td className="text-nowrap">{blp.codeMelli}</td>
                      <td>
                        <div className="set-row-middle">
                          <span
                            className="mx-1 cpointer text-nowrap"
                            onClick={() => {
                              editBlackListPerson(blp);
                            }}
                          >
                            <i className="ms-1">
                              <PencilSquare
                                className="text-warning"
                                size={"1.2rem"}
                              />
                            </i>
                            ویرایش
                          </span>
                          <span
                            className="mx-1 cpointer text-nowrap"
                            onClick={() => {
                              deleteBlackListPerson(blp.eBlackListPersonId);
                            }}
                          >
                            <i className="ms-1">
                              <Trash className="text-danger" size={"1.2rem"} />
                            </i>
                            حذف
                          </span>
                        </div>
                      </td>
                    </tr>
                  );
                })
              ) : (
                <tr>
                  <td colSpan={5}>لیست افراد خالی میباشد</td>
                </tr>
              )}
            </tbody>
          </Table>
        </Row>
        <Row>
          <Col sm={12} className="px-3 py-3 set-row-middle position-relative">
            <div
              className="input-wrapper flex-nowrap"
              style={{ position: "absolute", right: "5px" }}
            >
              <Form.Group className="set-row-middle">
                <InputGroup.Text>برو به صفحه</InputGroup.Text>
                <FormControl
                  name="goTo"
                  type="number"
                  style={{ maxWidth: "70px" }}
                  value={paginationFilter.goTo}
                  onChange={(e) => {
                    setPaginationFilter({
                      ...paginationFilter,
                      goTo: e.target.value,
                    });
                  }}
                />
                <button
                  className="btn gradiantBg"
                  onClick={() => {
                    if (paginationFilter.goTo !== "") {
                      getAllblackListPersons(paginationFilter.goTo);
                    }
                  }}
                >
                  <ArrowLeftCircleFill size="1.3em" color="#fff" />
                </button>
              </Form.Group>
            </div>
            <ul
              id="paginationList"
              className="m-0 p-0 list-unstyled set-row-middle flex-wrap"
            >
              <li
                className="ms-1 cpointer"
                onClick={() => {
                  if (paginationFilter.pointer !== paginationFilter.items[0]) {
                    getAllblackListPersons(paginationFilter.pointer - 1);
                  }
                }}
              >
                <ArrowRight size="1.3em" />
              </li>
              {paginationFilter.pointer !== 1 && (
                <li
                  className="paginationItems"
                  onClick={() => {
                    getAllblackListPersons(1);
                  }}
                >
                  1
                </li>
              )}
              {paginationFilter.pointer > 2 && (
                <li>
                  <strong>.....</strong>
                </li>
              )}
              {paginationFilter.items?.map((pa, index) => {
                return index + 1 === paginationFilter.pointer ||
                  (index <= paginationFilter.pointer + 1 &&
                    index + 1 >= paginationFilter.pointer) ||
                  index + 1 ===
                    paginationFilter.items[
                      paginationFilter.items.length - 1
                    ] ? (
                  <li
                    key={index}
                    className={`paginationItems ${
                      index + 1 === paginationFilter.pointer
                        ? "paginationItemActive"
                        : ""
                    }`}
                    onClick={() => {
                      getAllblackListPersons(pa);
                    }}
                  >
                    {pa}
                  </li>
                ) : index + 1 ===
                  paginationFilter.items[paginationFilter.items.length - 2] ? (
                  <>
                    <li>
                      <strong>.....</strong>
                    </li>
                    <li
                      key={index}
                      className={`paginationItems ${
                        index + 1 === paginationFilter.pointer
                          ? "paginationItemActive"
                          : ""
                      }`}
                      onClick={() => {
                        getAllblackListPersons(pa);
                      }}
                    >
                      {pa}
                    </li>
                  </>
                ) : (
                  <></>
                );
              })}

              <li
                className="me-1 cpointer"
                onClick={() => {
                  if (
                    paginationFilter.pointer !==
                    paginationFilter.items[paginationFilter.items.length - 1]
                  ) {
                    getAllblackListPersons(paginationFilter.pointer + 1);
                  }
                }}
              >
                <ArrowLeft size="1.3em" />
              </li>
            </ul>
            <div
              className="input-wrapper flex-nowrap"
              style={{ position: "absolute", left: "5px" }}
            >
              <Form.Group className="set-row-middle">
                <InputGroup.Text>تعداد سند در هر صفحه</InputGroup.Text>
                <Form.Select
                  value={paginationFilter.docNumberPerPage}
                  name="docNumberPerPage"
                  id="docNumberPerPage"
                  onChange={(e) => {
                    getAllblackListPersons(1, e.target.value);
                  }}
                >
                  <option value="5">5</option>
                  <option value="10">10</option>
                  <option value="15">15</option>
                </Form.Select>
              </Form.Group>
            </div>
          </Col>
        </Row>
      </div>
      <Portal show={blackListPersonModal}>
        <Modal.Header>
          <Modal.Title id="contained-modal-title-vcenter">
            افزودن فرد جدید
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Row>
            <Col sm={12} lg={3} className="p-3 set-column-middle">
              <div
                className={`profileImage-wrapper set-column-middle ${
                  blackListPersonInputsErr.imageErr ? "errorBorder" : ""
                }`}
              >
                <img
                  src={
                    blackListPersonInputs.image
                      ? IMAGEURLS + blackListPersonInputs.image
                      : defProfile
                  }
                  alt="profile"
                />
                <input
                  type="file"
                  name="image"
                  className="d-none"
                  id="orgImageInput"
                  onChange={fileChangeHandler}
                />
                <button
                  className="btn btn-primary"
                  style={{
                    width: "50px",
                    height: "50px",
                    position: "absolute",
                    top: "-20px",
                    right: "-20px",
                    borderRadius: "50%",
                  }}
                  onClick={() => {
                    document.querySelector("#orgImageInput").click();
                  }}
                >
                  <PencilSquare size="1.3em" />
                </button>
              </div>
              <p className="error text-center mt-2">
                {blackListPersonInputsErr.imageErr}
              </p>
            </Col>
            <Col sm={12} lg={9}>
              <Row>
                <Col sm={12} lg={4}>
                  <Form.Group className="input-wrapper">
                    <InputGroup.Text>نام</InputGroup.Text>
                    <FormControl
                      name="name"
                      type="text"
                      className={
                        blackListPersonInputsErr.nameErr ? "errorBorder" : ""
                      }
                      value={blackListPersonInputs.name}
                      onChange={blackListPersonOnChangeHandle}
                    />
                  </Form.Group>
                  <p className="error text-center">
                    {blackListPersonInputsErr.nameErr}
                  </p>
                </Col>
                <Col sm={12} lg={4}>
                  <Form.Group className="input-wrapper">
                    <InputGroup.Text>نام خانوادگی</InputGroup.Text>
                    <FormControl
                      name="family"
                      type="text"
                      className={
                        blackListPersonInputsErr.familyErr ? "errorBorder" : ""
                      }
                      value={blackListPersonInputs.family}
                      onChange={blackListPersonOnChangeHandle}
                    />
                  </Form.Group>
                  <p className="error text-center">
                    {blackListPersonInputsErr.familyErr}
                  </p>
                </Col>
                <Col sm={12} lg={4}>
                  <Form.Group className="input-wrapper">
                    <InputGroup.Text>کد ملی</InputGroup.Text>
                    <FormControl
                      name="codeMelli"
                      type="text"
                      className={
                        blackListPersonInputsErr.codeMelliErr
                          ? "errorBorder"
                          : ""
                      }
                      value={blackListPersonInputs.codeMelli}
                      onChange={blackListPersonOnChangeHandle}
                    />
                  </Form.Group>
                  <p className="error text-center">
                    {blackListPersonInputsErr.codeMelliErr}
                  </p>
                </Col>
                <hr />
                <Col sm={12} lg={4}>
                  <Form.Group className="input-wrapper">
                    <InputGroup.Text>شماره نامه</InputGroup.Text>
                    <FormControl
                      name="letterNumber"
                      type="text"
                      value={blackListPersonInputs.letterNumber}
                      onChange={blackListPersonOnChangeHandle}
                    />
                  </Form.Group>
                </Col>
                <Col sm={12} lg={4} className="input-wrapper">
                  <InputGroup className="flex-nowrap">
                    <InputGroup.Text>تاریخ نامه </InputGroup.Text>
                    <DateInput
                      name="letterDatetime"
                      onChange={(e) => {
                        setBlackListPersonInputs({
                          ...blackListPersonInputs,
                          [e.target.name]: e.target.value.split("T")[0],
                        });
                      }}
                      autoOk={true}
                      value={
                        blackListPersonInputs.letterDatetime
                          ? blackListPersonInputs.letterDatetime
                          : ""
                      }
                    />
                  </InputGroup>
                </Col>
                <Col sm={12} lg={4}>
                  <Form.Group className="input-wrapper">
                    <EnumSelector
                      data={staticDatas}
                      enumKey="PProjectLevel"
                      name="letterClassificationId"
                      title="طبقه بندی"
                      value={blackListPersonInputs.letterClassificationId}
                      onChange={blackListPersonOnChangeHandle}
                    />
                  </Form.Group>
                </Col>
                <Col sm={12} lg={4}>
                  <Form.Group className="input-wrapper">
                    <InputGroup.Text>فرستنده</InputGroup.Text>
                    <FormControl
                      name="letterSender"
                      type="text"
                      value={blackListPersonInputs.letterSender}
                      onChange={blackListPersonOnChangeHandle}
                    />
                  </Form.Group>
                </Col>
                <Col sm={12} lg={4}>
                  <Form.Group className="input-wrapper">
                    <InputGroup.Text>گیرنده</InputGroup.Text>
                    <FormControl
                      name="letterReciever"
                      type="text"
                      value={blackListPersonInputs.letterReciever}
                      onChange={blackListPersonOnChangeHandle}
                    />
                  </Form.Group>
                </Col>
                <Col sm={12} lg={4}>
                  <Form.Group className="input-wrapper">
                    <InputGroup.Text>ارجعیت</InputGroup.Text>
                    <FormControl
                      name="letterArjahiyat"
                      type="text"
                      value={blackListPersonInputs.letterArjahiyat}
                      onChange={blackListPersonOnChangeHandle}
                    />
                  </Form.Group>
                </Col>
                <Col sm={12} lg={4}>
                  <Form.Group className="input-wrapper">
                    <InputGroup.Text>پیوست</InputGroup.Text>
                    <FormControl
                      name="letterAttachment"
                      type="text"
                      value={blackListPersonInputs.letterAttachment}
                      onChange={blackListPersonOnChangeHandle}
                    />
                  </Form.Group>
                </Col>
                <Col sm={12} lg={4}>
                  <Form.Group className="input-wrapper">
                    <InputGroup.Text>علت</InputGroup.Text>
                    <FormControl
                      name="letterReason"
                      type="text"
                      value={blackListPersonInputs.letterReason}
                      onChange={blackListPersonOnChangeHandle}
                    />
                  </Form.Group>
                </Col>
                <Col sm={12} lg={4} className="input-wrapper">
                  <InputGroup className="flex-nowrap">
                    <InputGroup.Text>تاریخ دریافت</InputGroup.Text>
                    <DateInput
                      name="letterReceivedDatetime"
                      onChange={(e) => {
                        setBlackListPersonInputs({
                          ...blackListPersonInputs,
                          [e.target.name]: e.target.value.split("T")[0],
                        });
                      }}
                      autoOk={true}
                      value={
                        blackListPersonInputs.letterReceivedDatetime
                          ? blackListPersonInputs.letterReceivedDatetime
                          : ""
                      }
                    />
                  </InputGroup>
                </Col>
                <Col sm={12} lg={12} className="input-wrapper">
                  <InputGroup>
                    <InputGroup.Text>توضیحات</InputGroup.Text>
                    <FormControl
                      as="textarea"
                      aria-label="With textarea"
                      name="description"
                      id="description"
                      type="text"
                      value={blackListPersonInputs.description}
                      onChange={blackListPersonOnChangeHandle}
                    />
                  </InputGroup>
                </Col>
                <Col sm={12}>
                  {blackListPersonInputs.eBlackListPersonId ? (
                    <button
                      className="btn btn-success w-100"
                      onClick={() => ADDorUPDATEBlackListPerson("UPDATE")}
                    >
                      به روز رسانی اطلاعات
                    </button>
                  ) : (
                    <button
                      className="btn btn-success w-100"
                      onClick={() => ADDorUPDATEBlackListPerson("ADD")}
                    >
                      ثبت اطلاعات
                    </button>
                  )}
                </Col>
              </Row>
            </Col>
          </Row>
        </Modal.Body>
        <Modal.Footer>
          <button
            className="btn btn-danger"
            onClick={() => {
              setBlackListPersonModal(false);
            }}
          >
            بستن
          </button>
        </Modal.Footer>
      </Portal>
    </Dashboard>
  );
}

export default BlackListPerson;
