import React, { useEffect, useState, useRef } from "react";
import ReactDOM from "react-dom";
import {
  Table,
  Row,
  Col,
  Modal,
  InputGroup,
  FormControl,
  Form,
} from "react-bootstrap";
import {
  PencilSquare,
  Trash,
  ArrowRight,
  ArrowLeftCircleFill,
  ArrowLeft,
} from "react-bootstrap-icons";
import Dashboard from "components/Dashboard/Dashboard";
import AxiosInterceptor from "utils/AxiosInterceptor";
import swal from "sweetalert";
import { setUsersData, toggleLoading, toggleModalLoading } from "redux/actions";
import { useSelector, useDispatch } from "react-redux";
import Portal from "components/Portal/Portal";
import { useNavigate, useLocation } from "react-router-dom";
import validator from "validator";
import Breadcrumb from "components/Breadcrumb/Breadcrumb";

function Users() {
  const navigate = useNavigate();
  const params = useLocation();
  const tableHeightRef = useRef(null);
  const reduxState = useSelector((state) => state);
  const dispatch = useDispatch();
  const [paginationFilter, setPaginationFilter] = useState({
    pointer: 1,
    items: [],
    docNumberPerPage: "15",
    goTo: "",
  });
  const [showUsersModal, setShowUsersModal] = useState(false);
  const [usersInputs, setUsersInputs] = useState({
    eUserId: "",
    eOrganizationId: "",
    organizatioName: "",
    eLiteCompanyId: "",
    companyName: "",
    eProjectId: "",
    projectName: "",
    username: "",
    password: "",
    accessType: 0,
  });
  const [usersInputsErr, setUsersInputsErr] = useState({
    usernameErr: "",
    passwordErr: "",
  });
  const getAllUsers = (page, take) => {
    let orgId = params.search.split("&")[0].replace("?orgId=", "");
    let orgName = params.search.split("&")[1].replace("orgName=", "");
    let compId = params.search.split("&")[2].replace("compId=", "");
    let compName = params.search.split("&")[3].replace("compName=", "");
    let prjId = params.search.split("&")[4].replace("prjId=", "");
    let prjName = params.search.split("&")[5].replace("prjName=", "");
    setUsersInputs({
      ...usersInputs,
      eOrganizationId: orgId,
      organizatioName: decodeURIComponent(orgName),
      eLiteCompanyId: compId,
      companyName: decodeURIComponent(compName),
      eProjectId: prjId,
      projectName: decodeURIComponent(prjName),
    });
    if (orgId && compId && prjId) {
      dispatch(toggleLoading(true));
      AxiosInterceptor.post("/User/List", {
        page: +page,
        pageSize: take ? +take : +paginationFilter.docNumberPerPage,
        orderBy: "",
        filter: `eProjectId=${prjId}`,
      }).then((response) => {
        if (response.data.status) {
          dispatch(
            setUsersData({
              list: response.data.users.data,
              count: response.data.users.count,
            })
          );
        } else {
          swal({
            icon: "error",
            title: "خطا",
            text: response.data.message,
            button: "متوجه شدم",
          });
        }
        dispatch(toggleLoading(false));
      });
    } else {
      swal({
        icon: "error",
        title: "خطا",
        text: "ابتدا سازمان و شرکت و پروژه مورد نظر را مشخص نمایید",
        button: "متوجه شدم",
      });
      navigate("/Organizations");
    }
  };
  const userOnChangeHandle = (e) => {
    let { name, value, type } = e.target;
    if (type === "number") {
      setUsersInputs({
        ...usersInputs,
        [name]: +value,
      });
    } else if (type === "select-one") {
      if (value === "انتخاب کنید") {
        setUsersInputs({
          ...usersInputs,
          [name]: null,
        });
      } else if (value === "true") {
        setUsersInputs({
          ...usersInputs,
          [name]: true,
        });
      } else if (value === "false") {
        setUsersInputs({
          ...usersInputs,
          [name]: false,
        });
      } else {
        setUsersInputs({
          ...usersInputs,
          [name]: +value,
        });
      }
    } else {
      setUsersInputs({
        ...usersInputs,
        [name]: value,
      });
    }
  };
  const editUser = (usr) => {
    // console.log(usr)
    setUsersInputs({
      ...usersInputs,
      eUserId: usr.eUserId,
      username: usr.username,
      password: usr.password,
      accessType: usr.accessTypeInt,
    });
    setShowUsersModal(true);
  };
  const deleteUser = (oId) => {
    let alertInput = document.createElement("div");
    ReactDOM.render(
      <Form.Control id="adminPassword" name="adminPassword" type="password" />,
      alertInput
    );
    let el = alertInput.firstChild;
    swal("برای حذف کاربر باید کلمه عبور ادمین را وارد نمایید.", {
      content: el,
      buttons: ["لغو", "حذف"],
    }).then((value) => {
      if (value) {
        AxiosInterceptor.post("/User/Delete", {
          id: oId,
          password: document.querySelector("#adminPassword").value,
        }).then((response) => {
          if (response.data.status) {
            swal({
              icon: "success",
              title: "موفقیت",
              text: "کاربر مورد نظر با موفقیت حذف گردید",
              button: "متوجه شدم",
            });
            getAllUsers(1);
          } else {
            swal({
              icon: "error",
              title: "خطا",
              text: response.data.message,
              button: "متوجه شدم",
            });
          }
        });
      }
    });
  };
  const ADDorUPDATEUser = (actionType) => {
    let isValid = true;
    if (validator.isEmpty(usersInputs.username)) {
      setUsersInputsErr((prevState) => ({
        ...prevState,
        usernameErr: "نام کاربری نمیتواند خالی باشد.",
      }));
      isValid = false;
    } else {
      setUsersInputsErr((prevState) => ({
        ...prevState,
        usernameErr: "",
      }));
    }
    if (validator.isEmpty(usersInputs.password)) {
      setUsersInputsErr((prevState) => ({
        ...prevState,
        passwordErr: "رمز عبور نمیتواند خالی باشد.",
      }));
      isValid = false;
    } else {
      setUsersInputsErr((prevState) => ({
        ...prevState,
        passwordErr: "",
      }));
    }
    if (isValid) {
      dispatch(toggleModalLoading(true));
      let data = {};
      let msg = "";
      if (actionType === "ADD") {
        msg = "کاربر مورد نظر با موفقیت ثبت گردید.";
        data = {
          eOrganizationId: usersInputs.eOrganizationId,
          eLiteCompanyId: usersInputs.eLiteCompanyId,
          eProjectId: usersInputs.eProjectId,
          username: usersInputs.username,
          password: usersInputs.password,
          accessType: +usersInputs.accessType,
        };
      } else {
        msg = "کاربر مورد نظر با موفقیت به روز رسانی گردید.";
        data = {
          eUserId: usersInputs.eUserId,
          eOrganizationId: usersInputs.eOrganizationId,
          eLiteCompanyId: usersInputs.eLiteCompanyId,
          eProjectId: usersInputs.eProjectId,
          username: usersInputs.username,
          password: usersInputs.password,
          accessType: +usersInputs.accessType,
        };
      }
      AxiosInterceptor.post("/User/Edit", data).then((response) => {
        if (response.data.status) {
          swal({
            icon: "success",
            title: "موفقیت",
            text: msg,
            button: "متوجه شدم",
          });
          setShowUsersModal(false);
          getAllUsers(1);
        } else {
          swal({
            icon: "error",
            title: "خطا",
            text: response.data.message,
            button: "متوجه شدم",
          });
        }
        dispatch(toggleModalLoading(false));
      });
    }
  };
  useEffect(() => {
    getAllUsers(1);
  }, [params]);
  useEffect(() => {
    if (+reduxState.users.count > 0) {
      let arr = [];
      let groupsNumber =
        parseInt(reduxState.users.count) /
        parseInt(paginationFilter.docNumberPerPage);
      let i = 1;
      while (groupsNumber > 0) {
        groupsNumber--;
        arr.push(i);
        i++;
      }
      setPaginationFilter({
        ...paginationFilter,
        items: arr,
      });
    }
  }, [paginationFilter.docNumberPerPage, reduxState.users.count]);
  return (
    <Dashboard>
      <div className="shared-wrapper p-4">
        <Row className="mb-2">
          <Col
            sm={12}
            lg={9}
            className="d-flex align-item-center justify-content-start"
          >
            <Breadcrumb
              title={"لیست کاربران"}
              list={[
                usersInputs.projectName,
                usersInputs.companyName,
                usersInputs.organizatioName,
              ]}
            />
          </Col>
          <Col
            sm={12}
            lg={3}
            className="d-flex align-item-center justify-content-end"
          >
            <button
              className="btn btn-success mx-2 text-white"
              onClick={() => {
                setUsersInputs({
                  ...usersInputs,
                  eUserId: "",
                  username: "",
                  password: "",
                  accessType: 0,
                });
                setShowUsersModal(true);
              }}
            >
              افزودن کاربر جدید +
            </button>
          </Col>
        </Row>
        <hr />
        <Row
          ref={tableHeightRef}
          style={{
            overflowY: "auto",
          }}
        >
          <Table striped bordered hover responsive>
            <thead className="gradiantBg text-white text-center">
              <tr>
                <th scope="col" className="text-nowrap">
                  ردیف
                </th>
                <th scope="col" className="text-nowrap">
                  نام کاربری
                </th>
                <th scope="col" className="text-nowrap">
                  رمز عبور
                </th>
                <th scope="col" className="text-nowrap">
                  نوع
                </th>
                <th scope="col" className="text-nowrap">
                  عملیات
                </th>
              </tr>
            </thead>
            <tbody className="text-center">
              {reduxState.users.list.length > 0 ? (
                reduxState.users.list?.map((usr, index) => {
                  return (
                    <tr key={index} id={usr.eUserId}>
                      <td className="text-nowrap">
                        {parseInt(paginationFilter.pointer) *
                          parseInt(paginationFilter.docNumberPerPage) +
                          (index +
                            1 -
                            parseInt(paginationFilter.docNumberPerPage))}
                      </td>
                      <td className="text-nowrap">{usr.username}</td>
                      <td className="text-nowrap">*****</td>
                      <td className="text-nowrap">{usr.accessType}</td>
                      <td>
                        <div className="set-row-middle">
                          <span
                            className="mx-1 cpointer text-nowrap"
                            onClick={() => {
                              editUser(usr);
                            }}
                          >
                            <i className="ms-1">
                              <PencilSquare
                                className="text-warning"
                                size={"1.2rem"}
                              />
                            </i>
                            ویرایش
                          </span>
                          <span
                            className="mx-1 cpointer text-nowrap"
                            onClick={() => {
                              deleteUser(usr.eUserId);
                            }}
                          >
                            <i className="ms-1">
                              <Trash className="text-danger" size={"1.2rem"} />
                            </i>
                            حذف
                          </span>
                        </div>
                      </td>
                    </tr>
                  );
                })
              ) : (
                <tr>
                  <td colSpan={9}>لیست کاربران خالی میباشد</td>
                </tr>
              )}
            </tbody>
          </Table>
        </Row>
        <Row>
          <Col sm={12} className="px-3 py-3 set-row-middle position-relative">
            <div
              className="input-wrapper flex-nowrap"
              style={{ position: "absolute", right: "5px" }}
            >
              <Form.Group className="set-row-middle">
                <InputGroup.Text>برو به صفحه</InputGroup.Text>
                <FormControl
                  name="goTo"
                  type="number"
                  style={{ maxWidth: "70px" }}
                  value={paginationFilter.goTo}
                  onChange={(e) => {
                    setPaginationFilter({
                      ...paginationFilter,
                      goTo: e.target.value,
                    });
                  }}
                />
                <button
                  className="btn gradiantBg"
                  onClick={() => {
                    if (paginationFilter.goTo !== "") {
                      getAllUsers(paginationFilter.goTo);
                    }
                  }}
                >
                  <ArrowLeftCircleFill size="1.3em" color="#fff" />
                </button>
              </Form.Group>
            </div>
            <ul
              id="paginationList"
              className="m-0 p-0 list-unstyled set-row-middle flex-wrap"
            >
              <li
                className="ms-1 cpointer"
                onClick={() => {
                  if (paginationFilter.pointer !== paginationFilter.items[0]) {
                    getAllUsers(paginationFilter.pointer - 1);
                  }
                }}
              >
                <ArrowRight size="1.3em" />
              </li>
              {paginationFilter.pointer !== 1 && (
                <li
                  className="paginationItems"
                  onClick={() => {
                    getAllUsers(1);
                  }}
                >
                  1
                </li>
              )}
              {paginationFilter.pointer > 2 && (
                <li>
                  <strong>.....</strong>
                </li>
              )}
              {paginationFilter.items?.map((pa, index) => {
                return index + 1 === paginationFilter.pointer ||
                  (index <= paginationFilter.pointer + 1 &&
                    index + 1 >= paginationFilter.pointer) ||
                  index + 1 ===
                    paginationFilter.items[
                      paginationFilter.items.length - 1
                    ] ? (
                  <li
                    key={index}
                    className={`paginationItems ${
                      index + 1 === paginationFilter.pointer
                        ? "paginationItemActive"
                        : ""
                    }`}
                    onClick={() => {
                      getAllUsers(pa);
                    }}
                  >
                    {pa}
                  </li>
                ) : index + 1 ===
                  paginationFilter.items[paginationFilter.items.length - 2] ? (
                  <>
                    <li>
                      <strong>.....</strong>
                    </li>
                    <li
                      key={index}
                      className={`paginationItems ${
                        index + 1 === paginationFilter.pointer
                          ? "paginationItemActive"
                          : ""
                      }`}
                      onClick={() => {
                        getAllUsers(pa);
                      }}
                    >
                      {pa}
                    </li>
                  </>
                ) : (
                  <></>
                );
              })}

              <li
                className="me-1 cpointer"
                onClick={() => {
                  if (
                    paginationFilter.pointer !==
                    paginationFilter.items[paginationFilter.items.length - 1]
                  ) {
                    getAllUsers(paginationFilter.pointer + 1);
                  }
                }}
              >
                <ArrowLeft size="1.3em" />
              </li>
            </ul>
            <div
              className="input-wrapper flex-nowrap"
              style={{ position: "absolute", left: "5px" }}
            >
              <Form.Group className="set-row-middle">
                <InputGroup.Text>تعداد سند در هر صفحه</InputGroup.Text>
                <Form.Select
                  value={paginationFilter.docNumberPerPage}
                  name="docNumberPerPage"
                  id="docNumberPerPage"
                  onChange={(e) => {
                    getAllUsers(1, e.target.value);
                  }}
                >
                  <option value="5">5</option>
                  <option value="10">10</option>
                  <option value="15">15</option>
                </Form.Select>
              </Form.Group>
            </div>
          </Col>
        </Row>
      </div>
      <Portal show={showUsersModal}>
        <Modal.Header>
          <Modal.Title id="contained-modal-title-vcenter">
            افزودن کاربر جدید
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Row>
            <Col sm={12} lg={4}>
              <Form.Group className="input-wrapper">
                <InputGroup.Text>نام کاربری</InputGroup.Text>
                <FormControl
                  name="username"
                  type="text"
                  className={usersInputsErr.usernameErr ? "errorBorder" : ""}
                  value={usersInputs.username}
                  onChange={userOnChangeHandle}
                />
              </Form.Group>
              <p className="error text-center">{usersInputsErr.usernameErr}</p>
            </Col>
            <Col sm={12} lg={4}>
              <Form.Group className="input-wrapper">
                <InputGroup.Text>رمز عبور</InputGroup.Text>
                <FormControl
                  name="password"
                  type="password"
                  className={usersInputsErr.passwordErr ? "errorBorder" : ""}
                  value={usersInputs.password}
                  onChange={userOnChangeHandle}
                />
              </Form.Group>
              <p className="error text-center">{usersInputsErr.passwordErr}</p>
            </Col>
            <Col sm={12} lg={4} className="input-wrapper">
              <Form.Group className="set-row-middle" controlId="hasEduDoc">
                <InputGroup.Text>نوع کاربر</InputGroup.Text>
                <Form.Select
                  value={usersInputs.accessType}
                  name="accessType"
                  onChange={userOnChangeHandle}
                >
                  <option value={0}>امنیتی</option>
                  <option value={1}>بازرسی</option>
                  <option value={2}>اداری</option>
                  <option value={3}>مکتبی</option>
                  <option value={4}>کاربر</option>
                </Form.Select>
              </Form.Group>
            </Col>
            <Col sm={12}>
              {usersInputs.eUserId ? (
                <button
                  className="btn btn-success w-100"
                  onClick={() => ADDorUPDATEUser("UPDATE")}
                >
                  به روز رسانی اطلاعات
                </button>
              ) : (
                <button
                  className="btn btn-success w-100"
                  onClick={() => ADDorUPDATEUser("ADD")}
                >
                  ثبت اطلاعات
                </button>
              )}
            </Col>
          </Row>
        </Modal.Body>
        <Modal.Footer>
          <button
            className="btn btn-danger"
            onClick={() => {
              setShowUsersModal(false);
            }}
          >
            بستن
          </button>
        </Modal.Footer>
      </Portal>
    </Dashboard>
  );
}

export default Users;
