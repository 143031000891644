import React, { useEffect } from "react";
import { Col, InputGroup, Form } from "react-bootstrap";
import AxiosInterceptor from "../../utils/AxiosInterceptor";
import swal from "sweetalert";
import { toggleModalLoading } from "../../redux/actions";
import { useDispatch } from "react-redux";

function OrganizationSelector({ state, setState }) {
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(toggleModalLoading(true));
    AxiosInterceptor.post("/Organization/List", {
      page: 1,
      pageSize: 100,
      orderBy: "",
      filter: "",
    }).then((response) => {
      if (response.data.status) {
        setState({
          ...state,
          arr: response.data.organizations.data,
        });
      } else {
        swal({
          icon: "error",
          title: "خطا",
          text: response.data.message,
          button: "متوجه شدم",
        });
      }
      dispatch(toggleModalLoading(false));
    });
  }, []);
  return (
    <Col sm={12} lg={4} className="m-auto">
      <Form.Group className="set-row-middle" controlId="selectedValue">
        <InputGroup.Text>لیست سازمان ها</InputGroup.Text>
        <Form.Select
          value={state.orgId}
          name="orgId"
          onChange={(e) => {
            if (e.target.value) {
              state.arr.forEach((item) => {
                if (item.eOrganizationId === e.target.value) {
                  setState({
                    ...state,
                    orgId: e.target.value,
                    orgName: item.name,
                  });
                }
              });
            } else {
              setState({
                ...state,
                orgId: e.target.value,
                orgName: "",
              });
            }
          }}
        >
          <option value={""}>انتخاب کنید</option>
          {state.arr.map((op, index) => {
            return (
              <option value={op.eOrganizationId} key={index}>
                {op.name}
              </option>
            );
          })}
        </Form.Select>
      </Form.Group>
      <p className="text-danger text-center mt-2">{state.err}</p>
    </Col>
  );
}

export default OrganizationSelector;
