import {
  SET_LITECOMPANIES_DATA,
  SET_ORGANIZATION_DATA,
  SET_BLP_DATA,
  SET_BLC_DATA,
  SET_PROJECTS_DATA,
  SET_USERS_DATA,
  SET_CONTRACTORS_DATA,
  SET_PERSONELS_DATA,
  TOGGLE_MODAL_LOADING,
  TOGGLE_LOADING,
  SET_CHANGELOG_DATA,
} from "./types";
const initialState = {
  organizations: {
    list: [],
    count: 0,
  },
  liteCompanies: {
    list: [],
    count: 0,
  },
  projects: {
    list: [],
    count: 0,
  },
  users: {
    list: [],
    count: 0,
  },
  contractors: {
    list: [],
    count: 0,
  },
  personels: {
    list: [],
    count: 0,
  },
  blpersons: {
    list: [],
    count: 0,
  },
  blcompanies: {
    list: [],
    count: 0,
  },
  changelog: {
    list: [],
    count: 0,
  },
  modalLoading: false,
  Loading: false,
};
const myReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_ORGANIZATION_DATA:
      return {
        ...state,
        organizations: {
          list: action.payload.list,
          count: action.payload.count,
        },
      };
    case SET_LITECOMPANIES_DATA:
      return {
        ...state,
        liteCompanies: {
          list: action.payload.list,
          count: action.payload.count,
        },
      };
    case SET_PROJECTS_DATA:
      return {
        ...state,
        projects: {
          list: action.payload.list,
          count: action.payload.count,
        },
      };
    case SET_CONTRACTORS_DATA:
      return {
        ...state,
        contractors: {
          list: action.payload.list,
          count: action.payload.count,
        },
      };
    case SET_PERSONELS_DATA:
      return {
        ...state,
        personels: {
          list: action.payload.list,
          count: action.payload.count,
        },
      };
    case SET_BLP_DATA:
      return {
        ...state,
        blpersons: {
          list: action.payload.list,
          count: action.payload.count,
        },
      };
    case SET_BLC_DATA:
      return {
        ...state,
        blcompanies: {
          list: action.payload.list,
          count: action.payload.count,
        },
      };
    case SET_USERS_DATA:
      return {
        ...state,
        users: {
          list: action.payload.list,
          count: action.payload.count,
        },
      };
    case SET_CHANGELOG_DATA:
      return {
        ...state,
        changelog: {
          list: action.payload.list,
          count: action.payload.count,
        },
      };
    case TOGGLE_LOADING:
      return {
        ...state,
        Loading: action.payload,
      };
    case TOGGLE_MODAL_LOADING:
      return {
        ...state,
        modalLoading: action.payload,
      };
    default:
      return state;
  }
};

export default myReducer;
