import React, { useEffect, useState, useRef } from "react";
import ReactDOM from "react-dom";
import {
  Table,
  Row,
  Col,
  Modal,
  InputGroup,
  FormControl,
  Form,
} from "react-bootstrap";
import {
  PencilSquare,
  Trash,
  CloudArrowUpFill,
  ArrowRight,
  Eye,
  ArrowLeftCircleFill,
  ArrowLeft,
  Trash2,
} from "react-bootstrap-icons";
import Dashboard from "components/Dashboard/Dashboard";
import AxiosInterceptor from "utils/AxiosInterceptor";
import swal from "sweetalert";
import { setblcData, toggleLoading, toggleModalLoading } from "redux/actions";
import { useSelector, useDispatch } from "react-redux";
import Portal from "components/Portal/Portal";
import { IMAGEURLS } from "utils/Constants";
import { DateInput } from "react-hichestan-datetimepicker";
import EnumSelector from "components/EnumSelector/EnumSelector";
import IRCheck from "ircheck";

function BlackListCompany() {
  const tableHeightRef = useRef(null);
  const reduxState = useSelector((state) => state);
  const dispatch = useDispatch();
  const [staticDatas, setStaticDatas] = useState({});
  const [paginationFilter, setPaginationFilter] = useState({
    pointer: 1,
    items: [],
    docNumberPerPage: "15",
    goTo: "",
  });
  const [blackListCompanyModal, setBlackListCompanyModal] = useState(false);
  const [blackListCompanyInputs, setBlackListCompanyInputs] = useState({
    eBlackListCompanyId: "",
    name: "",
    managerName: "",
    managerCodeMelli: "",
    shomareSabt: "",
    docs: "",
    codeMelli: "",
    letterNumber: "",
    letterDatetime: "",
    letterSender: "",
    letterReciever: "",
    letterClassificationId: 0,
    letterClassificationDisplay: "",
    letterArjahiyat: "",
    letterAttachment: "",
    letterReason: "",
    letterReceivedDatetime: "",
    description: "",
  });
  const [blackListCompanyInputsErr, setBlackListCompanyInputsErr] = useState({
    managerCodeMelliErr: "",
  });
  const getStaticData = () => {
    AxiosInterceptor.post("/Setting/List", {
      page: 1,
      pageSize: 100,
      orderBy: "",
      filter: "",
    }).then((response) => {
      if (response.data.status) {
        let obj = {};
        response.data.settings.data.forEach((item) => {
          obj[item.key] = JSON.parse(item.value);
        });
        setStaticDatas(obj);
      } else {
        swal({
          icon: "error",
          title: "خطا",
          text: response.data.message,
          button: "متوجه شدم",
        });
      }
    });
  };
  const getAllblackListCompany = (page, take) => {
    dispatch(toggleLoading(true));
    AxiosInterceptor.post("/BlackListCompany/List", {
      page: +page,
      pageSize: take ? +take : +paginationFilter.docNumberPerPage,
      orderBy: "",
      filter: "",
    }).then((response) => {
      if (response.data.status) {
        dispatch(
          setblcData({
            list: response.data.list.data,
            count: response.data.list.count,
          })
        );
      } else {
        swal({
          icon: "error",
          title: "خطا",
          text: response.data.message,
          button: "متوجه شدم",
        });
      }
      dispatch(toggleLoading(false));
    });
  };
  const blackListCompanyOnChangeHandle = (e) => {
    let { name, value, type } = e.target;
    if (type === "number") {
      setBlackListCompanyInputs({
        ...blackListCompanyInputs,
        [name]: +value,
      });
    } else if (type === "select-one") {
      if (value === "انتخاب کنید") {
        setBlackListCompanyInputs({
          ...blackListCompanyInputs,
          [name]: null,
        });
      } else if (value === "true") {
        setBlackListCompanyInputs({
          ...blackListCompanyInputs,
          [name]: true,
        });
      } else if (value === "false") {
        setBlackListCompanyInputs({
          ...blackListCompanyInputs,
          [name]: false,
        });
      } else {
        setBlackListCompanyInputs({
          ...blackListCompanyInputs,
          [name]: +value,
        });
      }
    } else {
      setBlackListCompanyInputs({
        ...blackListCompanyInputs,
        [name]: value,
      });
    }
  };
  const editBlackListCompany = (blc) => {
    setBlackListCompanyInputs(blc);
    setBlackListCompanyModal(true);
  };
  const deleteBlackListCompany = (oId) => {
    let alertInput = document.createElement("div");
    ReactDOM.render(
      <Form.Control id="adminPassword" name="adminPassword" type="password" />,
      alertInput
    );
    let el = alertInput.firstChild;
    swal("برای حذف شرکت باید کلمه عبور ادمین را وارد نمایید.", {
      content: el,
      buttons: ["لغو", "حذف"],
    }).then((value) => {
      if (value) {
        AxiosInterceptor.post("/BlackListCompany/Delete", {
          id: oId,
          password: document.querySelector("#adminPassword").value,
        }).then((response) => {
          if (response.data.status) {
            swal({
              icon: "success",
              title: "موفقیت",
              text: "شرکت مورد نظر با موفقیت حذف گردید",
              button: "متوجه شدم",
            });
            getAllblackListCompany(1);
          } else {
            swal({
              icon: "error",
              title: "خطا",
              text: response.data.message,
              button: "متوجه شدم",
            });
          }
        });
      }
    });
  };
  const ADDorUPDATEBlackListCompany = (actionType) => {
    if (
      !IRCheck.National.isNationalCodeValid(blackListCompanyInputs.managerCodeMelli)
    ) {
      setBlackListCompanyInputsErr((prevState) => ({
        ...prevState,
        managerCodeMelliErr: "لطفا کد ملی معتبر وارد نمایید"
      }));
    } else {
      setBlackListCompanyInputsErr((prevState) => ({
        ...prevState,
        managerCodeMelliErr: "",
      }));
      dispatch(toggleModalLoading(true));
      let data = { ...blackListCompanyInputs };
      let msg = "";
      delete data.letterClassificationDisplay;
      if (data.letterDatetime === "") {
        data["letterDatetime"] = null;
      }
      if (data.letterReceivedDatetime === "") {
        data["letterReceivedDatetime"] = null;
      }
      if (actionType === "ADD") {
        delete data.eBlackListCompanyId;
        msg = "شرکت مورد نظر با موفقیت ثبت گردید.";
      } else {
        msg = "شرکت مورد نظر با موفقیت به روز رسانی گردید.";
      }
      AxiosInterceptor.post("/BlackListCompany/Edit", data).then((response) => {
        if (response.data.status) {
          swal({
            icon: "success",
            title: "موفقیت",
            text: msg,
            button: "متوجه شدم",
          });
          setBlackListCompanyModal(false);
          getAllblackListCompany(1);
        } else {
          swal({
            icon: "error",
            title: "خطا",
            text: response.data.message,
            button: "متوجه شدم",
          });
        }
        dispatch(toggleModalLoading(false));
      });
    }
  };
  const fileChangeHandler = (e) => {
    let size = (e.target.files[0].size / 1024).toFixed(2);
    let fileName = e.target.files[0].name;
    if (size > 7000) {
      swal({
        icon: "error",
        title: "خطا",
        text: "حداکثر حجم مجاز 7 مگابایت است",
        button: "متوجه شدم",
      });
    } else if (fileName.includes(".exe")) {
      swal({
        icon: "error",
        title: "خطا",
        text: "فایل‌های اجرایی را نمیتوان آپلود کرد",
        button: "متوجه شدم",
      });
    } else {
      dispatch(toggleModalLoading(true));
      let reader = new FileReader();
      reader.onloadend = () => {
        AxiosInterceptor.post("/Setting/Upload", {
          file: reader.result,
          name: fileName,
        }).then((response) => {
          switch (e.target.id) {
            case "blackListCompanyDocsFileInput":
              setBlackListCompanyInputs({
                ...blackListCompanyInputs,
                docs: response.data.message,
              });
              break;
            default:
              break;
          }
          dispatch(toggleModalLoading(false));
        });
      };
      reader.readAsDataURL(e.target.files[0]);
    }
  };
  useEffect(() => {
    getAllblackListCompany(1);
    getStaticData();
  }, []);
  useEffect(() => {
    if (+reduxState.blcompanies.count > 0) {
      let arr = [];
      let groupsNumber =
        parseInt(reduxState.blcompanies.count) /
        parseInt(paginationFilter.docNumberPerPage);
      let i = 1;
      while (groupsNumber > 0) {
        groupsNumber--;
        arr.push(i);
        i++;
      }
      setPaginationFilter({
        ...paginationFilter,
        items: arr,
      });
    }
  }, [paginationFilter.docNumberPerPage, reduxState.blcompanies.count]);
  return (
    <Dashboard>
      <div className="shared-wrapper p-4">
        <Row className="mb-2">
          <Col
            sm={12}
            lg={4}
            className="d-flex align-item-center justify-content-start"
          >
            <h5 className="m-0 set-column-middle">لیست شرکت های ممنوعه</h5>
          </Col>
          <Col
            sm={12}
            lg={8}
            className="d-flex align-item-center justify-content-end"
          >
            <button
              className="btn btn-success mx-2 text-white"
              onClick={() => {
                setBlackListCompanyInputs({
                  eBlackListCompanyId: "",
                  name: "",
                  managerName: "",
                  managerCodeMelli: "",
                  shomareSabt: "",
                  docs: "",
                  codeMelli: "",
                  letterNumber: "",
                  letterDatetime: "",
                  letterSender: "",
                  letterReciever: "",
                  letterClassificationId: 0,
                  letterClassificationDisplay: "",
                  letterArjahiyat: "",
                  letterAttachment: "",
                  letterReason: "",
                  letterReceivedDatetime: "",
                  description: "",
                });
                setBlackListCompanyModal(true);
              }}
            >
              افزودن شرکت جدید +
            </button>
          </Col>
        </Row>
        <hr />
        <Row
          ref={tableHeightRef}
          style={{
            overflowY: "auto",
          }}
        >
          <Table striped bordered hover responsive>
            <thead className="gradiantBg text-white text-center">
              <tr>
                <th scope="col" className="text-nowrap">
                  ردیف
                </th>
                <th scope="col" className="text-nowrap">
                  نام
                </th>
                <th scope="col" className="text-nowrap">
                  نام مدیر
                </th>
                <th scope="col" className="text-nowrap">
                  شناسه ملی
                </th>
                <th scope="col" className="text-nowrap">
                  عملیات
                </th>
              </tr>
            </thead>
            <tbody className="text-center">
              {reduxState.blcompanies.list.length > 0 ? (
                reduxState.blcompanies.list?.map((blc, index) => {
                  return (
                    <tr key={index} id={blc.eBlackListCompanyId}>
                      <td className="text-nowrap">
                        {parseInt(paginationFilter.pointer) *
                          parseInt(paginationFilter.docNumberPerPage) +
                          (index +
                            1 -
                            parseInt(paginationFilter.docNumberPerPage))}
                      </td>
                      <td className="text-nowrap">{blc.name}</td>
                      <td className="text-nowrap">{blc.managerName}</td>
                      <td className="text-nowrap">{blc.codeMelli}</td>
                      <td>
                        <div className="set-row-middle">
                          <span
                            className="mx-1 cpointer text-nowrap"
                            onClick={() => {
                              editBlackListCompany(blc);
                            }}
                          >
                            <i className="ms-1">
                              <PencilSquare
                                className="text-warning"
                                size={"1.2rem"}
                              />
                            </i>
                            ویرایش
                          </span>
                          <span
                            className="mx-1 cpointer text-nowrap"
                            onClick={() => {
                              deleteBlackListCompany(blc.eBlackListCompanyId);
                            }}
                          >
                            <i className="ms-1">
                              <Trash className="text-danger" size={"1.2rem"} />
                            </i>
                            حذف
                          </span>
                        </div>
                      </td>
                    </tr>
                  );
                })
              ) : (
                <tr>
                  <td colSpan={5}>لیست شرکت ها خالی میباشد</td>
                </tr>
              )}
            </tbody>
          </Table>
        </Row>
        <Row>
          <Col sm={12} className="px-3 py-3 set-row-middle position-relative">
            <div
              className="input-wrapper flex-nowrap"
              style={{ position: "absolute", right: "5px" }}
            >
              <Form.Group className="set-row-middle">
                <InputGroup.Text>برو به صفحه</InputGroup.Text>
                <FormControl
                  name="goTo"
                  type="number"
                  style={{ maxWidth: "70px" }}
                  value={paginationFilter.goTo}
                  onChange={(e) => {
                    setPaginationFilter({
                      ...paginationFilter,
                      goTo: e.target.value,
                    });
                  }}
                />
                <button
                  className="btn gradiantBg"
                  onClick={() => {
                    if (paginationFilter.goTo !== "") {
                      getAllblackListCompany(paginationFilter.goTo);
                    }
                  }}
                >
                  <ArrowLeftCircleFill size="1.3em" color="#fff" />
                </button>
              </Form.Group>
            </div>
            <ul
              id="paginationList"
              className="m-0 p-0 list-unstyled set-row-middle flex-wrap"
            >
              <li
                className="ms-1 cpointer"
                onClick={() => {
                  if (paginationFilter.pointer !== paginationFilter.items[0]) {
                    getAllblackListCompany(paginationFilter.pointer - 1);
                  }
                }}
              >
                <ArrowRight size="1.3em" />
              </li>
              {paginationFilter.pointer !== 1 && (
                <li
                  className="paginationItems"
                  onClick={() => {
                    getAllblackListCompany(1);
                  }}
                >
                  1
                </li>
              )}
              {paginationFilter.pointer > 2 && (
                <li>
                  <strong>.....</strong>
                </li>
              )}
              {paginationFilter.items?.map((pa, index) => {
                return index + 1 === paginationFilter.pointer ||
                  (index <= paginationFilter.pointer + 1 &&
                    index + 1 >= paginationFilter.pointer) ||
                  index + 1 ===
                    paginationFilter.items[
                      paginationFilter.items.length - 1
                    ] ? (
                  <li
                    key={index}
                    className={`paginationItems ${
                      index + 1 === paginationFilter.pointer
                        ? "paginationItemActive"
                        : ""
                    }`}
                    onClick={() => {
                      getAllblackListCompany(pa);
                    }}
                  >
                    {pa}
                  </li>
                ) : index + 1 ===
                  paginationFilter.items[paginationFilter.items.length - 2] ? (
                  <>
                    <li>
                      <strong>.....</strong>
                    </li>
                    <li
                      key={index}
                      className={`paginationItems ${
                        index + 1 === paginationFilter.pointer
                          ? "paginationItemActive"
                          : ""
                      }`}
                      onClick={() => {
                        getAllblackListCompany(pa);
                      }}
                    >
                      {pa}
                    </li>
                  </>
                ) : (
                  <></>
                );
              })}

              <li
                className="me-1 cpointer"
                onClick={() => {
                  if (
                    paginationFilter.pointer !==
                    paginationFilter.items[paginationFilter.items.length - 1]
                  ) {
                    getAllblackListCompany(paginationFilter.pointer + 1);
                  }
                }}
              >
                <ArrowLeft size="1.3em" />
              </li>
            </ul>
            <div
              className="input-wrapper flex-nowrap"
              style={{ position: "absolute", left: "5px" }}
            >
              <Form.Group className="set-row-middle">
                <InputGroup.Text>تعداد سند در هر صفحه</InputGroup.Text>
                <Form.Select
                  value={paginationFilter.docNumberPerPage}
                  name="docNumberPerPage"
                  id="docNumberPerPage"
                  onChange={(e) => {
                    getAllblackListCompany(1, e.target.value);
                  }}
                >
                  <option value="5">5</option>
                  <option value="10">10</option>
                  <option value="15">15</option>
                </Form.Select>
              </Form.Group>
            </div>
          </Col>
        </Row>
      </div>
      <Portal show={blackListCompanyModal}>
        <Modal.Header>
          <Modal.Title id="contained-modal-title-vcenter">
            افزودن شرکت جدید
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Row>
            <Col sm={12} lg={4}>
              <Form.Group className="input-wrapper">
                <InputGroup.Text>نام شرکت</InputGroup.Text>
                <FormControl
                  name="name"
                  type="text"
                  value={blackListCompanyInputs.name}
                  onChange={blackListCompanyOnChangeHandle}
                />
              </Form.Group>
            </Col>
            <Col sm={12} lg={4}>
              <Form.Group className="input-wrapper">
                <InputGroup.Text>شناسه ملی</InputGroup.Text>
                <FormControl
                  name="codeMelli"
                  type="text"
                  value={blackListCompanyInputs.codeMelli}
                  onChange={(e) => {
                    if (e.target.value.length < 12) {
                      blackListCompanyOnChangeHandle(e);
                    }
                  }}
                />
              </Form.Group>
            </Col>
            <Col sm={12} lg={4}>
              <Form.Group className="input-wrapper">
                <InputGroup.Text>شماره ثبت</InputGroup.Text>
                <FormControl
                  name="shomareSabt"
                  type="text"
                  value={blackListCompanyInputs.shomareSabt}
                  onChange={blackListCompanyOnChangeHandle}
                />
              </Form.Group>
            </Col>
            <Col sm={12} lg={4}>
              <Form.Group className="input-wrapper">
                <InputGroup.Text>کد ملی مدیرعامل</InputGroup.Text>
                <FormControl
                  name="managerCodeMelli"
                  type="text"
                  value={blackListCompanyInputs.managerCodeMelli}
                  className={
                    blackListCompanyInputsErr.managerCodeMelliErr
                      ? "errorBorder"
                      : ""
                  }
                  onChange={(e) => {
                    if (e.target.value.length < 11) {
                      blackListCompanyOnChangeHandle(e);
                    }
                  }}
                />
              </Form.Group>
              <p className="error text-center">
                {blackListCompanyInputsErr.managerCodeMelliErr}
              </p>
            </Col>
            <Col sm={12} lg={4}>
              <Form.Group className="input-wrapper">
                <InputGroup.Text>نام مدیرعامل</InputGroup.Text>
                <FormControl
                  name="managerName"
                  type="text"
                  value={blackListCompanyInputs.managerName}
                  onChange={blackListCompanyOnChangeHandle}
                />
              </Form.Group>
            </Col>
            {/* <Col sm={12} lg={4}>
              <Form.Group className="input-wrapper">
                <InputGroup.Text>اسناد</InputGroup.Text>
                <FormControl
                  name="docs"
                  type="text"
                  value={blackListCompanyInputs.docs}
                  onChange={blackListCompanyOnChangeHandle}
                />
              </Form.Group>
            </Col> */}
            <Col sm={12} lg={4} className="input-wrapper">
              <InputGroup>
                <InputGroup.Text>فایل</InputGroup.Text>
                {blackListCompanyInputs.docs ? (
                  <>
                    <a
                      className="btn btn-success text-white"
                      href={IMAGEURLS + blackListCompanyInputs.docs}
                      target="_blank"
                      rel="noreferrer"
                    >
                      <Eye size="1.2em" className="ms-1" />
                      مشاهده
                    </a>
                    <button
                      className="btn btn-danger text-white"
                      onClick={() => {
                        setBlackListCompanyInputs({
                          ...blackListCompanyInputs,
                          docs: "",
                        });
                      }}
                    >
                      <Trash2 size="1.2em" className="ms-1" />
                      حذف
                    </button>
                  </>
                ) : (
                  <>
                    <input
                      type="file"
                      name="image"
                      className="d-none"
                      id="blackListCompanyDocsFileInput"
                      onChange={fileChangeHandler}
                    />
                    <button
                      className="btn btn-info text-white"
                      onClick={() => {
                        document
                          .getElementById("blackListCompanyDocsFileInput")
                          .click();
                      }}
                    >
                      <CloudArrowUpFill size="1.2em" className="ms-1" />{" "}
                      بارگذاری
                    </button>
                  </>
                )}
              </InputGroup>
            </Col>
            <hr />
            <Col sm={12} lg={4}>
              <Form.Group className="input-wrapper">
                <InputGroup.Text>شماره نامه</InputGroup.Text>
                <FormControl
                  name="letterNumber"
                  type="text"
                  value={blackListCompanyInputs.letterNumber}
                  onChange={blackListCompanyOnChangeHandle}
                />
              </Form.Group>
            </Col>
            <Col sm={12} lg={4} className="input-wrapper">
              <InputGroup className="flex-nowrap">
                <InputGroup.Text>تاریخ نامه </InputGroup.Text>
                <DateInput
                  name="letterDatetime"
                  onChange={(e) => {
                    setBlackListCompanyInputs({
                      ...blackListCompanyInputs,
                      [e.target.name]: e.target.value.split("T")[0],
                    });
                  }}
                  autoOk={true}
                  value={
                    blackListCompanyInputs.letterDatetime
                      ? blackListCompanyInputs.letterDatetime
                      : ""
                  }
                />
              </InputGroup>
            </Col>
            <Col sm={12} lg={4}>
              <Form.Group className="input-wrapper">
                <EnumSelector
                  data={staticDatas}
                  enumKey="PProjectLevel"
                  name="letterClassificationId"
                  title="طبقه بندی"
                  value={blackListCompanyInputs.letterClassificationId}
                  onChange={blackListCompanyOnChangeHandle}
                />
              </Form.Group>
            </Col>
            <Col sm={12} lg={4}>
              <Form.Group className="input-wrapper">
                <InputGroup.Text>فرستنده</InputGroup.Text>
                <FormControl
                  name="letterSender"
                  type="text"
                  value={blackListCompanyInputs.letterSender}
                  onChange={blackListCompanyOnChangeHandle}
                />
              </Form.Group>
            </Col>
            <Col sm={12} lg={4}>
              <Form.Group className="input-wrapper">
                <InputGroup.Text>گیرنده</InputGroup.Text>
                <FormControl
                  name="letterReciever"
                  type="text"
                  value={blackListCompanyInputs.letterReciever}
                  onChange={blackListCompanyOnChangeHandle}
                />
              </Form.Group>
            </Col>
            <Col sm={12} lg={4}>
              <Form.Group className="input-wrapper">
                <InputGroup.Text>ارجعیت</InputGroup.Text>
                <FormControl
                  name="letterArjahiyat"
                  type="text"
                  value={blackListCompanyInputs.letterArjahiyat}
                  onChange={blackListCompanyOnChangeHandle}
                />
              </Form.Group>
            </Col>
            <Col sm={12} lg={4}>
              <Form.Group className="input-wrapper">
                <InputGroup.Text>پیوست</InputGroup.Text>
                <FormControl
                  name="letterAttachment"
                  type="text"
                  value={blackListCompanyInputs.letterAttachment}
                  onChange={blackListCompanyOnChangeHandle}
                />
              </Form.Group>
            </Col>
            <Col sm={12} lg={4}>
              <Form.Group className="input-wrapper">
                <InputGroup.Text>علت</InputGroup.Text>
                <FormControl
                  name="letterReason"
                  type="text"
                  value={blackListCompanyInputs.letterReason}
                  onChange={blackListCompanyOnChangeHandle}
                />
              </Form.Group>
            </Col>
            <Col sm={12} lg={4} className="input-wrapper">
              <InputGroup className="flex-nowrap">
                <InputGroup.Text>تاریخ دریافت</InputGroup.Text>
                <DateInput
                  name="letterReceivedDatetime"
                  onChange={(e) => {
                    setBlackListCompanyInputs({
                      ...blackListCompanyInputs,
                      [e.target.name]: e.target.value.split("T")[0],
                    });
                  }}
                  autoOk={true}
                  value={
                    blackListCompanyInputs.letterReceivedDatetime
                      ? blackListCompanyInputs.letterReceivedDatetime
                      : ""
                  }
                />
              </InputGroup>
            </Col>
            <Col sm={12} lg={12} className="input-wrapper">
              <InputGroup>
                <InputGroup.Text>توضیحات</InputGroup.Text>
                <FormControl
                  as="textarea"
                  aria-label="With textarea"
                  name="description"
                  id="description"
                  type="text"
                  value={blackListCompanyInputs.description}
                  onChange={blackListCompanyOnChangeHandle}
                />
              </InputGroup>
            </Col>
            <Col sm={12}>
              {blackListCompanyInputs.eBlackListCompanyId ? (
                <button
                  className="btn btn-success w-100"
                  onClick={() => ADDorUPDATEBlackListCompany("UPDATE")}
                >
                  به روز رسانی اطلاعات
                </button>
              ) : (
                <button
                  className="btn btn-success w-100"
                  onClick={() => ADDorUPDATEBlackListCompany("ADD")}
                >
                  ثبت اطلاعات
                </button>
              )}
            </Col>
          </Row>
        </Modal.Body>
        <Modal.Footer>
          <button
            className="btn btn-danger"
            onClick={() => {
              setBlackListCompanyModal(false);
            }}
          >
            بستن
          </button>
        </Modal.Footer>
      </Portal>
    </Dashboard>
  );
}

export default BlackListCompany;
