import React, { useEffect, useRef, useState } from "react";
import "./DashboardHome.scss";
import Dashboard from "components/Dashboard/Dashboard";
import cardSvg1 from "assets/images/cardSvg1.svg";
import cardSvg2 from "assets/images/cardSvg2.svg";
import cardSvg3 from "assets/images/cardSvg3.svg";
import Portal from "components/Portal/Portal";
import { Modal } from "react-bootstrap";
function DashboardHome() {
  const hoursRef = useRef(null);
  const minsRef = useRef(null);
  const secsRef = useRef(null);
  const intervalRef = useRef(null);
  const deg = 6;
  const [modalShow, setModalShow] = useState(false);
  const clock = () => {
    let day = new Date();
    let hh = day.getHours() * 30;
    let mm = day.getMinutes() * deg;
    let ss = day.getSeconds() * deg;
    hoursRef.current.style.transform = `rotateZ(${hh + mm / 12}deg)`;
    minsRef.current.style.transform = `rotateZ(${mm}deg)`;
    secsRef.current.style.transform = `rotateZ(${ss}deg)`;
  };
  useEffect(() => {
    intervalRef.current = setInterval(() => {
      clock();
    }, 1000);
    return () => {
      clearInterval(intervalRef.current);
    };
  }, []);

  return (
    <Dashboard>
      <header className="page-header page-header-dark bg-gradient-primary-to-secondary pb-10">
        <div className="container-xl px-4">
          <div className="page-header-content pt-4">
            <div className="row align-items-center justify-content-between">
              <div className="col-12">
                <h1 className="page-header-title">
                  <div className="page-header-icon">
                    <i data-feather="activity"></i>
                  </div>
                  داشبورد
                </h1>
                <div className="page-header-subtitle">
                  به داشبورد کنترل دسترسی شرکت کاربران مهندسی و ساختمان شمس عمران
                  خوش آمدید.
                </div>
              </div>
            </div>
          </div>
        </div>
      </header>
      <div className="container-xl px-4 mt-n10">
        <div className="row">
          <div className="col-xl-4 mb-4">
            <div
              className="card lift h-100"
              onClick={() => {
                document.querySelector("#Organizations").click();
              }}
            >
              <div className="card-body d-flex justify-content-center flex-column">
                <div className="d-flex align-items-center justify-content-between">
                  <div className="ms-3">
                    <i
                      className="feather-xl text-secondary mb-3"
                      data-feather="book"
                    ></i>
                    <h5>لیست سازمان ها</h5>
                    <div className="text-muted small">
                      برای مشاهده لیست سازمان ها روی این بخش کلیک نمایید (یا از
                      منو گزینه سازمان ها را انتخاب نمایید)
                    </div>
                  </div>
                  <img src={cardSvg3} alt="..." style={{ width: "8rem" }} />
                </div>
              </div>
            </div>
          </div>
          <div className="col-xl-4 mb-4">
            <div
              className="card lift h-100"
              onClick={() => {
                document.querySelector("#LiteCompanies").click();
              }}
            >
              <div className="card-body d-flex justify-content-center flex-column">
                <div className="d-flex align-items-center justify-content-between">
                  <div className="ms-3">
                    <i
                      className="feather-xl text-secondary mb-3"
                      data-feather="book"
                    ></i>
                    <h5>لیست شرکت ها</h5>
                    <div className="text-muted small">
                      برای مشاهده لیست شرکت ها روی این بخش کلیک نمایید (یا از
                      منو گزینه شرکت ها را انتخاب نمایید)
                    </div>
                  </div>
                  <img src={cardSvg1} alt="..." style={{ width: "8rem" }} />
                </div>
              </div>
            </div>
          </div>
          <div className="col-xl-4 mb-4">
            <div
              className="card lift h-100"
              onClick={() => {
                document.querySelector("#Projects").click();
              }}
            >
              <div className="card-body d-flex justify-content-center flex-column">
                <div className="d-flex align-items-start justify-content-between">
                  <div className="ms-3">
                    <i
                      className="feather-xl text-secondary mb-3"
                      data-feather="book"
                    ></i>
                    <h5>لیست پروژه ها</h5>
                    <div className="text-muted small">
                      برای مشاهده لیست پروژه ها روی این بخش کلیک نمایید (یا از
                      منو گزینه پروژه ها را انتخاب نمایید)
                    </div>
                  </div>
                  <img src={cardSvg2} alt="..." style={{ width: "8rem" }} />
                </div>
              </div>
            </div>
          </div>
        </div>
        <Portal show={modalShow}>
          <Modal.Header>
            <Modal.Title>ابلاغیه ها</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <p>لیست ابلاغیه ها</p>
          </Modal.Body>
          <Modal.Footer>
            <button
              className="btn btn-success"
              onClick={() => setModalShow(false)}
            >
              بستن
            </button>
          </Modal.Footer>
        </Portal>
        <div className="row mb-4">
          <div className="col-sm-12 set-row-middle">
            <div className="clock-wrapper">
              <div className="clock">
                <div className="hour">
                  <div className="hr" id="hr" ref={hoursRef}></div>
                </div>
                <div className="minute">
                  <div className="mn" id="mn" ref={minsRef}></div>
                </div>
                <div className="sec">
                  <div className="sc" id="sc" ref={secsRef}></div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Dashboard>
  );
}

export default DashboardHome;
