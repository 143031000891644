export const BASEURL = "https://api.ac.namapardazamir.ir";
export const IMAGEURLS = "https://ac.namapardazamir.ir/upload/";
export const EHRAZ_IMAGEURLS = "https://ehraz.namapardazamir.ir/upload/";
export const ApplicationTypes = {
  ORGANIZATIONS: "ORGANIZATIONS",
  LITECOMPANIES: "LITECOMPANIES",
  PROJECTS: "PROJECTS",
  USERS: "USERS",
  CONTRACTORS: "CONTRACTORS",
  PERSONELS: "PERSONELS",
};
