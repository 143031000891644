import React, { useState } from "react";
import { Modal, Button } from "react-bootstrap";
import {
  Speedometer2,
  Globe2,
  InfoCircle,
  LayerBackward,
  Building,
  PeopleFill,
  Bank,
  BoundingBox,
  PersonXFill,
  BandaidFill,
  Kanban,
  People,
  Telegram,
  Instagram,
  Envelope,
  Whatsapp,
  Telephone,
  ClockHistory,
} from "react-bootstrap-icons";
import { ApplicationTypes } from "utils/Constants";
import MenuItem from "./MenuItem";
import Portal from "components/Portal/Portal";

function Sidebar({ setTourState, selectPrerequisites, setBackUpModalShow }) {
  const [contactUsModalShow, setContactUsModalShow] = useState(false);
  return (
    <div id="layoutSidenav_nav">
      <nav className="sidenav shadow-right sidenav-light">
        <div className="sidenav-menu">
          <div className="nav accordion" id="accordionSidenav">
            <h6 className="text-center" style={{ lineHeight: 1.4 }}>
              شـــرکـــت <br />
              <strong>
                مهندسی و ساختمان <br />
                شـــمــس عــمــران
              </strong>
            </h6>
            <div className="sidenav-menu-heading">خانه</div>
            <MenuItem
              name="Dashboard"
              title="داشبورد"
              icon={<Speedometer2 style={{ color: "var(--color2)" }} />}
            />
            <div className="sidenav-menu-heading">اصلی</div>
            <MenuItem
              name="Organizations"
              title="سازمان ها"
              icon={<Bank style={{ color: "var(--color2)" }} />}
            />
            <MenuItem
              name="LiteCompanies"
              title="شرکت ها"
              icon={<Building style={{ color: "var(--color2)" }} />}
              onClick={() =>
                selectPrerequisites(ApplicationTypes.LITECOMPANIES)
              }
            />
            <MenuItem
              name="Projects"
              title="پروژه ها"
              icon={<BoundingBox style={{ color: "var(--color2)" }} />}
              onClick={() => selectPrerequisites(ApplicationTypes.PROJECTS)}
            />
            <MenuItem
              name="Users"
              title="کاربران"
              icon={<PeopleFill style={{ color: "var(--color2)" }} />}
              onClick={() => selectPrerequisites(ApplicationTypes.USERS)}
            />
            <MenuItem
              name="Contractors"
              title="پیمانکاران"
              icon={<Building style={{ color: "var(--color2)" }} />}
              onClick={() => selectPrerequisites(ApplicationTypes.CONTRACTORS)}
            />
            <MenuItem
              name="Personels"
              title="پرسنل"
              icon={<People style={{ color: "var(--color2)" }} />}
              onClick={() => selectPrerequisites(ApplicationTypes.PERSONELS)}
            />
            <MenuItem
              name="BlackListPerson"
              title="افراد ممنوعه"
              icon={<PersonXFill style={{ color: "var(--color2)" }} />}
            />
            <MenuItem
              name="BlackListCompany"
              title="شرکت‌های ممنوعه"
              icon={<BandaidFill style={{ color: "var(--color2)" }} />}
            />
            <div className="sidenav-menu-heading">دسترسی های ادمین</div>
            <MenuItem
              name="ChangeLog"
              title="تاریخچه تغییرات"
              icon={<ClockHistory style={{ color: "var(--color2)" }} />}
            />
            <MenuItem
              name="DocManagement"
              title="مدیریت مدارک"
              icon={<Kanban style={{ color: "var(--color2)" }} />}
            />
            <MenuItem
              name="backUp"
              title="پشتیبان گیری"
              icon={<LayerBackward style={{ color: "var(--color2)" }} />}
              onClick={() => setBackUpModalShow(true)}
            />
            <div className="sidenav-menu-heading">سایر</div>
            <MenuItem
              name="aboutUs"
              title="درباره ما"
              icon={<Globe2 style={{ color: "var(--color2)" }} />}
              onClick={() => setContactUsModalShow(true)}
            />
            <MenuItem
              name="help"
              title="راهنما"
              icon={<InfoCircle style={{ color: "var(--color2)" }} />}
              onClick={() => {
                setTimeout(() => {
                  document.querySelector(".react-joyride__beacon")
                    ? document.querySelector(".react-joyride__beacon").click()
                    : console.log("");
                }, 500);
                setTourState(true);
              }}
            />
          </div>
        </div>
      </nav>
      <Portal show={contactUsModalShow}>
        <Modal.Header>
          <Modal.Title id="contained-modal-title-vcenter">
            درباره ما
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p className="text-center">
            امروزه اهمیت و ضرورت کاهش تصدی گری برای سازمان ها و شرکت های دولتی و
            خصوصی کاملاً احساس شده و مدیران کلان و میانی ترجیح می دهند تا با
            برون سپاری بخشی از وظائف و ماموریت خود به سایر مجموعه ها ، ضمن سرعت
            بخشی در تحقق اهداف ، از اتلاف وقت در واگذاری و انجام امور پرهیز
            نمایند .
          </p>
          <p className="text-center">
            موسسه نما پرداز امیر در پاییز 1399 تاسیس و توانست با
            بهره گیری از تجربه 30 ساله کادر اجرائی خود طی این مدت ، اعتماد و
            رضایت کارفرمایان را جلب نماید . این موسسه در اجرای موضوعات فعالیت
            خود به شرح زیر آمادگی همکاری دارد : انجام کلیه امور برون سپاری شده
            شرکت ها و سازمان های دولتی و خصوصی در حوزه توافقات فی مابین . مستند
            سازی ، پالایش اسناد و مدارک ، گسترش سیستم های بایگانی و به روز رسانی
            آنها . برنامه ریزی ، طراحی و تولید محتوا در قالب بانک های اطلاعاتی
            به منظور پالایش و مستند سازی اسناد سازمان ها و شرکت های دولتی و
            خصوصی . پیگیری اجرای دستورالعمل های اداری و فنی و مهندسی . برگزاری
            نشست ها ، همایش ها ، نمایشگاه ها ، کارگاه های اطلاع رسانی کوتاه و
            بلند مدت . انجام پروژه های اداری و توسعه سرمایه های انسانی . انجام
            خدمات رفاهی برای پرسنل در شرکت ها و سازمان های دولتی و خصوصی به صورت
            حضوری و مجازی . مشاوره و تامین کلیه تجهیزات و اقلام مورد نیاز سازمان
            ها و شرکت ها . مراجعه به ادارات و سازمان های دولتی و خصوصی جهت اخذ
            پروانه و کلیه اسناد ثبتی و غیره . تامین نیروی انسانی سازمان ها .
            ارائه خدمات مدیریتی ، مشاوره ای در جهت توسعه فعالیت های کارآفرینی .
            تکثیر و تولید محتوا در قالب متون و الکترونیکی . انتقال و ارائه
            تجربیات دانش فنی .
          </p>
          <hr />
          <div className="set-row-middle justify-content-around mb-3">
            <h4>ارتباط با ما</h4>
          </div>
          <ul className="unstyled-list set-row-middle justify-content-around">
            <li>
              <a
                href="#!"
                target="_blank"
                rel="noreferrer"
                className="set-column-middle"
              >
                <Telegram size={"2rem"} />
                تلگرام
              </a>
            </li>
            <li>
              <a
                href="#!"
                target="_blank"
                rel="noreferrer"
                className="set-column-middle"
              >
                <Instagram size={"2rem"} color={"#833AB4"} />
                اینستاگرام
              </a>
            </li>
            <li>
              <a
                href="mailto:info@namapardazamir.ir"
                target="_blank"
                rel="noreferrer"
                className="set-column-middle"
              >
                <Envelope size={"2rem"} color={"#DB4437"} />
                ایمیل
              </a>
            </li>
            <li>
              <a
                href="#!"
                target="_blank"
                rel="noreferrer"
                className="set-column-middle"
              >
                <Whatsapp size={"2rem"} color={"#4FCE5D"} />
                واتساپ
              </a>
            </li>
            <li>
              <a
                href="http://namapardazamir.ir"
                target="_blank"
                rel="noreferrer"
                className="set-column-middle"
              >
                <Globe2 size={"2rem"} />
                وبسایت ما
              </a>
            </li>
            <li>
              <strong className="set-column-middle">
                <Telephone size={"2rem"} color={"#000"} />
                <span>شماره تماس</span>
                <span>0912-608-8954</span>
              </strong>
            </li>
          </ul>
        </Modal.Body>
        <Modal.Footer>
          <Button
            onClick={() => setContactUsModalShow(false)}
            variant={"danger"}
          >
            بستن
          </Button>
        </Modal.Footer>
      </Portal>
    </div>
  );
}

export default Sidebar;
