export const SET_ORGANIZATION_DATA = "SET_ORGANIZATION_DATA";
export const SET_LITECOMPANIES_DATA = "SET_LITECOMPANIES_DATA";
export const SET_PROJECTS_DATA = "SET_PROJECTS_DATA";
export const SET_USERS_DATA = "SET_USERS_DATA";
export const SET_CONTRACTORS_DATA = "SET_CONTRACTORS_DATA";
export const SET_PERSONELS_DATA = "SET_PERSONELS_DATA";
export const SET_BLP_DATA = "SET_BLP_DATA";
export const SET_BLC_DATA = "SET_BLC_DATA";
export const SET_CHANGELOG_DATA = "SET_CHANGELOG_DATA";
export const TOGGLE_MODAL_LOADING = "TOGGLE_MODAL_LOADING";
export const TOGGLE_LOADING = "TOGGLE_LOADING";
