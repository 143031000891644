import React, { useState, useEffect } from "react";
import {
  Row,
  Col,
  Modal,
  Button,
  InputGroup,
  FormControl,
  Form,
} from "react-bootstrap";
import { Search, Eye } from "react-bootstrap-icons";
import { useNavigate } from "react-router-dom";
import Portal from "components/Portal/Portal";
import swal from "sweetalert";
import { ApplicationTypes } from "utils/Constants";
import OrganizationSelector from "components/OrganizationSelector/OrganizationSelector";
import CompanySelector from "components/CompanySelector/CompanySelector";
import ProjectSelector from "components/ProjectSelector/ProjectSelector";

function Prerequisites({ showModal, dataType, resetPrerequisitesModalData }) {
  const navigate = useNavigate();
  const [organizationsState, setOrganizationsState] = useState({
    arr: [],
    orgId: "",
    orgName: "",
    err: "",
  });
  const [liteCompaniesState, setLiteCompaniesState] = useState({
    arr: [],
    compId: "",
    compName: "",
    err: "",
  });
  const [projectsState, setProjectsState] = useState({
    arr: [],
    prjId: "",
    prjName: "",
    err: "",
  });
  const goToList = () => {
    if (dataType === ApplicationTypes.LITECOMPANIES) {
      if (!organizationsState.orgId) {
        setOrganizationsState({
          ...organizationsState,
          err: "انتخاب سازمان ضروری است.",
        });
      } else {
        navigate(
          `/LiteCompanies?orgId=${organizationsState.orgId}&orgName=${organizationsState.orgName}`
        );
        resetPrerequisitesModalData();
      }
    } else if (dataType === ApplicationTypes.PROJECTS) {
      let isValid = true;
      if (!organizationsState.orgId) {
        setOrganizationsState({
          ...organizationsState,
          err: "انتخاب سازمان ضروری است.",
        });
        isValid = false;
      }
      if (!liteCompaniesState.compId) {
        setLiteCompaniesState({
          ...liteCompaniesState,
          err: "انتخاب شرکت ضروری است.",
        });
        isValid = false;
      }
      if (isValid) {
        navigate(
          `/Projects?orgId=${organizationsState.orgId}&orgName=${organizationsState.orgName}&compId=${liteCompaniesState.compId}&compName=${liteCompaniesState.compName}`
        );
        resetPrerequisitesModalData();
      }
    } else {
      // ApplicationTypes.USERS
      let isValid = true;
      if (!organizationsState.orgId) {
        setOrganizationsState({
          ...organizationsState,
          err: "انتخاب سازمان ضروری است.",
        });
        isValid = false;
      }
      if (!liteCompaniesState.compId) {
        setLiteCompaniesState({
          ...liteCompaniesState,
          err: "انتخاب شرکت ضروری است.",
        });
        isValid = false;
      }
      if (!projectsState.prjId) {
        setProjectsState({
          ...projectsState,
          err: "انتخاب پروژه ضروری است.",
        });
        isValid = false;
      }
      if (isValid) {
        let p =
          dataType === ApplicationTypes.USERS
            ? "Users"
            : dataType === ApplicationTypes.CONTRACTORS
            ? "Contractors"
            : "Personels";
        navigate(
          `/${p}?orgId=${organizationsState.orgId}&orgName=${organizationsState.orgName}&compId=${liteCompaniesState.compId}&compName=${liteCompaniesState.compName}&prjId=${projectsState.prjId}&prjName=${projectsState.prjName}`
        );
        resetPrerequisitesModalData();
      }
    }
  };
  const initalSearchInputs = {
    searchBy: "codeMelli",
    searchWord: "",
  };
  const [searchInputs, setSearchInputs] = useState(initalSearchInputs);
  const searchHandle = () => {
    if (searchInputs.searchWord) {
      resetPrerequisitesModalData();
      if (dataType === ApplicationTypes.PERSONELS) {
        navigate(
          `/Personels?${searchInputs.searchBy}=*${searchInputs.searchWord}`
        );
      } else {
        navigate(
          `/Contractors?${searchInputs.searchBy}=*${searchInputs.searchWord}`
        );
      }
    } else {
      swal({
        icon: "error",
        title: "خطا",
        text: "عبارت جست‌وجو نمیتواند خالی باشد.",
        button: "متوجه شدم",
      });
    }
  };
  useEffect(() => {
    // reset data when modal open
    if (showModal) {
      setSearchInputs(initalSearchInputs);
    }
  }, [showModal]);

  return (
    <Portal show={showModal}>
      <Modal.Header>
        <Modal.Title id="contained-modal-title-vcenter">فرم جستجو</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Row>
          {dataType === ApplicationTypes.LITECOMPANIES ? (
            <>
              <Col sm={12} className="set-row-middle mb-4">
                <h5>
                  برای مشاهده لیست شرکت ها، ابتدا باید سازمان آن را مشخص نمایید.
                </h5>
              </Col>
              <OrganizationSelector
                state={organizationsState}
                setState={setOrganizationsState}
              />
              <Col sm={12} className="set-row-middle mt-4">
                <Button onClick={() => goToList()} variant="success">
                  مشاهده لیست شرکت ها
                </Button>
              </Col>
            </>
          ) : dataType === ApplicationTypes.PROJECTS ? (
            <>
              <Col sm={12} className="set-row-middle mb-4">
                <h5>
                  برای مشاهده لیست پروژه ها، ابتدا باید سازمان و سپس شرکت آن را
                  مشخص نمایید.
                </h5>
              </Col>
              <OrganizationSelector
                state={organizationsState}
                setState={setOrganizationsState}
              />
              <CompanySelector
                state={liteCompaniesState}
                setState={setLiteCompaniesState}
                parentId={organizationsState.orgId}
              />
              <Col sm={12} className="set-row-middle mt-4">
                <Button onClick={() => goToList()} variant="success">
                  مشاهده لیست پروژه ها
                </Button>
              </Col>
            </>
          ) : dataType === ApplicationTypes.USERS ||
            dataType === ApplicationTypes.CONTRACTORS ||
            dataType === ApplicationTypes.PERSONELS ? (
            <>
              <Col sm={12} className="set-row-middle mb-4">
                <h5>
                  برای مشاهده لیست{" "}
                  {dataType === ApplicationTypes.USERS
                    ? " کاربران "
                    : dataType === ApplicationTypes.CONTRACTORS
                    ? " پیمانکاران "
                    : " پرسنل "}
                  ، ابتدا باید سازمان و شرکت، سپس پروژه آن را مشخص نمایید.
                </h5>
              </Col>
              <OrganizationSelector
                state={organizationsState}
                setState={setOrganizationsState}
              />
              <CompanySelector
                state={liteCompaniesState}
                setState={setLiteCompaniesState}
                parentId={organizationsState.orgId}
              />
              <ProjectSelector
                state={projectsState}
                setState={setProjectsState}
                parentId={liteCompaniesState.compId}
              />
              <Col sm={12} className="set-row-middle mt-4">
                <Button onClick={() => goToList()} variant="success">
                  مشاهده لیست
                  {dataType === ApplicationTypes.USERS
                    ? " کاربران "
                    : dataType === ApplicationTypes.CONTRACTORS
                    ? " پیمانکاران "
                    : " پرسنل "}
                  <Eye />
                </Button>
              </Col>
            </>
          ) : (
            <></>
          )}
        </Row>
        {dataType === ApplicationTypes.PERSONELS ||
        dataType === ApplicationTypes.CONTRACTORS ? (
          <>
            <hr />
            <Row className="my-2">
              <Col sm={12} className="set-row-middle mb-4">
                <h5>
                  {dataType === ApplicationTypes.PERSONELS
                    ? "یا میتوانید از طریق کدملی، نام یا نام خانوادگی پرسنل مورد نظر را بیابید."
                    : "یا میتوانید از شناسه ملی، نام یا شماره ثبت پیمانکار مورد نظر را بیابید."}
                </h5>
              </Col>
              <Col sm={12} lg={4}>
                <div className="input-wrapper flex-nowrap">
                  <Form.Group className="set-row-middle">
                    <InputGroup.Text>جستجو بر اساس</InputGroup.Text>
                    <Form.Select
                      value={searchInputs.searchBy}
                      name="searchBy"
                      id="searchBy"
                      onChange={(e) => {
                        setSearchInputs({
                          ...searchInputs,
                          searchBy: e.target.value,
                        });
                      }}
                    >
                      {dataType === ApplicationTypes.PERSONELS ? (
                        <>
                          <option value="codeMelli">کدملی</option>
                          <option value="name">نام</option>
                          <option value="family">نام خانوادگی</option>
                        </>
                      ) : (
                        <>
                          <option value="name">نام شرکت</option>
                          <option value="sabtNumber">شماره ثبت</option>
                          <option value="codeMelli">شناسه ملی</option>
                        </>
                      )}
                    </Form.Select>
                  </Form.Group>
                </div>
              </Col>
              <Col sm={12} lg={8}>
                <Form.Group className="input-wrapper">
                  <InputGroup.Text>عبارت جست‌وجو</InputGroup.Text>
                  <FormControl
                    name="searchWord"
                    type="text"
                    value={searchInputs.searchWord}
                    onChange={(e) => {
                      setSearchInputs({
                        ...searchInputs,
                        searchWord: e.target.value,
                      });
                    }}
                  />
                </Form.Group>
              </Col>
              <Col sm={12} className="set-row-middle mt-4">
                <Button
                  className="mx-auto"
                  onClick={searchHandle}
                  variant={"success"}
                >
                  جست‌وجو <Search />
                </Button>
              </Col>
            </Row>
          </>
        ) : (
          <></>
        )}
      </Modal.Body>
      <Modal.Footer>
        <button
          className="btn btn-danger"
          onClick={resetPrerequisitesModalData}
        >
          بستن
        </button>
      </Modal.Footer>
    </Portal>
  );
}

export default Prerequisites;
