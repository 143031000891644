import React, { useEffect, useState, useRef } from "react";
import ReactDOM from "react-dom";
import {
  Table,
  Row,
  Col,
  Modal,
  InputGroup,
  FormControl,
  Form,
  Image,
} from "react-bootstrap";
import {
  PencilSquare,
  Trash,
  ArrowRight,
  ArrowLeftCircleFill,
  ArrowLeft,
  Building,
  PeopleFill,
  People,
} from "react-bootstrap-icons";
import Dashboard from "components/Dashboard/Dashboard";
import AxiosInterceptor from "utils/AxiosInterceptor";
import swal from "sweetalert";
import {
  setProjectsData,
  toggleLoading,
  toggleModalLoading,
} from "redux/actions";
import { useSelector, useDispatch } from "react-redux";
import Portal from "components/Portal/Portal";
import defCompany from "assets/images/defCompany.jpg";
import { useNavigate, useLocation } from "react-router-dom";
import { IMAGEURLS } from "utils/Constants";
import EnumSelector from "components/EnumSelector/EnumSelector";
import validator from "validator";
import Breadcrumb from "components/Breadcrumb/Breadcrumb";

function Projects() {
  const navigate = useNavigate();
  const params = useLocation();
  const tableHeightRef = useRef(null);
  const reduxState = useSelector((state) => state);
  const dispatch = useDispatch();
  const [staticDatas, setStaticDatas] = useState({});
  const [paginationFilter, setPaginationFilter] = useState({
    pointer: 1,
    items: [],
    docNumberPerPage: "15",
    goTo: "",
  });
  const [showProjectsModal, setShowProjectsModal] = useState(false);
  const [projectsInputs, setProjectsInputs] = useState({
    eProjectId: "",
    eLiteCompanyId: "",
    companyName: "",
    eOrganizationId: "",
    organizatioName: "",
    name: "",
    // manager: "",
    classificationId: 0,
    provinceId: 0,
    cityId: 0,
    image: "",
  });
  const [projectsInputsErr, setProjectsInputsErr] = useState({
    nameErr: "",
  });
  const getAllProjects = (page, take) => {
    let orgId = params.search.split("&")[0].replace("?orgId=", "");
    let orgName = params.search.split("&")[1].replace("orgName=", "");
    let compId = params.search.split("&")[2].replace("compId=", "");
    let compName = params.search.split("&")[3].replace("compName=", "");
    setProjectsInputs({
      ...projectsInputs,
      eOrganizationId: orgId,
      organizatioName: decodeURIComponent(orgName),
      eLiteCompanyId: compId,
      companyName: decodeURIComponent(compName),
    });
    if (orgId && compId) {
      dispatch(toggleLoading(true));
      AxiosInterceptor.post("/Project/List", {
        page: +page,
        pageSize: take ? +take : +paginationFilter.docNumberPerPage,
        orderBy: "",
        filter: `eLiteCompanyId=${compId}`,
      }).then((response) => {
        if (response.data.status) {
          dispatch(
            setProjectsData({
              list: response.data.list.data,
              count: response.data.list.count,
            })
          );
        } else {
          swal({
            icon: "error",
            title: "خطا",
            text: response.data.message,
            button: "متوجه شدم",
          });
        }
        dispatch(toggleLoading(false));
      });
    } else {
      swal({
        icon: "error",
        title: "خطا",
        text: "ابتدا سازمان و شرکت مورد نظر را مشخص نمایید",
        button: "متوجه شدم",
      });
      navigate("/Organizations");
    }
  };
  const getStaticData = () => {
    AxiosInterceptor.post("/Setting/List", {
      page: 1,
      pageSize: 100,
      orderBy: "",
      filter: "",
    }).then((response) => {
      if (response.data.status) {
        let obj = {};
        response.data.settings.data.forEach((item) => {
          obj[item.key] = JSON.parse(item.value);
        });
        setStaticDatas(obj);
      } else {
        swal({
          icon: "error",
          title: "خطا",
          text: response.data.message,
          button: "متوجه شدم",
        });
      }
    });
  };
  const projectOnChangeHandle = (e) => {
    let { name, value, type } = e.target;
    if (type === "number") {
      setProjectsInputs({
        ...projectsInputs,
        [name]: +value,
      });
    } else if (type === "select-one") {
      if (value === "انتخاب کنید") {
        setProjectsInputs({
          ...projectsInputs,
          [name]: null,
        });
      } else if (value === "true") {
        setProjectsInputs({
          ...projectsInputs,
          [name]: true,
        });
      } else if (value === "false") {
        setProjectsInputs({
          ...projectsInputs,
          [name]: false,
        });
      } else {
        setProjectsInputs({
          ...projectsInputs,
          [name]: +value,
        });
      }
    } else {
      setProjectsInputs({
        ...projectsInputs,
        [name]: value,
      });
    }
  };
  const editProject = (prj) => {
    setProjectsInputs({
      ...projectsInputs,
      eProjectId: prj.eProjectId,
      name: prj.name,
      // manager: prj.manager,
      image: prj.image,
      classificationId: prj.classificationId,
      provinceId: prj.provinceId,
      cityId: prj.cityId,
    });
    setShowProjectsModal(true);
  };
  const deleteProject = (oId) => {
    let alertInput = document.createElement("div");
    ReactDOM.render(
      <Form.Control id="adminPassword" name="adminPassword" type="password" />,
      alertInput
    );
    let el = alertInput.firstChild;
    swal("برای حذف پروژه باید کلمه عبور ادمین را وارد نمایید.", {
      content: el,
      buttons: ["لغو", "حذف"],
    }).then((value) => {
      if (value) {
        AxiosInterceptor.post("/Project/Delete", {
          id: oId,
          password: document.querySelector("#adminPassword").value,
        }).then((response) => {
          if (response.data.status) {
            swal({
              icon: "success",
              title: "موفقیت",
              text: "پروژه مورد نظر با موفقیت حذف گردید",
              button: "متوجه شدم",
            });
            getAllProjects(1);
          } else {
            swal({
              icon: "error",
              title: "خطا",
              text: response.data.message,
              button: "متوجه شدم",
            });
          }
        });
      }
    });
  };
  const ADDorUPDATEProject = (actionType) => {
    if (validator.isEmpty(projectsInputs.name)) {
      setProjectsInputsErr((prevState) => ({
        ...prevState,
        nameErr: "نام پروژه نمیتواند خالی باشد.",
      }));
    } else {
      setProjectsInputsErr((prevState) => ({
        ...prevState,
        nameErr: "",
      }));
      dispatch(toggleModalLoading(true));
      let data = {};
      let msg = "";
      if (actionType === "ADD") {
        msg = "پروژه مورد نظر با موفقیت ثبت گردید.";
        data = {
          eLiteCompanyId: projectsInputs.eLiteCompanyId,
          eOrganizationId: projectsInputs.eOrganizationId,
          name: projectsInputs.name,
          // manager: projectsInputs.manager,
          image: projectsInputs.image,
          classificationId: projectsInputs.classificationId,
          provinceId: projectsInputs.provinceId,
          cityId: projectsInputs.cityId,
        };
      } else {
        msg = "پروژه مورد نظر با موفقیت به روز رسانی گردید.";
        data = {
          eProjectId: projectsInputs.eProjectId,
          eLiteCompanyId: projectsInputs.eLiteCompanyId,
          eOrganizationId: projectsInputs.eOrganizationId,
          name: projectsInputs.name,
          // manager: projectsInputs.manager,
          image: projectsInputs.image,
          classificationId: projectsInputs.classificationId,
          provinceId: projectsInputs.provinceId,
          cityId: projectsInputs.cityId,
        };
      }
      AxiosInterceptor.post("/Project/Edit", data).then((response) => {
        if (response.data.status) {
          swal({
            icon: "success",
            title: "موفقیت",
            text: msg,
            button: "متوجه شدم",
          });
          setShowProjectsModal(false);
          getAllProjects(1);
        } else {
          swal({
            icon: "error",
            title: "خطا",
            text: response.data.message,
            button: "متوجه شدم",
          });
        }
        dispatch(toggleModalLoading(false));
      });
    }
  };
  const fileChangeHandler = (e) => {
    let size = (e.target.files[0].size / 1024).toFixed(2);
    let fileName = e.target.files[0].name;
    if (size > 5000) {
      swal({
        icon: "error",
        title: "خطا",
        text: "حداکثر حجم مجاز 5 مگابایت است",
        button: "متوجه شدم",
      });
    } else if (fileName.includes(".exe")) {
      swal({
        icon: "error",
        title: "خطا",
        text: "فایل‌های اجرایی را نمیتوان آپلود کرد",
        button: "متوجه شدم",
      });
    } else {
      dispatch(toggleModalLoading(true));
      let reader = new FileReader();
      reader.onloadend = () => {
        AxiosInterceptor.post("/Setting/Upload", {
          file: reader.result,
          name: fileName,
        }).then((response) => {
          setProjectsInputs({
            ...projectsInputs,
            image: response.data.message,
          });
          dispatch(toggleModalLoading(false));
        });
      };
      reader.readAsDataURL(e.target.files[0]);
    }
  };
  useEffect(() => {
    getAllProjects(1);
    getStaticData();
  }, [params]);
  useEffect(() => {
    if (+reduxState.projects.count > 0) {
      let arr = [];
      let groupsNumber =
        parseInt(reduxState.projects.count) /
        parseInt(paginationFilter.docNumberPerPage);
      let i = 1;
      while (groupsNumber > 0) {
        groupsNumber--;
        arr.push(i);
        i++;
      }
      setPaginationFilter({
        ...paginationFilter,
        items: arr,
      });
    }
  }, [paginationFilter.docNumberPerPage, reduxState.projects.count]);
  return (
    <Dashboard>
      <div className="shared-wrapper p-4">
        <Row className="mb-2">
          <Col
            sm={12}
            lg={9}
            className="d-flex align-item-center justify-content-start"
          >
            <Breadcrumb
              title={"لیست پروژه ها"}
              list={[
                projectsInputs.companyName,
                projectsInputs.organizatioName,
              ]}
            />
          </Col>
          <Col
            sm={12}
            lg={3}
            className="d-flex align-item-center justify-content-end"
          >
            <button
              className="btn btn-success mx-2 text-white"
              onClick={() => {
                setProjectsInputs({
                  ...projectsInputs,
                  eProjectId: "",
                  name: "",
                  // manager: "",
                  image: "",
                });
                setShowProjectsModal(true);
              }}
            >
              افزودن پروژه جدید +
            </button>
          </Col>
        </Row>
        <hr />
        <Row
          ref={tableHeightRef}
          style={{
            overflowY: "auto",
          }}
        >
          <Table striped bordered hover responsive>
            <thead className="gradiantBg text-white text-center">
              <tr>
                <th scope="col" className="text-nowrap">
                  ردیف
                </th>
                <th scope="col" className="text-nowrap">
                  تصویر
                </th>
                <th scope="col" className="text-nowrap">
                  نام
                </th>
                {/* <th scope="col" className="text-nowrap">
                  مدیر
                </th> */}
                <th scope="col" className="text-nowrap">
                  طبقه بندی
                </th>
                <th scope="col" className="text-nowrap">
                  استان
                </th>
                <th scope="col" className="text-nowrap">
                  شهرستان
                </th>
                <th scope="col" className="text-nowrap">
                  عملیات
                </th>
              </tr>
            </thead>
            <tbody className="text-center">
              {reduxState.projects.list.length > 0 ? (
                reduxState.projects.list?.map((prj, index) => {
                  return (
                    <tr key={index} id={prj.eProjectId}>
                      <td className="text-nowrap">
                        {parseInt(paginationFilter.pointer) *
                          parseInt(paginationFilter.docNumberPerPage) +
                          (index +
                            1 -
                            parseInt(paginationFilter.docNumberPerPage))}
                      </td>
                      <td className="text-nowrap set-row-middle">
                        <div style={{ width: "40px", height: "40px" }}>
                          <Image
                            className="cpointer zoomable_avatar"
                            src={prj.image ? IMAGEURLS + prj.image : defCompany}
                            width="40px"
                            height="40px"
                          />
                        </div>
                      </td>
                      <td className="text-nowrap">{prj.name}</td>
                      {/* <td className="text-nowrap">{prj.manager}</td> */}
                      <td className="text-nowrap">
                        {prj.classificationDisplay}
                      </td>
                      <td className="text-nowrap">{prj.provinceDisplay}</td>
                      <td className="text-nowrap">{prj.cityDisplay}</td>
                      <td>
                        <div className="set-row-middle">
                          <span
                            className="mx-2 cpointer text-nowrap"
                            onClick={() => {
                              navigate(
                                `/Users?orgId=${projectsInputs.eOrganizationId}&orgName=${projectsInputs.organizatioName}&compId=${projectsInputs.eLiteCompanyId}&compName=${projectsInputs.companyName}&prjId=${prj.eProjectId}&prjName=${prj.name}`
                              );
                            }}
                          >
                            <i className="ms-1">
                              <PeopleFill
                                className="text-success"
                                size={"1.2rem"}
                              />
                            </i>
                            لیست کاربران
                          </span>
                          <span
                            className="mx-2 cpointer text-nowrap"
                            onClick={() => {
                              navigate(
                                `/Contractors?orgId=${projectsInputs.eOrganizationId}&orgName=${projectsInputs.organizatioName}&compId=${projectsInputs.eLiteCompanyId}&compName=${projectsInputs.companyName}&prjId=${prj.eProjectId}&prjName=${prj.name}`
                              );
                            }}
                          >
                            <i className="ms-1">
                              <Building className="text-info" size={"1.2rem"} />
                            </i>
                            پیمانکاران
                          </span>
                          <span
                            className="mx-2 cpointer text-nowrap"
                            onClick={() => {
                              navigate(
                                `/Personels?orgId=${projectsInputs.eOrganizationId}&orgName=${projectsInputs.organizatioName}&compId=${projectsInputs.eLiteCompanyId}&compName=${projectsInputs.companyName}&prjId=${prj.eProjectId}&prjName=${prj.name}`
                              );
                            }}
                          >
                            <i className="ms-1">
                              <People
                                className="text-primary"
                                size={"1.2rem"}
                              />
                            </i>
                            لیست پرسنل
                          </span>
                          <span
                            className="mx-1 cpointer text-nowrap"
                            onClick={() => {
                              editProject(prj);
                            }}
                          >
                            <i className="ms-1">
                              <PencilSquare
                                className="text-warning"
                                size={"1.2rem"}
                              />
                            </i>
                            ویرایش
                          </span>
                          <span
                            className="mx-1 cpointer text-nowrap"
                            onClick={() => {
                              deleteProject(prj.eProjectId);
                            }}
                          >
                            <i className="ms-1">
                              <Trash className="text-danger" size={"1.2rem"} />
                            </i>
                            حذف
                          </span>
                        </div>
                      </td>
                    </tr>
                  );
                })
              ) : (
                <tr>
                  <td colSpan={12}>لیست پروژه ها خالی میباشد</td>
                </tr>
              )}
            </tbody>
          </Table>
        </Row>
        <Row>
          <Col sm={12} className="px-3 py-3 set-row-middle position-relative">
            <div
              className="input-wrapper flex-nowrap"
              style={{ position: "absolute", right: "5px" }}
            >
              <Form.Group className="set-row-middle">
                <InputGroup.Text>برو به صفحه</InputGroup.Text>
                <FormControl
                  name="goTo"
                  type="number"
                  style={{ maxWidth: "70px" }}
                  value={paginationFilter.goTo}
                  onChange={(e) => {
                    setPaginationFilter({
                      ...paginationFilter,
                      goTo: e.target.value,
                    });
                  }}
                />
                <button
                  className="btn gradiantBg"
                  onClick={() => {
                    if (paginationFilter.goTo !== "") {
                      getAllProjects(paginationFilter.goTo);
                    }
                  }}
                >
                  <ArrowLeftCircleFill size="1.3em" color="#fff" />
                </button>
              </Form.Group>
            </div>
            <ul
              id="paginationList"
              className="m-0 p-0 list-unstyled set-row-middle flex-wrap"
            >
              <li
                className="ms-1 cpointer"
                onClick={() => {
                  if (paginationFilter.pointer !== paginationFilter.items[0]) {
                    getAllProjects(paginationFilter.pointer - 1);
                  }
                }}
              >
                <ArrowRight size="1.3em" />
              </li>
              {paginationFilter.pointer !== 1 && (
                <li
                  className="paginationItems"
                  onClick={() => {
                    getAllProjects(1);
                  }}
                >
                  1
                </li>
              )}
              {paginationFilter.pointer > 2 && (
                <li>
                  <strong>.....</strong>
                </li>
              )}
              {paginationFilter.items?.map((pa, index) => {
                return index + 1 === paginationFilter.pointer ||
                  (index <= paginationFilter.pointer + 1 &&
                    index + 1 >= paginationFilter.pointer) ||
                  index + 1 ===
                    paginationFilter.items[
                      paginationFilter.items.length - 1
                    ] ? (
                  <li
                    key={index}
                    className={`paginationItems ${
                      index + 1 === paginationFilter.pointer
                        ? "paginationItemActive"
                        : ""
                    }`}
                    onClick={() => {
                      getAllProjects(pa);
                    }}
                  >
                    {pa}
                  </li>
                ) : index + 1 ===
                  paginationFilter.items[paginationFilter.items.length - 2] ? (
                  <>
                    <li>
                      <strong>.....</strong>
                    </li>
                    <li
                      key={index}
                      className={`paginationItems ${
                        index + 1 === paginationFilter.pointer
                          ? "paginationItemActive"
                          : ""
                      }`}
                      onClick={() => {
                        getAllProjects(pa);
                      }}
                    >
                      {pa}
                    </li>
                  </>
                ) : (
                  <></>
                );
              })}

              <li
                className="me-1 cpointer"
                onClick={() => {
                  if (
                    paginationFilter.pointer !==
                    paginationFilter.items[paginationFilter.items.length - 1]
                  ) {
                    getAllProjects(paginationFilter.pointer + 1);
                  }
                }}
              >
                <ArrowLeft size="1.3em" />
              </li>
            </ul>
            <div
              className="input-wrapper flex-nowrap"
              style={{ position: "absolute", left: "5px" }}
            >
              <Form.Group className="set-row-middle">
                <InputGroup.Text>تعداد سند در هر صفحه</InputGroup.Text>
                <Form.Select
                  value={paginationFilter.docNumberPerPage}
                  name="docNumberPerPage"
                  id="docNumberPerPage"
                  onChange={(e) => {
                    getAllProjects(1, e.target.value);
                  }}
                >
                  <option value="5">5</option>
                  <option value="10">10</option>
                  <option value="15">15</option>
                </Form.Select>
              </Form.Group>
            </div>
          </Col>
        </Row>
      </div>
      <Portal show={showProjectsModal}>
        <Modal.Header>
          <Modal.Title id="contained-modal-title-vcenter">
            افزودن پروژه جدید
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Row>
            <Col sm={12} lg={3} className="p-3 set-column-middle">
              <div className="profileImage-wrapper set-column-middle">
                <img
                  src={
                    projectsInputs.image
                      ? IMAGEURLS + projectsInputs.image
                      : defCompany
                  }
                  alt="profile"
                />
                <input
                  type="file"
                  name="image"
                  className="d-none"
                  id="orgImageInput"
                  onChange={fileChangeHandler}
                />
                <button
                  className="btn btn-primary"
                  style={{
                    width: "50px",
                    height: "50px",
                    position: "absolute",
                    top: "-20px",
                    right: "-20px",
                    borderRadius: "50%",
                  }}
                  onClick={() => {
                    document.querySelector("#orgImageInput").click();
                  }}
                >
                  <PencilSquare size="1.3em" />
                </button>
              </div>
            </Col>
            <Col sm={12} lg={9}>
              <Row>
                <Col sm={12} lg={6}>
                  <Form.Group className="input-wrapper">
                    <InputGroup.Text>نام</InputGroup.Text>
                    <FormControl
                      name="name"
                      type="text"
                      className={projectsInputsErr.nameErr ? "errorBorder" : ""}
                      value={projectsInputs.name}
                      onChange={projectOnChangeHandle}
                    />
                  </Form.Group>
                  <p className="error text-center">
                    {projectsInputsErr.nameErr}
                  </p>
                </Col>
                {/* <Col sm={12} lg={4}>
                  <Form.Group className="input-wrapper">
                    <InputGroup.Text>مدیر</InputGroup.Text>
                    <FormControl
                      name="manager"
                      type="text"
                      value={projectsInputs.manager}
                      onChange={projectOnChangeHandle}
                    />
                  </Form.Group>
                </Col> */}
                <Col sm={12} lg={6} className="input-wrapper">
                  <EnumSelector
                    data={staticDatas}
                    enumKey="PProjectLevel"
                    name="classificationId"
                    title="طبقه بندی"
                    value={projectsInputs.classificationId}
                    onChange={projectOnChangeHandle}
                  />
                </Col>
                <Col sm={12} lg={6} className="input-wrapper">
                  <EnumSelector
                    data={staticDatas}
                    enumKey="PProvince"
                    name="provinceId"
                    title="استان"
                    value={projectsInputs.provinceId}
                    onChange={projectOnChangeHandle}
                  />
                </Col>
                <Col sm={12} lg={6} className="input-wrapper">
                  <Form.Group className="set-row-middle" controlId="hasEduDoc">
                    <InputGroup.Text>شهرستان</InputGroup.Text>
                    <Form.Select
                      value={projectsInputs.cityId}
                      name="cityId"
                      onChange={projectOnChangeHandle}
                    >
                      <option value={0}>انتخاب کنید</option>
                      {staticDatas.PCity?.filter(
                        (op) => +op.parentValue === projectsInputs.provinceId
                      ).map((op, index) => {
                        return (
                          <option value={op.key} key={index} id={op.key}>
                            {op.value}
                          </option>
                        );
                      })}
                    </Form.Select>
                  </Form.Group>
                </Col>
                <Col sm={12}>
                  {projectsInputs.eProjectId ? (
                    <button
                      className="btn btn-success w-100"
                      onClick={() => ADDorUPDATEProject("UPDATE")}
                    >
                      به روز رسانی اطلاعات
                    </button>
                  ) : (
                    <button
                      className="btn btn-success w-100"
                      onClick={() => ADDorUPDATEProject("ADD")}
                    >
                      ثبت اطلاعات
                    </button>
                  )}
                </Col>
              </Row>
            </Col>
          </Row>
        </Modal.Body>
        <Modal.Footer>
          <button
            className="btn btn-danger"
            onClick={() => {
              setShowProjectsModal(false);
            }}
          >
            بستن
          </button>
        </Modal.Footer>
      </Portal>
    </Dashboard>
  );
}

export default Projects;
