import React, { useEffect, useState, useRef } from "react";
import {
  Table,
  Row,
  Col,
  InputGroup,
  FormControl,
  Form,
  Image,
  OverlayTrigger,
  Tooltip,
  Modal,
  Button,
  Tabs,
  Tab,
} from "react-bootstrap";
import {
  ArrowRight,
  ArrowLeftCircleFill,
  ArrowLeft,
  XLg,
  CheckLg,
  FileEarmarkMedicalFill,
  InfoSquareFill,
} from "react-bootstrap-icons";
import Dashboard from "components/Dashboard/Dashboard";
import Portal from "components/Portal/Portal";
import DocItem from "pages/DocManagement/DocItem";
import AxiosInterceptor from "utils/AxiosInterceptor";
import { EHRAZ_IMAGEURLS } from "utils/Constants";
import defProfile from "assets/images/defProfile.jpg";
import swal from "sweetalert";
import {
  setPersonelsData,
  toggleLoading,
  toggleModalLoading,
} from "redux/actions";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate, useLocation } from "react-router-dom";
import Breadcrumb from "components/Breadcrumb/Breadcrumb";

function Personels() {
  const navigate = useNavigate();
  const params = useLocation();
  const tableHeightRef = useRef(null);
  const reduxState = useSelector((state) => state);
  const dispatch = useDispatch();
  const arrayParams = params.search.split("&");
  const isSearchMode = arrayParams.length === 1;
  const searchedKey = isSearchMode ? decodeURIComponent(arrayParams[0].split("=")[1].replace("*", "")) : "";
  const [paginationFilter, setPaginationFilter] = useState({
    pointer: 1,
    items: [],
    docNumberPerPage: "15",
    goTo: "",
  });
  const [usersInputs, setUsersInputs] = useState({
    eUserId: "",
    eOrganizationId: "",
    organizatioName: "",
    eLiteCompanyId: "",
    companyName: "",
    eProjectId: "",
    projectName: "",
  });
  const getAllPersonels = (page, take, filter) => {
    dispatch(toggleLoading(true));
    const prjId = arrayParams[4]?.replace("prjId=", "");
    AxiosInterceptor.post("/Person/List", {
      page: +page,
      pageSize: take ? +take : +paginationFilter.docNumberPerPage,
      orderBy: "",
      filter: filter ? filter : prjId ? `eProjectId=${prjId}` : "",
    }).then((response) => {
      if (response.data.status) {
        dispatch(
          setPersonelsData({
            list: response.data.persons.data,
            count: response.data.persons.count,
          })
        );
      } else {
        swal({
          icon: "error",
          title: "خطا",
          text: response.data.message,
          button: "متوجه شدم",
        });
      }
      dispatch(toggleLoading(false));
    });
  };
  const [personDocListModal, setPersonDocListModal] = useState(false);
  const [personDocList, setPersonDocList] = useState({
    amniatiList: [],
    nezaratiList: [],
    maktabiList: [],
    edariList: [],
  });
  const getAllDocsOfThisPersonel = (personId) => {
    setPersonDocListModal(true);
    dispatch(toggleModalLoading(true));
    AxiosInterceptor.post("/PersonDoc/List", { id: personId }).then(
      (response) => {
        if (response.data.status) {
          let amniatiList = [],
            nezaratiList = [],
            maktabiList = [],
            edariList = [];
          response.data.items.forEach((item) => {
            switch (item.eDoc.type) {
              case "Amniyati":
                amniatiList.push(item);
                break;
              case "Nezarati":
                nezaratiList.push(item);
                break;
              case "Maktabi":
                maktabiList.push(item);
                break;
              case "Edari":
                edariList.push(item);
                break;
              // case "Edu":

              //   break;
              default:
                break;
            }
          });
          setPersonDocList({
            amniatiList: amniatiList,
            nezaratiList: nezaratiList,
            maktabiList: maktabiList,
            edariList: edariList,
          });
        } else {
          swal({
            icon: "error",
            title: "خطا",
            text: response.data.message,
            button: "متوجه شدم",
          });
          setPersonDocListModal(false);
        }
        dispatch(toggleModalLoading(false));
      }
    );
  };
  const [selectedPersonel, setSelectedPersonel] = useState({});
  // const [searchInputs, setSearchInputs] = useState({
  //   searchBy: "codeMelli",
  //   searchWord: "",
  // });
  // const searchHandle = () => {
  //   if (searchInputs.searchWord) {
  //     dispatch(toggleLoading(true));
  //     AxiosInterceptor.post("/Person/List", {
  //       page: 1,
  //       pageSize: 20,
  //       orderBy: "",
  //       filter: `${searchInputs.searchBy}=${searchInputs.searchWord}`,
  //     }).then((response) => {
  //       if (response.data.status) {
  //         if (response.data.persons.count === 0) {
  //           swal({
  //             icon: "error",
  //             title: "خطا",
  //             text: "نتیجه ای برای این عبات جست‌وجو یافت نشد",
  //             button: "متوجه شدم",
  //           });
  //         } else {
  //           let person = response.data.persons.data[0];
  //           setSelectedPersonel(person);
  //           getAllDocsOfThisPersonel(person.ePersonId);
  //         }
  //       } else {
  //         swal({
  //           icon: "error",
  //           title: "خطا",
  //           text: response.data.message,
  //           button: "متوجه شدم",
  //         });
  //       }
  //       dispatch(toggleLoading(false));
  //     });
  //   } else {
  //     swal({
  //       icon: "error",
  //       title: "خطا",
  //       text: "عبارت جست‌وجو نمیتواند خالی باشد.",
  //       button: "متوجه شدم",
  //     });
  //   }
  // };

  const [jobDetailModal, setJobDetailModal] = useState({
    show: false,
    person: {},
    list: [],
  });
  const getJobAllDetails = (person) => {
    dispatch(toggleModalLoading(true));
    AxiosInterceptor.post("/Job/List", { id: person.ePersonId }).then(
      (response) => {
        if (response.data.status) {
          setJobDetailModal({
            ...jobDetailModal,
            person: person,
            show: true,
            list: response.data.items,
          });
        } else {
          swal({
            icon: "error",
            title: "خطا",
            text: response.data.message,
            button: "متوجه شدم",
          });
        }
        dispatch(toggleModalLoading(false));
      }
    );
  };

  useEffect(() => {
    if (params.search) {
      if (arrayParams.length > 1) {
        //این یعنی از بخش بالایی فرم سرچ استفاده کرده و 6 تا پارامتر فرستاده
        let orgId = arrayParams[0].replace("?orgId=", "");
        let orgName = arrayParams[1].replace("orgName=", "");
        let compId = arrayParams[2].replace("compId=", "");
        let compName = arrayParams[3].replace("compName=", "");
        let prjId = arrayParams[4].replace("prjId=", "");
        let prjName = arrayParams[5].replace("prjName=", "");
        setUsersInputs({
          ...usersInputs,
          eOrganizationId: orgId,
          organizatioName: decodeURIComponent(orgName),
          eLiteCompanyId: compId,
          companyName: decodeURIComponent(compName),
          eProjectId: prjId,
          projectName: decodeURIComponent(prjName),
        });
        getAllPersonels(1, 15);
      } else {
        //این یعنی از بخش پایینی فرم سرچ استفاده کرده و یه پارامتر فرستاده فقط برای فیلتر
        let filter = decodeURIComponent(arrayParams[0].replace("?", ""));
        getAllPersonels(1, 50, filter);
      }
    } else {
      swal({
        icon: "error",
        title: "خطا",
        text: "ابتدا سازمان و شرکت و پروژه مورد نظر را مشخص نمایید",
        button: "متوجه شدم",
      });
      navigate("/Organizations");
    }
  }, [params]);

  useEffect(() => {
    if (+reduxState.personels.count > 0) {
      let arr = [];
      let groupsNumber =
        parseInt(reduxState.personels.count) /
        parseInt(paginationFilter.docNumberPerPage);
      let i = 1;
      while (groupsNumber > 0) {
        groupsNumber--;
        arr.push(i);
        i++;
      }
      setPaginationFilter({
        ...paginationFilter,
        items: arr,
      });
    }
  }, [paginationFilter.docNumberPerPage, reduxState.personels.count]);
  return (
    <Dashboard>
      <div className="shared-wrapper p-4">
        <Row className="mb-2">
          <Col
            sm={12}
            className="d-flex align-item-center justify-content-start"
          >
            {/* اگر در حالت سرچ روی فیلد های پایینی بود  */}
            {isSearchMode ? (
              <Breadcrumb title={`نتایج جست‌وجو برای عبارت ${searchedKey}`} />
            ) : (
              <>
                <Breadcrumb
                  title={"لیست پرسنل"}
                  list={[
                    usersInputs.projectName,
                    usersInputs.companyName,
                    usersInputs.organizatioName,
                  ]}
                />
              </>
            )}
          </Col>
        </Row>
        <hr />
        {/* <Row className="my-2">
          <Col sm={12} lg={5}>
            <div className="input-wrapper flex-nowrap">
              <Form.Group className="set-row-middle">
                <InputGroup.Text>جستجو بر اساس</InputGroup.Text>
                <Form.Select
                  value={searchInputs.searchBy}
                  name="searchBy"
                  id="searchBy"
                  onChange={(e) => {
                    setSearchInputs({
                      ...searchInputs,
                      searchBy: e.target.value,
                    });
                  }}
                >
                  <option value="codeMelli">کدملی</option>
                  <option value="name">نام</option>
                  <option value="family">نام خانوادگی</option>
                </Form.Select>
              </Form.Group>
            </div>
          </Col>
          <Col sm={12} lg={5}>
            <Form.Group className="input-wrapper">
              <InputGroup.Text>عبارت جست‌وجو</InputGroup.Text>
              <FormControl
                name="searchWord"
                type="text"
                value={searchInputs.searchWord}
                onChange={(e) => {
                  setSearchInputs({
                    ...searchInputs,
                    searchWord: e.target.value,
                  });
                }}
              />
            </Form.Group>
          </Col>
          <Col sm={12} lg={2}>
            <Button
              className="w-100"
              onClick={searchHandle}
              variant={"success"}
            >
              جست‌وجو <Search />
            </Button>
          </Col>
        </Row> */}
        <Row ref={tableHeightRef} style={{ overflowY: "auto" }}>
          <Table striped bordered hover responsive>
            <thead className="gradiantBg text-white text-center">
              <tr>
                <th scope="col" className="text-nowrap">
                  ردیف
                </th>
                <th scope="col" className="text-nowrap">
                  تصویر
                </th>
                <th scope="col" className="text-nowrap">
                  نام
                </th>
                <th scope="col" className="text-nowrap">
                  نام خانوادگی
                </th>
                <th scope="col" className="text-nowrap">
                  کد ملی
                </th>
                {isSearchMode && (
                  <>
                    <th scope="col" className="text-nowrap">
                      سازمان
                    </th>
                    <th scope="col" className="text-nowrap">
                      شرکت
                    </th>
                    <th scope="col" className="text-nowrap">
                      پروژه
                    </th>
                  </>
                )}
                <th scope="col" className="text-nowrap">
                  صلاحیت همکاری
                </th>
                <th scope="col" className="text-nowrap">
                  عملیات
                </th>
              </tr>
            </thead>
            <tbody className="text-center">
              {reduxState.personels.list.length > 0 ? (
                reduxState.personels.list?.map((person, index) => {
                  return (
                    <tr key={index} id={person.ePersonId}>
                      <td className="text-nowrap">
                        {parseInt(paginationFilter.pointer) *
                          parseInt(paginationFilter.docNumberPerPage) +
                          (index +
                            1 -
                            parseInt(paginationFilter.docNumberPerPage))}
                      </td>
                      <td className="text-nowrap set-row-middle">
                        <div style={{ width: "40px", height: "40px" }}>
                          <Image
                            className="cpointer zoomable_avatar"
                            src={
                              person.image
                                ? EHRAZ_IMAGEURLS + person.image
                                : defProfile
                            }
                            width="40px"
                            height="40px"
                          />
                        </div>
                      </td>
                      <td className="text-nowrap">{person.name}</td>
                      <td className="text-nowrap">{person.family}</td>
                      <td className="text-nowrap">{person.codeMelli}</td>
                      {isSearchMode && (
                        <>
                          <td className="text-nowrap">
                            {person.eOrganization.name}
                          </td>
                          <td className="text-nowrap">
                            {person.eLiteCompany.name}
                          </td>
                          <td className="text-nowrap">
                            {person.eProject.name}
                          </td>
                        </>
                      )}
                      <td className="text-nowrap">
                        {/* 
                          دارد = 0,
                          ندارد = 1,
                          موقت = 2,
                          شناسایی_جعل_سند = 3,
                        */}
                        {person.salahiyateHamkariInt === 0 ||
                          person.salahiyateHamkariInt === 2 ? (
                          <OverlayTrigger
                            placement="top"
                            delay={{ show: 250, hide: 400 }}
                            overlay={
                              <Tooltip>
                                {person.salahiyateHamkari.replaceAll("_", " ")}
                              </Tooltip>
                            }
                          >
                            <span className="text-success cpointer">
                              <CheckLg size="1.3em" />
                            </span>
                          </OverlayTrigger>
                        ) : (
                          <OverlayTrigger
                            placement="top"
                            delay={{ show: 250, hide: 400 }}
                            overlay={
                              <Tooltip>
                                {person.salahiyateHamkari.replaceAll("_", " ")}
                              </Tooltip>
                            }
                          >
                            <span className="text-danger cpointer">
                              <XLg size="1.3em" />
                            </span>
                          </OverlayTrigger>
                        )}
                      </td>
                      <td>
                        <div className="set-row-middle">
                          <span
                            className="mx-1 cpointer text-nowrap"
                            onClick={() => {
                              setSelectedPersonel(person);
                              getAllDocsOfThisPersonel(person.ePersonId);
                            }}
                          >
                            <i className="ms-1">
                              <FileEarmarkMedicalFill
                                className="text-info"
                                size={"1.2rem"}
                              />
                            </i>
                            لیست مدارک
                          </span>
                          <span
                            className="mx-2 cpointer text-nowrap"
                            onClick={() => {
                              getJobAllDetails(person);
                            }}
                          >
                            <i className="ms-1">
                              <InfoSquareFill
                                className="text-info"
                                size={"1.2rem"}
                              />
                            </i>
                            خلاصه وضعیت همکاری
                          </span>
                        </div>
                      </td>
                    </tr>
                  );
                })
              ) : (
                <tr>
                  <td colSpan={99}>
                    {
                      isSearchMode ? "نتیجه ای برای این عبارت یافت نشد" : "لیست پرسنل خالی میباشد"
                    }
                  </td>
                </tr>
              )}
            </tbody>
          </Table>
        </Row>
        <Row>
          <Col sm={12} className="px-3 py-3 set-row-middle position-relative">
            <div
              className="input-wrapper flex-nowrap"
              style={{ position: "absolute", right: "5px" }}
            >
              <Form.Group className="set-row-middle">
                <InputGroup.Text>برو به صفحه</InputGroup.Text>
                <FormControl
                  name="goTo"
                  type="number"
                  style={{ maxWidth: "70px" }}
                  value={paginationFilter.goTo}
                  onChange={(e) => {
                    setPaginationFilter({
                      ...paginationFilter,
                      goTo: e.target.value,
                    });
                  }}
                />
                <button
                  className="btn gradiantBg"
                  onClick={() => {
                    if (paginationFilter.goTo !== "") {
                      getAllPersonels(paginationFilter.goTo);
                    }
                  }}
                >
                  <ArrowLeftCircleFill size="1.3em" color="#fff" />
                </button>
              </Form.Group>
            </div>
            <ul
              id="paginationList"
              className="m-0 p-0 list-unstyled set-row-middle flex-wrap"
            >
              <li
                className="ms-1 cpointer"
                onClick={() => {
                  if (paginationFilter.pointer !== paginationFilter.items[0]) {
                    getAllPersonels(paginationFilter.pointer - 1);
                  }
                }}
              >
                <ArrowRight size="1.3em" />
              </li>
              {paginationFilter.pointer !== 1 && (
                <li
                  className="paginationItems"
                  onClick={() => {
                    getAllPersonels(1);
                  }}
                >
                  1
                </li>
              )}
              {paginationFilter.pointer > 2 && (
                <li>
                  <strong>.....</strong>
                </li>
              )}
              {paginationFilter.items?.map((pa, index) => {
                return index + 1 === paginationFilter.pointer ||
                  (index <= paginationFilter.pointer + 1 &&
                    index + 1 >= paginationFilter.pointer) ||
                  index + 1 ===
                  paginationFilter.items[
                  paginationFilter.items.length - 1
                  ] ? (
                  <li
                    key={index}
                    className={`paginationItems ${index + 1 === paginationFilter.pointer
                      ? "paginationItemActive"
                      : ""
                      }`}
                    onClick={() => {
                      getAllPersonels(pa);
                    }}
                  >
                    {pa}
                  </li>
                ) : index + 1 ===
                  paginationFilter.items[paginationFilter.items.length - 2] ? (
                  <>
                    <li>
                      <strong>.....</strong>
                    </li>
                    <li
                      key={index}
                      className={`paginationItems ${index + 1 === paginationFilter.pointer
                        ? "paginationItemActive"
                        : ""
                        }`}
                      onClick={() => {
                        getAllPersonels(pa);
                      }}
                    >
                      {pa}
                    </li>
                  </>
                ) : (
                  <></>
                );
              })}

              <li
                className="me-1 cpointer"
                onClick={() => {
                  if (
                    paginationFilter.pointer !==
                    paginationFilter.items[paginationFilter.items.length - 1]
                  ) {
                    getAllPersonels(paginationFilter.pointer + 1);
                  }
                }}
              >
                <ArrowLeft size="1.3em" />
              </li>
            </ul>
            <div
              className="input-wrapper flex-nowrap"
              style={{ position: "absolute", left: "5px" }}
            >
              <Form.Group className="set-row-middle">
                <InputGroup.Text>تعداد سند در هر صفحه</InputGroup.Text>
                <Form.Select
                  value={paginationFilter.docNumberPerPage}
                  name="docNumberPerPage"
                  id="docNumberPerPage"
                  onChange={(e) => {
                    getAllPersonels(1, e.target.value);
                  }}
                >
                  <option value="5">5</option>
                  <option value="10">10</option>
                  <option value="15">15</option>
                </Form.Select>
              </Form.Group>
            </div>
          </Col>
        </Row>
        <Portal show={personDocListModal}>
          <Modal.Header>
            <Modal.Title id="contained-modal-title-vcenter">
              لیست مدارک ارسال شده
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Row>
              <Col sm={12} lg={3} className="set-column-middle">
                <div
                  className="set-row-middle"
                  style={{
                    width: "170px",
                    height: "170px",
                    borderRadius: "50%",
                    border: "3px solid #ddd",
                    overflow: "hidden",
                    padding: "5px",
                  }}
                >
                  <Image
                    src={
                      selectedPersonel.image
                        ? EHRAZ_IMAGEURLS + selectedPersonel.image
                        : defProfile
                    }
                    width="90%"
                    height="90%"
                  />
                </div>
                <h4 className="my-1">
                  {selectedPersonel.name + " " + selectedPersonel.family}
                </h4>
                <h5>{selectedPersonel.codeMelli}</h5>
              </Col>
              <Col sm={12} lg={9}>
                <Tabs
                  defaultActiveKey="amniati"
                  transition={true}
                  className="my-3"
                >
                  <Tab eventKey="amniati" title="امنیتی">
                    <Row>
                      {personDocList.amniatiList.length > 0 ? (
                        personDocList.amniatiList.map((item, index) => {
                          return (
                            <DocItem key={index} item={item} type="readOnly" />
                          );
                        })
                      ) : (
                        <h5 className="text-center mb-0 mt-5 text-muted">
                          لیست مدارک امنیتی ارسال شده خالی میباشد.
                        </h5>
                      )}
                    </Row>
                  </Tab>
                  <Tab eventKey="nezarati" title="نظارتی">
                    <Row>
                      {personDocList.nezaratiList.length > 0 ? (
                        personDocList.nezaratiList.map((item, index) => {
                          return (
                            <DocItem key={index} item={item} type="readOnly" />
                          );
                        })
                      ) : (
                        <h5 className="text-center mb-0 mt-5 text-muted">
                          لیست مدارک نظارتی ارسال شده خالی میباشد.
                        </h5>
                      )}
                    </Row>
                  </Tab>
                  <Tab eventKey="maktabi" title="مکتبی">
                    <Row>
                      {personDocList.maktabiList.length > 0 ? (
                        personDocList.maktabiList.map((item, index) => {
                          return (
                            <DocItem key={index} item={item} type="readOnly" />
                          );
                        })
                      ) : (
                        <h5 className="text-center mb-0 mt-5 text-muted">
                          لیست مدارک مکتبی ارسال شده خالی میباشد.
                        </h5>
                      )}
                    </Row>
                  </Tab>
                  <Tab eventKey="edari" title="اداری">
                    <Row>
                      {personDocList.edariList.length > 0 ? (
                        personDocList.edariList.map((item, index) => {
                          return (
                            <DocItem key={index} item={item} type="readOnly" />
                          );
                        })
                      ) : (
                        <h5 className="text-center mb-0 mt-5 text-muted">
                          لیست مدارک اداری ارسال شده خالی میباشد.
                        </h5>
                      )}
                    </Row>
                  </Tab>
                </Tabs>
              </Col>
            </Row>
          </Modal.Body>
          <Modal.Footer>
            <Button
              onClick={() => setPersonDocListModal(false)}
              variant={"danger"}
            >
              بستن
            </Button>
          </Modal.Footer>
        </Portal>
        <Portal show={jobDetailModal.show}>
          <Modal.Header>
            <Modal.Title id="contained-modal-title-vcenter">
              لیست خلاصه وضعیت همکاری{" "}
              <span className="text-info">
                {jobDetailModal.person.name + " " + jobDetailModal.person.family}
              </span>
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Row>
              <Table striped bordered hover responsive>
                <thead className="gradiantBg text-white text-center">
                  <tr>
                    <th scope="col" className="text-nowrap">
                      ردیف
                    </th>
                    <th scope="col" className="text-nowrap">
                      نام شرکت
                    </th>
                    <th scope="col" className="text-nowrap">
                      محل خدمتی
                    </th>
                    <th scope="col" className="text-nowrap">
                      تاریخ استخدام
                    </th>
                    <th scope="col" className="text-nowrap">
                      تاریخ خاتمه همکاری
                    </th>
                    <th scope="col" className="text-nowrap">
                      علت خاتمه
                    </th>
                  </tr>
                </thead>
                <tbody className="text-center">
                  {jobDetailModal.list.length > 0 ? (
                    jobDetailModal.list.map((jd, index) => {
                      return (
                        <tr
                          key={index}
                          id={jd.eJobId}
                          style={
                            jd.endStatusDisplay === "امنیتی" ||
                              jd.endStatusDisplay === "مکتبی" ||
                              jd.endStatusDisplay === "نظارتی" ||
                              jd.endStatusDisplay === "اداری"
                              ? {
                                background: "rgba(255,0,0,.7)",
                                fontWeight: "bolder",
                              }
                              : {}
                          }
                        >
                          <td className="text-nowrap">{index + 1}</td>
                          <td className="text-nowrap">{jd.companyIdDisplay}</td>
                          <td className="text-nowrap">
                            {jd.servicePlaceIdDisplay
                              ? jd.servicePlaceIdDisplay
                              : "-"}
                            {" (" + jd.projectLevelDisplay + ") "}
                          </td>
                          <td className="text-nowrap">{jd.startDatePersian}</td>
                          <td className="text-nowrap">{jd.endDatePersian}</td>
                          <td className="text-nowrap">
                            <strong>{jd.endStatusDisplay}</strong>
                          </td>
                        </tr>
                      );
                    })
                  ) : (
                    <tr>
                      <td colSpan={8}>لیست خلاصه وضعیت همکاری خالی میباشد</td>
                    </tr>
                  )}
                </tbody>
              </Table>
            </Row>
          </Modal.Body>
          <Modal.Footer>
            <button
              className="btn btn-danger"
              onClick={() =>
                setJobDetailModal({
                  ...jobDetailModal,
                  show: false,
                })
              }
            >
              بستن
            </button>
          </Modal.Footer>
        </Portal>
      </div>
    </Dashboard>
  );
}

export default Personels;
