import React, { useEffect, useState, useRef } from "react";
import ReactDOM from "react-dom";
import {
  Table,
  Row,
  Col,
  Modal,
  InputGroup,
  FormControl,
  Form,
  Image,
  OverlayTrigger,
  Tooltip,
} from "react-bootstrap";
import {
  PencilSquare,
  Trash,
  ArrowRight,
  ArrowLeftCircleFill,
  ArrowLeft,
  EyeFill,
  InfoSquareFill,
  XLg,
  CheckLg,
} from "react-bootstrap-icons";
import Dashboard from "../../components/Dashboard/Dashboard";
import AxiosInterceptor from "../../utils/AxiosInterceptor";
import swal from "sweetalert";
import {
  setLiteCompaniesData,
  toggleLoading,
  toggleModalLoading,
} from "../../redux/actions";
import { useSelector, useDispatch } from "react-redux";
import Portal from "../../components/Portal/Portal";
import defCompany from "../../assets/images/defCompany.jpg";
import { useNavigate, useLocation } from "react-router-dom";
import { DateInput } from "react-hichestan-datetimepicker";
import { IMAGEURLS } from "../../utils/Constants";
import validator from "validator";
import Breadcrumb from "components/Breadcrumb/Breadcrumb";
import defProfile from "../../assets/images/defProfile.jpg";

function LiteCompanies() {
  const navigate = useNavigate();
  const params = useLocation();
  const tableHeightRef = useRef(null);
  const reduxState = useSelector((state) => state);
  const dispatch = useDispatch();
  const [paginationFilter, setPaginationFilter] = useState({
    pointer: 1,
    items: [],
    docNumberPerPage: "15",
    goTo: "",
  });
  const [showLiteCompaniesModal, setShowLiteCompaniesModal] = useState(false);
  const [liteCompaniesInputs, setLiteCompaniesInputs] = useState({
    eLiteCompanyId: "",
    eOrganizationId: "",
    organizatioName: "",
    name: "",
    image: "",
    startDate: "",
    activityBackground: "",
    sabtNumber: "",
  });
  const [liteCompaniesInputsErr, setLiteCompaniesInputsErr] = useState({
    nameErr: "",
  });
  const getAllLiteCompanies = (page, take) => {
    let orgId = params.search.split("&")[0].replace("?orgId=", "");
    let orgName = params.search.split("&")[1].replace("orgName=", "");
    setLiteCompaniesInputs({
      ...liteCompaniesInputs,
      eOrganizationId: orgId,
      organizatioName: decodeURIComponent(orgName),
    });
    if (orgId) {
      dispatch(toggleLoading(true));
      AxiosInterceptor.post("/LiteCompany/List", {
        page: +page,
        pageSize: take ? +take : +paginationFilter.docNumberPerPage,
        orderBy: "",
        filter: `eOrganizationId=${orgId}`,
      }).then((response) => {
        if (response.data.status) {
          dispatch(
            setLiteCompaniesData({
              list: response.data.liteCompanys.data,
              count: response.data.liteCompanys.count,
            })
          );
        } else {
          swal({
            icon: "error",
            title: "خطا",
            text: response.data.message,
            button: "متوجه شدم",
          });
        }
        dispatch(toggleLoading(false));
      });
    } else {
      swal({
        icon: "error",
        title: "خطا",
        text: "ابتدا سازمان مورد نظر را مشخص نمایید",
        button: "متوجه شدم",
      });
      navigate("/Organizations");
    }
  };
  const companyOnChangeHandle = (e) => {
    let { name, value, type } = e.target;
    if (type === "number") {
      setLiteCompaniesInputs({
        ...liteCompaniesInputs,
        [name]: +value,
      });
    } else if (type === "select-one") {
      if (value === "انتخاب کنید") {
        setLiteCompaniesInputs({
          ...liteCompaniesInputs,
          [name]: null,
        });
      } else if (value === "true") {
        setLiteCompaniesInputs({
          ...liteCompaniesInputs,
          [name]: true,
        });
      } else if (value === "false") {
        setLiteCompaniesInputs({
          ...liteCompaniesInputs,
          [name]: false,
        });
      } else {
        setLiteCompaniesInputs({
          ...liteCompaniesInputs,
          [name]: +value,
        });
      }
    } else {
      setLiteCompaniesInputs({
        ...liteCompaniesInputs,
        [name]: value,
      });
    }
  };
  const editCompany = (comp) => {
    setLiteCompaniesInputs({
      ...liteCompaniesInputs,
      eLiteCompanyId: comp.eLiteCompanyId,
      name: comp.name,
      image: comp.image,
      startDate: comp.startDate,
      activityBackground: comp.activityBackground,
      sabtNumber: comp.sabtNumber,
    });
    setShowLiteCompaniesModal(true);
  };
  const deleteCompany = (oId) => {
    let alertInput = document.createElement("div");
    ReactDOM.render(
      <Form.Control id="adminPassword" name="adminPassword" type="password" />,
      alertInput
    );
    let el = alertInput.firstChild;
    swal("برای حذف شرکت باید کلمه عبور ادمین را وارد نمایید.", {
      content: el,
      buttons: ["لغو", "حذف"],
    }).then((value) => {
      if (value) {
        AxiosInterceptor.post("/LiteCompany/Delete", {
          id: oId,
          password: document.querySelector("#adminPassword").value,
        }).then((response) => {
          if (response.data.status) {
            swal({
              icon: "success",
              title: "موفقیت",
              text: "شرکت مورد نظر با موفقیت حذف گردید",
              button: "متوجه شدم",
            });
            getAllLiteCompanies(1);
          } else {
            swal({
              icon: "error",
              title: "خطا",
              text: response.data.message,
              button: "متوجه شدم",
            });
          }
        });
      }
    });
  };
  const ADDorUPDATECompany = (actionType) => {
    if (validator.isEmpty(liteCompaniesInputs.name)) {
      setLiteCompaniesInputsErr((prevState) => ({
        ...prevState,
        nameErr: "نام شرکت نمیتواند خالی باشد.",
      }));
    } else {
      setLiteCompaniesInputsErr((prevState) => ({
        ...prevState,
        nameErr: "",
      }));
      dispatch(toggleModalLoading(true));
      let data = {};
      let msg = "";
      if (actionType === "ADD") {
        msg = "شرکت مورد نظر با موفقیت ثبت گردید.";
        data = {
          eOrganizationId: liteCompaniesInputs.eOrganizationId,
          name: liteCompaniesInputs.name,
          image: liteCompaniesInputs.image,
          activityBackground: liteCompaniesInputs.activityBackground,
          sabtNumber: liteCompaniesInputs.sabtNumber,
          startDate: liteCompaniesInputs.startDate,
        };
      } else {
        msg = "شرکت مورد نظر با موفقیت به روز رسانی گردید.";
        data = {
          eLiteCompanyId: liteCompaniesInputs.eLiteCompanyId,
          eOrganizationId: liteCompaniesInputs.eOrganizationId,
          name: liteCompaniesInputs.name,
          image: liteCompaniesInputs.image,
          activityBackground: liteCompaniesInputs.activityBackground,
          sabtNumber: liteCompaniesInputs.sabtNumber,
          startDate: liteCompaniesInputs.startDate,
        };
      }
      AxiosInterceptor.post("/LiteCompany/Edit", data).then((response) => {
        if (response.data.status) {
          swal({
            icon: "success",
            title: "موفقیت",
            text: msg,
            button: "متوجه شدم",
          });
          setShowLiteCompaniesModal(false);
          getAllLiteCompanies(1);
        } else {
          swal({
            icon: "error",
            title: "خطا",
            text: response.data.message,
            button: "متوجه شدم",
          });
        }
        dispatch(toggleModalLoading(false));
      });
    }
  };
  const fileChangeHandler = (e) => {
    let size = (e.target.files[0].size / 1024).toFixed(2);
    let fileName = e.target.files[0].name;
    if (size > 5000) {
      swal({
        icon: "error",
        title: "خطا",
        text: "حداکثر حجم مجاز 5 مگابایت است",
        button: "متوجه شدم",
      });
    } else if (fileName.includes(".exe")) {
      swal({
        icon: "error",
        title: "خطا",
        text: "فایل‌های اجرایی را نمیتوان آپلود کرد",
        button: "متوجه شدم",
      });
    } else {
      dispatch(toggleModalLoading(true));
      let reader = new FileReader();
      reader.onloadend = () => {
        AxiosInterceptor.post("/Setting/Upload", {
          file: reader.result,
          name: fileName,
        }).then((response) => {
          setLiteCompaniesInputs({
            ...liteCompaniesInputs,
            image: response.data.message,
          });
          dispatch(toggleModalLoading(false));
        });
      };
      reader.readAsDataURL(e.target.files[0]);
    }
  };
  const [personMembersModal, setPersonMembersModal] = useState({
    show: false,
    type: "", // directors -- personel
    eCompanyId: "",
    list: [],
  });
  const getAllPersons = (eCompanyId, type) => {
    //types 0=heyatModire, 1=karkonan
    dispatch(toggleModalLoading(true));
    AxiosInterceptor.post("/CompanyPerson/List", { id: eCompanyId }).then(
      (response) => {
        if (response.data.status) {
          let data = [];
          if (type === "directors") {
            data = response.data.items.filter((p) => p.type === "هیئت_مدیره");
          } else {
            data = response.data.items.filter((p) => p.type === "کارکنان");
          }
          setPersonMembersModal((prevState) => ({
            ...prevState,
            list: data,
          }));
        } else {
          swal({
            icon: "error",
            title: "خطا",
            text: response.data.message,
            button: "متوجه شدم",
          });
        }
        dispatch(toggleModalLoading(false));
      }
    );
  };
  useEffect(() => {
    getAllLiteCompanies(1);
  }, [params]);
  useEffect(() => {
    if (+reduxState.liteCompanies.count > 0) {
      let arr = [];
      let groupsNumber =
        parseInt(reduxState.liteCompanies.count) /
        parseInt(paginationFilter.docNumberPerPage);
      let i = 1;
      while (groupsNumber > 0) {
        groupsNumber--;
        arr.push(i);
        i++;
      }
      setPaginationFilter({
        ...paginationFilter,
        items: arr,
      });
    }
  }, [paginationFilter.docNumberPerPage, reduxState.liteCompanies.count]);
  return (
    <Dashboard>
      <div className="shared-wrapper p-4">
        <Row className="mb-2">
          <Col
            sm={12}
            lg={9}
            className="d-flex align-item-center justify-content-start"
          >
            <Breadcrumb
              title={"لیست شرکت ها"}
              list={[liteCompaniesInputs.organizatioName]}
            />
          </Col>
          <Col
            sm={12}
            lg={3}
            className="d-flex align-item-center justify-content-end"
          >
            <button
              className="btn btn-success mx-2 text-white"
              onClick={() => {
                setLiteCompaniesInputs({
                  ...liteCompaniesInputs,
                  eLiteCompanyId: "",
                  name: "",
                  image: "",
                  startDate: "",
                  activityBackground: "",
                  sabtNumber: "",
                });
                setShowLiteCompaniesModal(true);
              }}
            >
              افزودن شرکت جدید +
            </button>
          </Col>
        </Row>
        <hr />
        <Row
          ref={tableHeightRef}
          style={{
            overflowY: "auto",
          }}
        >
          <Table striped bordered hover responsive>
            <thead className="gradiantBg text-white text-center">
              <tr>
                <th scope="col" className="text-nowrap">
                  ردیف
                </th>
                <th scope="col" className="text-nowrap">
                  تصویر
                </th>
                <th scope="col" className="text-nowrap">
                  نام
                </th>
                <th scope="col" className="text-nowrap">
                  تاریخ شروع فعالیت
                </th>
                <th scope="col" className="text-nowrap">
                  زمینه فعالیت
                </th>
                <th scope="col" className="text-nowrap">
                  شماره ثبت
                </th>
                <th scope="col" className="text-nowrap">
                  عملیات
                </th>
              </tr>
            </thead>
            <tbody className="text-center">
              {reduxState.liteCompanies.list.length > 0 ? (
                reduxState.liteCompanies.list?.map((comp, index) => {
                  return (
                    <tr key={index} id={comp.eLiteCompanyId}>
                      <td className="text-nowrap">
                        {parseInt(paginationFilter.pointer) *
                          parseInt(paginationFilter.docNumberPerPage) +
                          (index +
                            1 -
                            parseInt(paginationFilter.docNumberPerPage))}
                      </td>
                      <td className="text-nowrap set-row-middle">
                        <div style={{ width: "40px", height: "40px" }}>
                          <Image
                            className="cpointer zoomable_avatar"
                            src={
                              comp.image ? IMAGEURLS + comp.image : defCompany
                            }
                            width="40px"
                            height="40px"
                          />
                        </div>
                      </td>
                      <td className="text-nowrap">{comp.name}</td>
                      <td className="text-nowrap">
                        {new Date(
                          comp.startDate.split("T")[0]
                        ).toLocaleDateString("fa-IR")}
                      </td>
                      <td className="text-nowrap">{comp.activityBackground}</td>
                      <td className="text-nowrap">{comp.sabtNumber}</td>
                      <td>
                        <div className="set-row-middle">
                          <span
                            className="mx-2 cpointer text-nowrap"
                            onClick={() => {
                              navigate(
                                `/Projects?orgId=${liteCompaniesInputs.eOrganizationId}&orgName=${liteCompaniesInputs.organizatioName}&compId=${comp.eLiteCompanyId}&compName=${comp.name}`
                              );
                            }}
                          >
                            <i className="ms-1">
                              <EyeFill
                                className="text-success"
                                size={"1.2rem"}
                              />
                            </i>
                            لیست پروژه ها
                          </span>
                          <span
                            className="mx-2 cpointer text-nowrap"
                            onClick={() => {
                              setPersonMembersModal({
                                ...personMembersModal,
                                show: true,
                                type: "directors",
                                eCompanyId: comp.eCompanyId,
                              });
                              getAllPersons(comp.eCompanyId, "directors");
                            }}
                          >
                            <i className="ms-1">
                              <InfoSquareFill
                                className="text-info"
                                size={"1.2rem"}
                              />
                            </i>
                            اعضای هیئت مدیره
                          </span>
                          <span
                            className="mx-2 cpointer text-nowrap"
                            onClick={() => {
                              setPersonMembersModal({
                                ...personMembersModal,
                                show: true,
                                type: "personel",
                                eCompanyId: comp.eCompanyId,
                              });
                              getAllPersons(comp.eCompanyId, "personel");
                            }}
                          >
                            <i className="ms-1">
                              <InfoSquareFill
                                className="text-info"
                                size={"1.2rem"}
                              />
                            </i>
                            لیست افراد
                          </span>
                          <span
                            className="mx-1 cpointer text-nowrap"
                            onClick={() => {
                              editCompany(comp);
                            }}
                          >
                            <i className="ms-1">
                              <PencilSquare
                                className="text-warning"
                                size={"1.2rem"}
                              />
                            </i>
                            ویرایش
                          </span>
                          <span
                            className="mx-1 cpointer text-nowrap"
                            onClick={() => {
                              deleteCompany(comp.eLiteCompanyId);
                            }}
                          >
                            <i className="ms-1">
                              <Trash className="text-danger" size={"1.2rem"} />
                            </i>
                            حذف
                          </span>
                        </div>
                      </td>
                    </tr>
                  );
                })
              ) : (
                <tr>
                  <td colSpan={11}>لیست شرکت ها خالی میباشد</td>
                </tr>
              )}
            </tbody>
          </Table>
        </Row>
        <Row>
          <Col sm={12} className="px-3 py-3 set-row-middle position-relative">
            <div
              className="input-wrapper flex-nowrap"
              style={{ position: "absolute", right: "5px" }}
            >
              <Form.Group className="set-row-middle">
                <InputGroup.Text>برو به صفحه</InputGroup.Text>
                <FormControl
                  name="goTo"
                  type="number"
                  style={{ maxWidth: "70px" }}
                  value={paginationFilter.goTo}
                  onChange={(e) => {
                    setPaginationFilter({
                      ...paginationFilter,
                      goTo: e.target.value,
                    });
                  }}
                />
                <button
                  className="btn gradiantBg"
                  onClick={() => {
                    if (paginationFilter.goTo !== "") {
                      getAllLiteCompanies(paginationFilter.goTo);
                    }
                  }}
                >
                  <ArrowLeftCircleFill size="1.3em" color="#fff" />
                </button>
              </Form.Group>
            </div>
            <ul
              id="paginationList"
              className="m-0 p-0 list-unstyled set-row-middle flex-wrap"
            >
              <li
                className="ms-1 cpointer"
                onClick={() => {
                  if (paginationFilter.pointer !== paginationFilter.items[0]) {
                    getAllLiteCompanies(paginationFilter.pointer - 1);
                  }
                }}
              >
                <ArrowRight size="1.3em" />
              </li>
              {paginationFilter.pointer !== 1 && (
                <li
                  className="paginationItems"
                  onClick={() => {
                    getAllLiteCompanies(1);
                  }}
                >
                  1
                </li>
              )}
              {paginationFilter.pointer > 2 && (
                <li>
                  <strong>.....</strong>
                </li>
              )}
              {paginationFilter.items?.map((pa, index) => {
                return index + 1 === paginationFilter.pointer ||
                  (index <= paginationFilter.pointer + 1 &&
                    index + 1 >= paginationFilter.pointer) ||
                  index + 1 ===
                    paginationFilter.items[
                      paginationFilter.items.length - 1
                    ] ? (
                  <li
                    key={index}
                    className={`paginationItems ${
                      index + 1 === paginationFilter.pointer
                        ? "paginationItemActive"
                        : ""
                    }`}
                    onClick={() => {
                      getAllLiteCompanies(pa);
                    }}
                  >
                    {pa}
                  </li>
                ) : index + 1 ===
                  paginationFilter.items[paginationFilter.items.length - 2] ? (
                  <>
                    <li>
                      <strong>.....</strong>
                    </li>
                    <li
                      key={index}
                      className={`paginationItems ${
                        index + 1 === paginationFilter.pointer
                          ? "paginationItemActive"
                          : ""
                      }`}
                      onClick={() => {
                        getAllLiteCompanies(pa);
                      }}
                    >
                      {pa}
                    </li>
                  </>
                ) : (
                  <></>
                );
              })}

              <li
                className="me-1 cpointer"
                onClick={() => {
                  if (
                    paginationFilter.pointer !==
                    paginationFilter.items[paginationFilter.items.length - 1]
                  ) {
                    getAllLiteCompanies(paginationFilter.pointer + 1);
                  }
                }}
              >
                <ArrowLeft size="1.3em" />
              </li>
            </ul>
            <div
              className="input-wrapper flex-nowrap"
              style={{ position: "absolute", left: "5px" }}
            >
              <Form.Group className="set-row-middle">
                <InputGroup.Text>تعداد سند در هر صفحه</InputGroup.Text>
                <Form.Select
                  value={paginationFilter.docNumberPerPage}
                  name="docNumberPerPage"
                  id="docNumberPerPage"
                  onChange={(e) => {
                    getAllLiteCompanies(1, e.target.value);
                  }}
                >
                  <option value="5">5</option>
                  <option value="10">10</option>
                  <option value="15">15</option>
                </Form.Select>
              </Form.Group>
            </div>
          </Col>
        </Row>
      </div>
      <Portal show={showLiteCompaniesModal}>
        <Modal.Header>
          <Modal.Title id="contained-modal-title-vcenter">
            افزودن شرکت جدید
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Row>
            <Col sm={12} lg={3} className="p-3 set-column-middle">
              <div className="profileImage-wrapper set-column-middle">
                <img
                  src={
                    liteCompaniesInputs.image
                      ? IMAGEURLS + liteCompaniesInputs.image
                      : defCompany
                  }
                  alt="profile"
                />
                <input
                  type="file"
                  name="image"
                  className="d-none"
                  id="orgImageInput"
                  onChange={fileChangeHandler}
                />
                <button
                  className="btn btn-primary"
                  style={{
                    width: "50px",
                    height: "50px",
                    position: "absolute",
                    top: "-20px",
                    right: "-20px",
                    borderRadius: "50%",
                  }}
                  onClick={() => {
                    document.querySelector("#orgImageInput").click();
                  }}
                >
                  <PencilSquare size="1.3em" />
                </button>
              </div>
            </Col>
            <Col sm={12} lg={9}>
              <Row>
                <Col sm={12} lg={6}>
                  <Form.Group className="input-wrapper">
                    <InputGroup.Text>نام</InputGroup.Text>
                    <FormControl
                      name="name"
                      type="text"
                      className={
                        liteCompaniesInputsErr.nameErr ? "errorBorder" : ""
                      }
                      value={liteCompaniesInputs.name}
                      onChange={companyOnChangeHandle}
                    />
                  </Form.Group>
                  <p className="error text-center">
                    {liteCompaniesInputsErr.nameErr}
                  </p>
                </Col>
                <Col sm={12} lg={6} className="input-wrapper">
                  <InputGroup className="flex-nowrap">
                    <InputGroup.Text>تاریخ شروع فعالیت</InputGroup.Text>
                    <DateInput
                      name="startDate"
                      onChange={(e) => {
                        setLiteCompaniesInputs({
                          ...liteCompaniesInputs,
                          [e.target.name]: e.target.value.split("T")[0],
                        });
                      }}
                      autoOk={true}
                      value={
                        liteCompaniesInputs.startDate
                          ? liteCompaniesInputs.startDate
                          : ""
                      }
                    />
                  </InputGroup>
                </Col>
                <Col sm={12} lg={6}>
                  <Form.Group className="input-wrapper">
                    <InputGroup.Text>زمینه فعالیت</InputGroup.Text>
                    <FormControl
                      name="activityBackground"
                      type="text"
                      value={liteCompaniesInputs.activityBackground}
                      onChange={companyOnChangeHandle}
                    />
                  </Form.Group>
                </Col>
                <Col sm={12} lg={6}>
                  <Form.Group className="input-wrapper">
                    <InputGroup.Text>شماره ثبت</InputGroup.Text>
                    <FormControl
                      name="sabtNumber"
                      type="text"
                      value={liteCompaniesInputs.sabtNumber}
                      onChange={companyOnChangeHandle}
                    />
                  </Form.Group>
                </Col>
                <Col sm={12}>
                  {liteCompaniesInputs.eLiteCompanyId ? (
                    <button
                      className="btn btn-success w-100"
                      onClick={() => ADDorUPDATECompany("UPDATE")}
                    >
                      به روز رسانی اطلاعات
                    </button>
                  ) : (
                    <button
                      className="btn btn-success w-100"
                      onClick={() => ADDorUPDATECompany("ADD")}
                    >
                      ثبت اطلاعات
                    </button>
                  )}
                </Col>
              </Row>
            </Col>
          </Row>
        </Modal.Body>
        <Modal.Footer>
          <button
            className="btn btn-danger"
            onClick={() => {
              setShowLiteCompaniesModal(false);
            }}
          >
            بستن
          </button>
        </Modal.Footer>
      </Portal>
      <Portal show={personMembersModal.show}>
        <Modal.Header>
          <Modal.Title id="contained-modal-title-vcenter">
            {personMembersModal.type === "directors"
              ? "اعضای هیئت مدیره"
              : "لیست افراد"}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Row>
            <Table striped bordered hover responsive>
              <thead className="gradiantBg text-white text-center">
                <tr>
                  <th scope="col" className="text-nowrap">
                    ردیف
                  </th>
                  <th scope="col" className="text-nowrap">
                    تصویر
                  </th>
                  <th scope="col" className="text-nowrap">
                    نام
                  </th>
                  <th scope="col" className="text-nowrap">
                    نام خانوادگی
                  </th>
                  <th scope="col" className="text-nowrap">
                    کد ملی
                  </th>
                  <th scope="col" className="text-nowrap">
                    صلاحیت همکاری
                  </th>
                  <th scope="col" className="text-nowrap">
                    سمت
                  </th>
                  {personMembersModal.type === "directors" && (
                    <>
                      <th scope="col" className="text-nowrap">
                        وضعیت
                      </th>
                    </>
                  )}
                  <th scope="col" className="text-nowrap">
                    گذرنامه
                  </th>
                </tr>
              </thead>
              <tbody className="text-center">
                {personMembersModal.list.length > 0 ? (
                  personMembersModal.list.map((cp, index) => {
                    return (
                      <tr key={index} id={cp.eCompanyPersonId}>
                        <td className="text-nowrap">{index + 1}</td>
                        <td className="text-nowrap">
                          <div style={{ width: "40px", height: "40px" }}>
                            <Image
                              className="cpointer zoomable_avatar"
                              src={
                                cp.eLitePerson.image
                                  ? IMAGEURLS + cp.eLitePerson.image
                                  : defProfile
                              }
                              width="40px"
                              height="40px"
                            />
                          </div>
                        </td>
                        <td className="text-nowrap">{cp.eLitePerson.name}</td>
                        <td className="text-nowrap">{cp.eLitePerson.family}</td>
                        <td className="text-nowrap">
                          {cp.eLitePerson.codeMelli}
                        </td>
                        <td className="text-nowrap">
                          {/* 
                        دارد = 0,
                        ندارد = 1,
                        موقت = 2,
                        شناسایی_جعل_سند = 3,
                      */}
                          {cp.eLitePerson.salahiyateHamkariInt === 0 ||
                          cp.eLitePerson.salahiyateHamkariInt === 2 ? (
                            <OverlayTrigger
                              placement="top"
                              delay={{ show: 250, hide: 400 }}
                              overlay={
                                <Tooltip>
                                  {cp.eLitePerson.salahiyateHamkari.replaceAll(
                                    "_",
                                    " "
                                  )}
                                </Tooltip>
                              }
                            >
                              <span className="text-success cpointer">
                                <CheckLg size="1.3em" />
                              </span>
                            </OverlayTrigger>
                          ) : (
                            <OverlayTrigger
                              placement="top"
                              delay={{ show: 250, hide: 400 }}
                              overlay={
                                <Tooltip>
                                  {cp.eLitePerson.salahiyateHamkari.replaceAll(
                                    "_",
                                    " "
                                  )}
                                </Tooltip>
                              }
                            >
                              <span className="text-danger cpointer">
                                <XLg size="1.3em" />
                              </span>
                            </OverlayTrigger>
                          )}
                        </td>
                        <td className="text-nowrap">{cp.jobPositionDisplay}</td>
                        {personMembersModal.type === "directors" && (
                          <>
                            <td className="text-nowrap">
                              {cp.status ? "فعلی" : "سابق"}
                            </td>
                          </>
                        )}
                        <td className="text-nowrap">
                          {cp.eLitePerson.hasGozarname ? (
                            <span className="text-success">
                              <CheckLg size="1.3em" />
                            </span>
                          ) : (
                            <span className="text-danger">
                              <XLg size="1.3em" />
                            </span>
                          )}
                        </td>
                      </tr>
                    );
                  })
                ) : (
                  <tr>
                    <td colSpan={9}>لیست افراد خالی میباشد</td>
                  </tr>
                )}
              </tbody>
            </Table>
          </Row>
        </Modal.Body>
        <Modal.Footer>
          <button
            className="btn btn-danger"
            onClick={() =>
              setPersonMembersModal({
                ...personMembersModal,
                show: false,
              })
            }
          >
            بستن
          </button>
        </Modal.Footer>
      </Portal>
    </Dashboard>
  );
}

export default LiteCompanies;
