import React, { useEffect } from "react";
import { Col, InputGroup, Form } from "react-bootstrap";
import AxiosInterceptor from "../../utils/AxiosInterceptor";
import swal from "sweetalert";
import { toggleModalLoading } from "../../redux/actions";
import { useDispatch } from "react-redux";

function OrganizationSelector({ state, setState, parentId }) {
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(toggleModalLoading(true));
    AxiosInterceptor.post("/LiteCompany/List", {
      page: 1,
      pageSize: 100,
      orderBy: "",
      filter: "",
    }).then((response) => {
      if (response.data.status) {
        setState({
          ...state,
          arr: response.data.liteCompanys.data,
        });
      } else {
        swal({
          icon: "error",
          title: "خطا",
          text: response.data.message,
          button: "متوجه شدم",
        });
      }
      dispatch(toggleModalLoading(false));
    });
  }, []);
  return (
    <Col sm={12} lg={4} className="m-auto">
      <Form.Group className="set-row-middle" controlId="selectedValue">
        <InputGroup.Text>لیست شرکت ها</InputGroup.Text>
        <Form.Select
          value={state.compId}
          name="compId"
          onChange={(e) => {
            if (e.target.value) {
              state.arr.forEach((item) => {
                if (item.eLiteCompanyId === e.target.value) {
                  setState({
                    ...state,
                    compId: e.target.value,
                    compName: item.name,
                  });
                }
              });
            } else {
              setState({
                ...state,
                compId: e.target.value,
                compName: "",
              });
            }
          }}
          disabled={parentId ? false : true}
        >
          <option value={""}>انتخاب کنید</option>
          {state.arr
            .filter((item) => item.eOrganizationId === parentId)
            .map((op, index) => {
              return (
                <option value={op.eLiteCompanyId} key={index}>
                  {op.name}
                </option>
              );
            })}
        </Form.Select>
      </Form.Group>
      <p className="text-danger text-center mt-2">{state.err}</p>
    </Col>
  );
}

export default OrganizationSelector;
