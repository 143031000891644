import React from "react";
import { ArrowLeftCircleFill } from "react-bootstrap-icons";
import { useNavigate } from "react-router-dom";

function Breadcrumb({ title, list }) {
  const navigate = useNavigate();
  return (
    <div className={list?"set-column-middle align-items-start":"set-row-middle"}>
      <h5>{title}</h5>
      <nav
        aria-label="breadcrumb"
        style={{ direction: "ltr" }}
        className="set-row-middle"
      >
        <button
          className="btn btn-warning mx-2 text-white text-nowrap"
          style={{ fontSize: "13px" }}
          onClick={() => {
            navigate(-1);
          }}
        >
          بازگشت به صفحه قبلی
          <ArrowLeftCircleFill className="mx-1" />
        </button>
        {list && (
          <ol className="breadcrumb mb-1" style={{ fontSize: "15px" }}>
            {list?.map((item, i) => {
              return (
                <li
                  key={i}
                  className={`breadcrumb-item ${i !== 0 ? "active" : ""}`}
                >
                  {item}
                </li>
              );
            })}
          </ol>
        )}
      </nav>
    </div>
  );
}

export default Breadcrumb;
