import React from "react";
import { useLocation, Link } from "react-router-dom";

function MenuItem({ name, onClick, title, icon }) {
  const { pathname } = useLocation();
  let address = pathname.split("/")[1];
  if (onClick) {
    return (
      <a
        id={name}
        className={`nav-link cpointer ${
          address === name ? "nav-link-active" : ""
        }`}
        onClick={onClick}
      >
        <div className="nav-link-icon">{icon}</div>
        {title}
      </a>
    );
  }
  return (
    <Link
      id={name}
      className={`nav-link cpointer ${
        address === name ? "nav-link-active" : ""
      }`}
      to={`/${name}`}
    >
      <div className="nav-link-icon">{icon}</div>
      {title}
    </Link>
  );
}

export default MenuItem;
