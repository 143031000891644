import React, { useState, useEffect } from "react";
import ReactDOM from 'react-dom';
import "./DocManagement.scss";
import {
  Tabs,
  Tab,
  Row,
  Col,
  Form,
  InputGroup,
  Modal,
  FormControl,
} from "react-bootstrap";
import { toggleLoading, toggleModalLoading } from "redux/actions";
import Dashboard from "components/Dashboard/Dashboard";
import { IMAGEURLS } from "utils/Constants";
import Portal from "components/Portal/Portal";
import AxiosInterceptor from "utils/AxiosInterceptor";
import swal from "sweetalert";
import { Trash } from "react-bootstrap-icons";
import DocItem from "./DocItem";
import { useDispatch } from "react-redux";
function DocManagement() {
  const dispatch = useDispatch();
  const [docTypes, setDocTypes] = useState({
    amniatiList: [],
    nezaratiList: [],
    maktabiList: [],
    edariList: [],
  });
  const [newDoc, setNewDoc] = useState({
    show: false,
    name: "",
    type: "",
    image: "",
    nameErr: "",
    typeErr: "",
    imageErr: "",
  });
  const selectDocImage = (e) => {
    let size = (e.target.files[0].size / 1024).toFixed(2);
    let fileName = e.target.files[0].name;
    if (size > 7000) {
      swal({
        icon: "error",
        title: "خطا",
        text: "حداکثر حجم مجاز 7 مگابایت است",
        button: "متوجه شدم",
      });
    } else if (fileName.includes(".exe")) {
      swal({
        icon: "error",
        title: "خطا",
        text: "فایل‌های اجرایی را نمیتوان آپلود کرد",
        button: "متوجه شدم",
      });
    } else {
      let reader = new FileReader();
      reader.onloadend = () => {
        dispatch(toggleModalLoading(true));
        AxiosInterceptor.post("/Setting/Upload", {
          file: reader.result,
          name: fileName,
        }).then((response) => {
          setNewDoc({
            ...newDoc,
            image: response.data.message,
          });
          dispatch(toggleModalLoading(false));
        });
      };
      reader.readAsDataURL(e.target.files[0]);
    }
  };
  const getAllDocs = () => {
    dispatch(toggleLoading(true));
    AxiosInterceptor.post("/Doc/List").then((response) => {
      if (response.data.status) {
        let amniatiList = [],
          nezaratiList = [],
          maktabiList = [],
          edariList = [];
        response.data.items.forEach((item) => {
          switch (item.type) {
            case "Amniyati":
              amniatiList.push(item);
              break;
            case "Nezarati":
              nezaratiList.push(item);
              break;
            case "Maktabi":
              maktabiList.push(item);
              break;
            case "Edari":
              edariList.push(item);
              break;
            // case "Edu":

            //   break;
            default:
              break;
          }
        });
        setDocTypes({
          amniatiList: amniatiList,
          nezaratiList: nezaratiList,
          maktabiList: maktabiList,
          edariList: edariList,
        });
      } else {
        swal({
          icon: "error",
          title: "خطا",
          text: response.data.message,
          button: "متوجه شدم",
        });
      }
      dispatch(toggleLoading(false));
    });
  };
  const addDoc = () => {
    let isValid = true;
    if (newDoc.name === "") {
      setNewDoc((prevState) => ({
        ...prevState,
        nameErr: "وارد کردن نام مدرک الزامی است",
      }));
      isValid = false;
    } else {
      setNewDoc((prevState) => ({
        ...prevState,
        nameErr: "",
      }));
    }
    if (newDoc.type === "") {
      setNewDoc((prevState) => ({
        ...prevState,
        typeErr: "انتخاب یکی از انواع مدرک الزامی است",
      }));
      isValid = false;
    } else {
      setNewDoc((prevState) => ({
        ...prevState,
        typeErr: "",
      }));
    }
    // if (newDoc.image === "") {
    //   setNewDoc((prevState) => ({
    //     ...prevState,
    //     imageErr: "انتخاب فایل مدرک الزامی است",
    //   }));
    //   isValid = false;
    // } else {
    //   setNewDoc((prevState) => ({
    //     ...prevState,
    //     imageErr: "",
    //   }));
    // }
    if (isValid) {
      dispatch(toggleModalLoading(true));
      AxiosInterceptor.post("/Doc/Add", {
        type: newDoc.type,
        name: newDoc.name,
        image: newDoc.image,
      }).then((response) => {
        if (response.data.status) {
          swal({
            icon: "success",
            title: "موفقیت",
            text: "مدرک مورد نظر با موفقیت ثبت گردید",
            button: "متوجه شدم",
          });
          setNewDoc({
            ...newDoc,
            show: false,
          });
          getAllDocs();
        } else {
          swal({
            icon: "error",
            title: "خطا",
            text: response.data.message,
            button: "متوجه شدم",
          });
        }
        dispatch(toggleModalLoading(false));
      });
    }
  };
  const deleteDoc = (eDocId, docName) => {
    let alertInput = document.createElement("div");
    ReactDOM.render(
      <Form.Control id="adminPassword" name="adminPassword" type="password" />,
      alertInput
    );
    let el = alertInput.firstChild;
    swal("برای حذف مدرک باید کلمه عبور ادمین را وارد نمایید.", {
      content: el,
      buttons: ["لغو", "حذف"],
    }).then((value) => {
      if (value) {
        dispatch(toggleLoading(true));
        AxiosInterceptor.post("/Doc/Delete", {
          id: eDocId,
          password: el.value,
        }).then((response) => {
          if (response.data.status) {
            swal({
              icon: "success",
              title: "موفقیت",
              text: "مدرک مورد نظر با موفقیت حذف گردید",
              button: "متوجه شدم",
            });
            getAllDocs();
          } else {
            swal({
              icon: "error",
              title: "خطا",
              text: response.data.message,
              button: "متوجه شدم",
            });
          }
          dispatch(toggleLoading(false));
        });
      }
    });
  };
  useEffect(() => {
    getAllDocs();
  }, []);
  return (
    <Dashboard>
      <div className="DocManagement-wrapper">
        <Row>
          <Col sm={12}>
            <h4 className="text-center mb-0">مدیریت مدارک</h4>
          </Col>
        </Row>
        <Row className="justify-content-end">
          <Col sm={12} lg={4} className="input-wrapper">
            <button
              className="btn btn-success"
              onClick={() =>
                setNewDoc({
                  show: true,
                  name: "",
                  type: "",
                  image: "",
                  nameErr: "",
                  typeErr: "",
                  imageErr: "",
                })
              }
            >
              افزودن مدرک جدید +
            </button>
          </Col>
        </Row>
        <Row>
          <Col sm={12}>
            <div className="Tablist">
              <Tabs
                defaultActiveKey="amniati"
                transition={true}
                className="my-3"
              >
                <Tab eventKey="amniati" title="امنیتی">
                  <Row>
                    {docTypes.amniatiList.length > 0 ? (
                      docTypes.amniatiList.map((item, index) => {
                        return (
                          <DocItem
                            key={index}
                            item={item}
                            type="create"
                            deleteDoc={deleteDoc}
                          />
                        );
                      })
                    ) : (
                      <h5 className="text-center mb-0">
                        لیست مدارک امنیتی خالی میباشد.
                      </h5>
                    )}
                  </Row>
                </Tab>
                <Tab eventKey="nezarati" title="نظارتی">
                  <Row>
                    {docTypes.nezaratiList.length > 0 ? (
                      docTypes.nezaratiList.map((item, index) => {
                        return (
                          <DocItem
                            key={index}
                            item={item}
                            type="create"
                            deleteDoc={deleteDoc}
                          />
                        );
                      })
                    ) : (
                      <h5 className="text-center mb-0">
                        لیست مدارک نظارتی خالی میباشد.
                      </h5>
                    )}
                  </Row>
                </Tab>
                <Tab eventKey="maktabi" title="مکتبی">
                  <Row>
                    {docTypes.maktabiList.length > 0 ? (
                      docTypes.maktabiList.map((item, index) => {
                        return (
                          <DocItem
                            key={index}
                            item={item}
                            type="create"
                            deleteDoc={deleteDoc}
                          />
                        );
                      })
                    ) : (
                      <h5 className="text-center mb-0">
                        لیست مدارک مکتبی خالی میباشد.
                      </h5>
                    )}
                  </Row>
                </Tab>
                <Tab eventKey="edari" title="اداری">
                  <Row>
                    {docTypes.edariList.length > 0 ? (
                      docTypes.edariList.map((item, index) => {
                        return (
                          <DocItem
                            key={index}
                            item={item}
                            type="create"
                            deleteDoc={deleteDoc}
                          />
                        );
                      })
                    ) : (
                      <h5 className="text-center mb-0">
                        لیست مدارک اداری خالی میباشد.
                      </h5>
                    )}
                  </Row>
                </Tab>
              </Tabs>
            </div>
          </Col>
        </Row>
        <Portal show={newDoc.show}>
          <Modal.Header>
            <Modal.Title id="contained-modal-title-vcenter">
              افزودن مدرک
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Row>
              <Col sm={12} lg={3} className="input-wrapper flex-column">
                <Form.Group className="input-wrapper mb-0">
                  <InputGroup.Text>نام مدرک</InputGroup.Text>
                  <FormControl
                    name="name"
                    type="text"
                    value={newDoc.name}
                    onChange={(e) => {
                      setNewDoc({ ...newDoc, name: e.target.value });
                    }}
                  />
                </Form.Group>
                <div>
                  <strong className="error">{newDoc.nameErr}</strong>
                </div>
              </Col>
              <Col sm={12} lg={3} className="input-wrapper flex-column">
                <Form.Group className="set-row-middle" controlId="hasGozarname">
                  <InputGroup.Text>نوع مدرک</InputGroup.Text>
                  <Form.Select
                    value={newDoc.type}
                    name="type"
                    onChange={(e) => {
                      setNewDoc({ ...newDoc, type: +e.target.value });
                    }}
                  >
                    <option value="">انتخاب کنید</option>
                    <option value="1">امنیتی</option>
                    <option value="2">نظارتی</option>
                    <option value="3">مکتبی</option>
                    <option value="4">اداری</option>
                  </Form.Select>
                </Form.Group>
                <div>
                  <strong className="error">{newDoc.typeErr}</strong>
                </div>
              </Col>
              <Col sm={12} lg={6} className="input-wrapper">
                {newDoc.image ? (
                  <div
                    id="docImageUploader"
                    className="set-column-middle p-5 position-relative"
                  >
                    <a
                      href={IMAGEURLS + newDoc.image}
                      target="_blank"
                      rel="noreferrer"
                    >
                      {newDoc.image}
                    </a>
                    <span
                      style={{
                        position: "absolute",
                        bottom: "10px",
                        left: "10px",
                        cursor: "pointer",
                      }}
                      onClick={() => {
                        setNewDoc({ ...newDoc, image: "", imageErr: "" });
                      }}
                    >
                      <Trash size="1.2em" color="red" />
                    </span>
                  </div>
                ) : (
                  <div
                    id="docImageUploader"
                    className={`set-column-middle p-5 cpointer ${newDoc.imageErr ? "hasErr" : ""
                      }`}
                    onClick={() => {
                      document.querySelector("#docImageInput").click();
                    }}
                  >
                    <h5>فایل مدرک را آپلود نمایید</h5>
                    <p className="text-warning">
                      حداکثر حجم مجاز 7 مگابایت میباشد.
                    </p>
                    <strong className="error">{newDoc.imageErr}</strong>
                    <input
                      type="file"
                      name="image"
                      className="d-none"
                      id="docImageInput"
                      onChange={selectDocImage}
                    />
                  </div>
                )}
              </Col>
              <Col sm={12} lg={12} className="input-wrapper">
                <button className="btn btn-success w-100 mt-4" onClick={addDoc}>
                  ثبت
                </button>
              </Col>
            </Row>
          </Modal.Body>
          <Modal.Footer>
            <button
              className="btn btn-danger"
              onClick={() =>
                setNewDoc({
                  ...newDoc,
                  show: false,
                })
              }
            >
              بستن
            </button>
          </Modal.Footer>
        </Portal>
      </div>
    </Dashboard>
  );
}

export default DocManagement;
