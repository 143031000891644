import React from "react";
import ReactDOM from "react-dom";
import { Modal } from "react-bootstrap";
import { useSelector } from "react-redux";
import "./Portal.scss";

function Portal(props) {
  const reduxState = useSelector((state) => state);
  return ReactDOM.createPortal(
    <Modal
      {...props}
      size={props.size ? props.size : "xl"}
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <div
        className={
          reduxState.modalLoading
            ? "modal_loading_wrapper w-100 h-100 position-absolute set-column-middle bg-white"
            : "d-none"
        }
        style={{ zIndex: 99 }}
      >
        <div className="modal_loading set-column-middle">
          <svg
            version="1.1"
            viewBox="0 0 128 128"
            width="100%"
            height="100%"
            xmlns="http://www.w3.org/2000/svg"
            x="0px"
            y="0px"
          >
            <path
              className="doc"
              d="M0-0.00002 0 3.6768 0 124.32 0 128h4.129 119.74 4.129v-3.6769-120.65-3.6768h-4.129-119.74zm8.2581 7.3537 111.48 0 0 113.29-111.48 0zm13.626 25.048 0 7.3537 57.806 0 0-7.3537zm0 19.12 0 7.3537 84.232 0 0-7.3537zm0 17.649 0 7.3537 84.232 0 0-7.3537zm0 19.12 0 7.3537 84.232 0 0-7.3537z7z"
            />
            <path
              className="magnify"
              d="M38.948 10.429c-18.254 10.539-24.468 33.953-14.057 51.986 9.229 15.984 28.649 22.764 45.654 16.763-0.84868 2.6797-0.61612 5.6834 0.90656 8.3207l17.309 29.98c2.8768 4.9827 9.204 6.6781 14.187 3.8013 4.9827-2.8768 6.6781-9.204 3.8013-14.187l-17.31-29.977c-1.523-2.637-4.008-4.34-6.753-4.945 13.7-11.727 17.543-31.935 8.31-47.919-10.411-18.034-33.796-24.359-52.049-13.82zm6.902 11.955c11.489-6.633 26.133-2.7688 32.893 8.9404 6.7603 11.709 2.7847 26.324-8.704 32.957-11.489 6.632-26.133 2.768-32.893-8.941-6.761-11.709-2.785-26.324 8.704-32.957z"
            />
          </svg>
          <div className="type_container">
            <h4 className="mt-5 typed-out">در حال بارگذاری اطلاعات ...</h4>
          </div>
        </div>
      </div>
      {props.children}
    </Modal>,
    document.getElementById("modals-wrapper")
  );
}

export default Portal;
